import Service from './_base';
import { serializeParams } from '../lib/utils';
import { trackPromise } from 'react-promise-tracker';

class UserService extends Service {
    /**
     * Given an id, returns an estate
     * @param id
     * @returns {Promise<void>}
     */

    async getUser(id) {
        const params = {
            requestParameters: {
                userId: id
            }
        };
        return await trackPromise(this.client.get('users' + serializeParams(params)));
    }

    async partialUpdateBatch(data) {
        await trackPromise(this.client.post('partialupdate/batch', data));
    }

    async saveUserComChannel(comChannels) {
        return await trackPromise(this.client.put('users/comchannels/save', comChannels));
    }

    async getComChannelData(comChannelType, userName) {
        let url = `users/comchannels?comChannelType=${comChannelType}`;
        if (userName) {
            url += '&username=' + encodeURIComponent(userName);
        }
        let response = await trackPromise(this.client.get(url));
        return response.data;
    }
}

export default new UserService();
