import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { Modal } from 'antd-mobile';
import Loader from 'react-loader-spinner';
import styled from 'styled-components';
import theme from '../styles/theme';

const StyledModal = styled(Modal)`
    .am-modal-content {
        background-color: transparent;
    }
`;

const LoadingSpinner = (props) => {
    const { promiseInProgress } = usePromiseTracker({ delay: 200 });

    return (
        promiseInProgress === true && (
            <StyledModal visible={true} transparent={true} maskClosable={true} footer={[]}>
                <Loader type="ThreeDots" color={theme.colors.primary} />
            </StyledModal>
        )
    );
};
export default LoadingSpinner;
