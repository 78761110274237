import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class AccountService extends Service {
    /**
     * Given a search string, returns a list of companies
     * @param search
     * @returns {Promise<*>}
     */

    async validateFreemiumAccount(model) {
        return await trackPromise(this.client.post('public/freemiumaccount/validate', model));
    }

    async createFreemiumAccount(token) {
        return await trackPromise(this.client.post(`public/freemiumaccount/create?token=${token}`, token));
    }

    async getCountries(languageId) {
        let defaultLanguage = 'en-GB',
            languagesArray = [defaultLanguage],
            browserLanguage = languageId || navigator.language || false,
            cleanResponse = { data: [] };

        if (browserLanguage) {
            // fix for certain browser specific languages codes
            if (browserLanguage.length === 5) {
                browserLanguage = browserLanguage.slice(0, browserLanguage.length - 2) + browserLanguage.slice(-2).toUpperCase();
            }

            // fix for 2 characters language codes
            if (browserLanguage.length === 2) {
                browserLanguage = browserLanguage + '-' + browserLanguage.toUpperCase();
            }

            languagesArray.push(browserLanguage);
        }

        let response = await trackPromise(this.client.post('translations/getcountrieslanguages', languagesArray));
        // console.log(response);

        if (response && response.data) {
            if (browserLanguage) {
                response.data.forEach((element) => {
                    let newItem = element,
                        hasTranslation = false,
                        defaultTranslation = {};

                    if (element.nameML) {
                        element.nameML.forEach((item, index) => {
                            if (item.languageId === browserLanguage) {
                                newItem.nameML = [element.nameML[index]];
                                hasTranslation = true;
                            }

                            if (item.languageId === defaultLanguage) {
                                defaultTranslation = item;
                            }
                        });

                        if (!hasTranslation) {
                            newItem.nameML = [defaultTranslation];
                        }
                    }

                    cleanResponse.data.push(newItem);
                });
            } else {
                cleanResponse = response;
            }
        }
        // console.log(cleanResponse);

        return cleanResponse;
    }

    async getLanguages() {
        return await trackPromise(this.client.get('translations/getlanguages'));
    }
}

export default new AccountService();
