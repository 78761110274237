import http from '../lib/http';
import { trackPromise } from 'react-promise-tracker';

class AutomaticActionsService {
    constructor() {
        this.client = http;
    }

    /**
     * get a list of event rules
     * @returns {Promise<*>}
     */
    async getEventRules() {
        let url = 'events/rules';
        let response = await trackPromise(this.client.get(url));
        return response?.data;
    }
}

export default new AutomaticActionsService();
