import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class CalendarService extends Service {
    /**
     * Given a list of filters, returns appointments
     * @param filters
     * @returns {Promise<*>}
     */

    async getAppointments(filters = {}) {
        return await trackPromise(
            this.client.post('search/calendars', {
                sort: {
                    ascending: true,
                    field: 'startDateTime'
                },
                page: {
                    size: 10000
                },
                field: null,
                aggregate: null,
                postFilter: null,
                query: {
                    includeGroupEstates: true,
                    includeRecurrences: true,
                    ...filters
                }
            })
        );
    }

    /**
     * Given an id, returns an appointment
     * @param id
     * @returns {Promise<*>}
     */

    async getAppointment(id) {
        return await trackPromise(
            this.client.get('calendars', {
                params: {
                    searchParameters: JSON.stringify({
                        pageIndex: null,
                        pageSize: null,
                        parameters: {
                            calendarIdList: [id]
                        }
                    }),
                    includes: 'contactinfo,estateinfo,calendaruser'
                }
            })
        );
    }

    /**
     * Given data, creates an appointment
     * @param data
     * @returns {Promise<void>}
     */

    async createAppointment(data = {}, events = []) {
        return await trackPromise(
            this.client.put(
                'calendars/savedata',
                [
                    {
                        events: events,
                        object: {
                            ...data,
                            rowStatus: 1
                        }
                    }
                ],
                {
                    params: {
                        validationWarningBehaviour: 1
                    }
                }
            )
        );
    }

    /**
     * Given data, creates more appointments
     * @param data
     * @returns {Promise<void>}
     */

    async createAppointments(data = {}, events = []) {
        return await trackPromise(
            this.client.put('calendars/savedata', data, {
                params: {
                    validationWarningBehaviour: 1
                }
            })
        );
    }

    /**
     * Given data, updates an appointment
     * @param data
     * @returns {Promise<void>}
     */

    async updateAppointment(data = {}, correlationId = null) {
        return await trackPromise(
            this.client.put('calendars/savedata', [data], {
                params: {
                    validationWarningBehaviour: 1,
                    correlationId: correlationId
                }
            })
        );
    }

    /**
     * Given an id, deletes an appointment
     * @param id
     * @returns {Promise<void>}
     */

    async deleteAppointment(id) {
        return await trackPromise(
            this.client.post('partialupdate/batch', [
                {
                    entityName: 'Calendar',
                    primaryKey: {
                        calendarId: id
                    },
                    values: {
                        deleted: 1
                    }
                }
            ])
        );
    }

    async calendarIcsImport(icsBody) {
        const response = await trackPromise(this.client.put('calendars/import/ics',icsBody, { headers: { 'Content-Type': 'text/calendar'} }));
        return response.data;
    }
}

export default new CalendarService();
