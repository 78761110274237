export default class UA {
    constructor(action, data, logger) {
        this.logger = logger;

        this.parameters = {};
        this.parameters['action'] = action;
        this.parameters['action_data'] = data;
        this.start = null;
    }

    // start timing the event
    timed = () => {
        this.start = new Date();
        return this;
    };

    on = (name = null, id = null, data = null) => {
        if (name) {
            this.parameters['context_name'] = name;
        }
        if (id) {
            this.parameters['context_id'] = id;
        }
        if (data) {
            this.parameters['context_data'] = data;
        }
        return this;
    };

    // emit the event
    emit = () => {
        if (this.start != null) {
            const duration = new Date().getTime() - this.start.getTime();
            this.parameters['duration'] = duration;
        }

        if (this.logger) {
            this.parameters['location'] = window.location.pathname + window.location.hash;
            this.logger.log(undefined, this.parameters, undefined);
        }
    };
};

