//import { hot } from 'react-hot-loader/root';
import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
import PiwikPro from '@piwikpro/react-piwik-pro';
import * as sw from './sw';
import { getQueryString, componentLoader } from './lib/utils';
import LoadingSpinner from './m/base/LoadingSpinner';

//const MobileApp = lazy(() => import(/*webpackChunkName:"mobile_app"*/ './m/App'));
const attempts = 20;
const MobileApp = lazy(() => componentLoader(() => import('./m/App'), attempts));

//default application is Mobile, for Test & Import use || '1' instead of || '2'
let appType = getQueryString('x') || localStorage.getItem('AppType') || '2';
if (localStorage.getItem('IsMobile') === 'true' || appType === '2') {
    appType = '2';
} else {
    appType = '1';
}
localStorage.setItem('AppType', appType);
if (window.location.href.indexOf('mobile.whise.eu') > 0) {
    PiwikPro.initialize('3e36fe44-9ea1-4b8f-ac23-b08380a27497', 'https://whise.containers.piwik.pro');
} else {
    PiwikPro.initialize('99a264cb-8858-4161-8bc0-a2389eb11d8d', 'https://whisestg.containers.piwik.pro');
}

class App extends React.Component {
    render() {
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <MobileApp />
                <LoadingSpinner />
            </Suspense>
        );
    }
}
sw.register();

render(
    <div>
        <App type={appType} />
    </div>,
    document.getElementById('root')
);
sw.unregister();
