export default class LingerManager {
    constructor(lingerPeriodMs) {
        this.MODE = {
            IMMEDIATE: 0,
            LINGER: 1,
            ERROR: 2
        };
    
        this._backOffPeriodMs = 500;
        this._backOffMaxPeriodMs = 30 * 1000;
    
        this._alarm = null;
        this._mode = this.MODE.LINGER;
        this._backOffFactor = 0;
        this._lingerPeriodMs = lingerPeriodMs;
    }

    reset = () => {
        if (this.isScheduled()) {
            clearTimeout(this._alarm);
        }
        this._alarm = null;
    };

    isScheduled = () => {
        return this._alarm !== null;
    };

    setMode = (mode) => {
        this._mode = mode;
        if (this._mode !== this.MODE.ERROR) {
            // reset the back-off factor
            this._backOffFactor = 0;
        }
    };

    postpone = (callback) => {
        let timeMs;
        this.reset();

        switch (this._mode) {
            case this.MODE.IMMEDIATE:
                timeMs = 0;
                break;

            case this.MODE.LINGER:
                timeMs = this._lingerPeriodMs;
                break;

            case this.MODE.ERROR:
                let backOffPeriodMs = this._backOffPeriodMs * Math.pow(2, this._backOffFactor);
                this._backOffFactor++;
                timeMs = Math.min(backOffPeriodMs, this._backOffMaxPeriodMs);
                break;
            
            default:
                break;
        }

        // defer the callback
        this._alarm = setTimeout(callback, timeMs);
    };
};

//export default new LingerManager();
