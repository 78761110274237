import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend'; // primary use cache
import XHR from 'i18next-xhr-backend';

const lng = localStorage.getItem('LanguageId') || 'en-GB';

i18n.use(initReactI18next)
    .use(Backend)
    .init({
        lng,
        fallbackLng: 'en-GB',
        load: 'currentOnly',
        crossDomain: true,
        backend: {
            backends: [LocalStorageBackend, XHR],
            backendOptions: [
                {
                    prefix: 'i18next_res_',
                    expirationTime: 7 * 24 * 60 * 60 * 1000
                },
                {
                    loadPath: `${window.env?.REACT_APP_TRANSLATIONS_URL}?languageId={{lng}}`
                }
            ]
        },
        react: {
            useSuspense: false,
            wait: false
        }
    });

export default i18n;
