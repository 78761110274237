import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class CompanyService extends Service {
    /**
     * Given a search string, returns a list of companies
     * @param search
     * @returns {Promise<*>}
     */

    async searchCompanies(search = '') {
        return await trackPromise(this.client.get('companies', {
            params: {
                searchParameters: JSON.stringify({
                    parameters: {
                        name: `%${search}%`
                    },
                    pageIndex: 0,
                    pageSize: 10
                }),
                includes: 'company,division,location,document'
            }
        }));
    }
}

export default new CompanyService();
