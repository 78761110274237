/* eslint-disable array-callback-return */
import moment from 'moment';
import LZString from 'lz-string';
import Logger from './logger';
import Security from './security';
import UA from './UA.js';
import { generateGuid } from '../lib/utils';
import Service from '../services/_base';
import Features from './features';
import LicenseType from '../m/base/enums/LicenseType';
// import ReactGA from 'react-ga';
// import ReactPixel from 'react-facebook-pixel';
// import { CustomEvent, PageViews } from '@piwikpro/react-piwik-pro';
//import 'moment/min/moment-with-locales'

const storageProperties = {
    AuditFields: true,
    NameOutgoingMail: false,
    Username: false,
    CurrencySymbol: false,
    ServerId: false,
    UserId: false,
    OfficeId: false,
    ClientId: false,
    StoragePath: false,
    StorageUrl: false,
    ClientName: false,
    ClientStatusId: false,
    UserStatusId: false,
    LanguageId: false,
    Email: false,
    Url: false,
    DetailUrl: false,
    ContactUrl: false,
    PrivacyUrl: false,
    CountryId: false,
    Name: false,
    OfficeName: false,
    Password: false,
    ReferenceDataLastUpdateDate: false,
    CacheLastUpdateDate: false,
    AccessToken: false,
    InstanceId: false,
    CalendarActions: false,
    CalendarSearchParameters: false,
    Users: true,
    Offices: false,
    ContactTitles: false,
    ContactTypes: false,
    Languages: false,
    Preferences: true,
    ContactStatuses: false,
    ExportMediaStatuses: false,
    ContactSearchOptions: false,
    ContactSortOptions: false,
    ContactListViewPreference: false,
    EstateSearchOptions: false,
    EstateSortOptions: false,
    EstateMatchingSearchOptions: false,
    EstateMatchingSortOptions: false,
    EstateStatuses: false,
    EstateListPreference: false,
    ProspectionStatuses: false,
    MaritalStatuses: false,
    ContactOrigins: false,
    Countries: true,
    Purposes: false,
    PurposeStatuses: false,
    Categories: false,
    Subcategories: true,
    Regions: false,
    States: false,
    DisplayStatuses: false,
    Availabilities: false,
    ContactNumberTypes: false,
    Details: true,
    Subdetails: true,
    SubdetailEnums: true,
    HistoryCategories: false,
    BaseDocumentTypes: true,
    BaseContactTypes: false,
    EstateGroups: false,
    EstateGroupSelection: false,
    ContractStatuses: false,
    TemplateFiles: true,
    ContractTypes: false,
    IsCorporateClient: false,
    EnableExperimentalFeatures: false,
    IsOnline: false,
    EstateSavedOffline: false,
    IsMobile: false,
    AppUrl: false,
    Permissions: false,
    IsAdministrator: false,
    ComChannels: false,
    EmailListPreference: false,
    EmailFolders: false,
    IsFreemium: false,
    PackageType: '',
    DataLayerPackageType:'',
    AllowPayment: false,
    UsedLanguages: false,
    CountrySelection: false,
    RegionSelection: false,
    ContactTypeSelection: false,
    ContactTitleSelection: false,
    ContactStatusSelection: false,
    EstateStatusSelection: false,
    LanguageSelection: false,
    ProspectionStatusSelection: false,
    ContactOriginSelection: false,
    MaritalStatusSelection: false,
    ActiveUsers: false,
    ActiveOffices: [false, (i) => i.id > 0],
    ActionSelection: false,
    HistoryCategorySelection: false,
    StateSelection: false,
    AvailabilitySelection: false,
    PurposeSelection: false,
    PurposeStatusSelection: false,
    CategorySelection: false,
    SubcategorySelection: false,
    DisplayStatusSelection: false,
    DetailsWithPictureSelection: false,
    ContractTypeSelection: false,
    ContractStatusSelection: false,
    BaseDocumentTypeSelection: false,
    BaseContactTypeSelection: false,
    SubdetailSelection: true,
    PurposeStatusGroupSelection: false,
    SubcategoryGroupSelection: false,
    MaxPictureResolution: false,
    TelephonySettings: false,
    PendingContacts: false,
    CalendarFilterVisible: false,
    EstateFilterVisible: false,
    ContactFilterVisible: false,
    UpdatedVersion: false,
    AgreeTerms: false,
    EstateSubdetails: true,
    GroupInfo: true,
    IsGroupMember: false,
    Notifications: true,
    ServiceConsumers: true,
    MediaSettings: false
};

const refCache = {};

export const getRefData = (key, decompress = false) => {
    if (!refCache[key]) {
        let data = localStorage.getItem(key);

        if (decompress) {
            data = LZString.decompressFromUTF16(data);
        }

        try {
            data = JSON.parse(data);
        } catch {}

        refCache[key] = data;
    }
    return refCache[key];
};

export const setRefData = (key, value, compress = false) => {
    refCache[key] = value;

    if (typeof value === 'object') {
        value = JSON.stringify(value);
        let data = compress ? LZString.compressToUTF16(value) : value;

        localStorage.setItem(key, data);
    } else {
        localStorage.setItem(key, value);
    }
};

class DataService extends Service {
    constructor() {
        super();

        // ReactGA.initialize('UA-7515072-6');
        // ReactPixel.init('198284717410072');

        this.init();
        this.setPreferences();
        this.setMomentLocaleForLanguage();

        // let clientUrlSuffix = window.env?.REACT_APP_CLIENT_URL_SUFFIX;
        if (!this.StorageUrl) {
            this.StorageUrl = this.getStorageUrl(this.StoragePath);
        }

        if (this.EnableExperimentalFeatures) {
            // this.ImagesUrl = experimentalImageUrl;
            this.ImagesUrl = this.format(
                '{0}{1}/{2}{3}/{4}',
                'https://',
                window.env?.REACT_APP_IMAGES_URL,
                window.env?.REACT_APP_ENV,
                this.ServerId,
                this.StoragePath
            );
        } else {
            // this.ImagesUrl = `https://${this.StoragePath}.${clientUrlSuffix}/Pictures`;
            this.ImagesUrl = this.getUrl('Pictures');
        }
        //this.StorageUrl = `https://${this.StoragePath}.${clientUrlSuffix}`;

        if (window.env?.REACT_APP_ENV === 'stg') {
            this.WhiseUrl = `https://whisestoragestaging.blob.core.windows.net/public/whise`;
        } else {
            //this.WhiseUrl = `https://whise.${clientUrlSuffix}`;
            this.WhiseUrl = `https://prd.storagewhise.eu/public/whise`;
        }

        this.ServerTz = 'Europe/Brussels';
        this.LocalTz = 'Europe/Brussels';

        this.Logger = new Logger(
            {
                lingerMs: 5000
            },
            this
        );
        this.Logger.addMeta('ver', this.ApplicationVersion());
        this.Logger.catchErrors();

        if (this.Permissions) {
            this.Security = new Security(
                this.Permissions,
                this.UserId,
                this.Preferences.permissionType,
                this.Preferences.allowContactRepresentativeChangeByAnyUser,
                this.Preferences.blockCreateUpdateDelete
            );
        }
    }

    ApplicationVersion() {
        return window.env?.REACT_APP_VERSION;
    }

    /**
     * Loop through the properties and create the
     * the class setters and getters
     */
    init() {
        Object.keys(storageProperties).map((key) => {
            let settings = storageProperties[key];

            if (!Array.isArray(settings)) {
                settings = [settings, null];
            }

            Object.defineProperty(this, key, {
                enumerable: true,
                get() {
                    let data = getRefData(key, settings[0]);
                    if (data) {
                        if (typeof settings[1] === 'function') {
                            if (Array.isArray(data)) {
                                data = data.filter(settings[1]);
                            } else if (typeof data === 'object') {
                                data = Object.keys(data).reduce((all, currentKey) => {
                                    if (!settings[1](data[currentKey])) {
                                        return all;
                                    }

                                    return {
                                        ...all,
                                        [currentKey]: data[currentKey]
                                    };
                                }, {});
                            } else {
                                data = settings[1](data);
                            }
                        }
                    }
                    return data;
                },
                set(value) {
                    setRefData(key, value, settings[0]);
                }
            });
        });
    }

    setPreferences() {
        let preferences = this.Preferences;
        if (preferences) {
            this.Features = new Features(preferences, this.OfficeId);
        }
    }

    setMomentLocaleForLanguage() {
        if (!this.LanguageId) {
            switch (navigator.language.substring(0, 2)) {
                case 'fr':
                    this.LanguageId = 'fr-BE';
                    break;

                case 'nl':
                    this.LanguageId = 'nl-BE';
                    break;

                case 'ro':
                    this.LanguageId = 'ro-RO';
                    break;

                case 'es':
                    this.LanguageId = 'es-ES';
                    break;

                case 'tr':
                    this.LanguageId = 'tr-TR';
                    break;

                default:
                    this.LanguageId = 'en-GB';
                    break;
            }
        }

        if (this.LanguageId) {
            if (this.LanguageId.substring(0, 2) === 'fr') {
                moment.locale('fr', {
                    months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
                    monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
                    monthsParseExact: true,
                    weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
                    weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
                    weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
                    weekdaysParseExact: true,
                    longDateFormat: {
                        LT: 'HH:mm',
                        LTS: 'HH:mm:ss',
                        L: 'DD/MM/YYYY',
                        LL: 'D MMMM YYYY',
                        LLL: 'D MMMM YYYY HH:mm',
                        LLLL: 'dddd D MMMM YYYY HH:mm'
                    },
                    calendar: {
                        sameDay: '[Aujourd’hui à] LT',
                        nextDay: '[Demain à] LT',
                        nextWeek: 'dddd [à] LT',
                        lastDay: '[Hier à] LT',
                        lastWeek: 'dddd [dernier à] LT',
                        sameElse: 'L'
                    },
                    relativeTime: {
                        future: 'dans %s',
                        past: 'il y a %s',
                        s: 'quelques secondes',
                        m: 'une minute',
                        mm: '%d minutes',
                        h: 'une heure',
                        hh: '%d heures',
                        d: 'un jour',
                        dd: '%d jours',
                        M: 'un mois',
                        MM: '%d mois',
                        y: 'un an',
                        yy: '%d ans'
                    },
                    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
                    ordinal: function (number) {
                        return number + (number === 1 ? 'er' : 'e');
                    },
                    meridiemParse: /PD|MD/,
                    isPM: function (input) {
                        return input.charAt(0) === 'M';
                    },
                    // In case the meridiem units are not separated around 12, then implement
                    // this function (look at locale/id.js for an example).
                    // meridiemHour : function (hour, meridiem) {
                    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
                    // },
                    meridiem: function (hours, minutes, isLower) {
                        return hours < 12 ? 'PD' : 'MD';
                    },
                    week: {
                        dow: 1, // Monday is the first day of the week.
                        doy: 4 // Used to determine first week of the year.
                    }
                });
            }
            if (this.LanguageId.substring(0, 2) === 'nl') {
                moment.locale('nl', {
                    months: 'januari_februari_maart_april_mei_juni_juli_augustus_september_oktober_november_december'.split('_'),
                    monthsShort: 'jan._febr._mrt_apr._mei_juni_juli._aug_sep._okt._nov._dec.'.split('_'),
                    monthsParseExact: true,
                    weekdays: 'zondag_maandag_dinsdag_woensdag_donderdag_vrijdag_zaterdag'.split('_'),
                    weekdaysShort: 'zo._ma._di._wo._do._vr._za.'.split('_'),
                    weekdaysMin: 'Zo_Ma_Di_Wo_Do_Vr_Za'.split('_'),
                    weekdaysParseExact: true,
                    longDateFormat: {
                        LT: 'HH:mm',
                        LTS: 'HH:mm:ss',
                        L: 'DD/MM/YYYY',
                        LL: 'D MMMM YYYY',
                        LLL: 'D MMMM YYYY HH:mm',
                        LLLL: 'dddd D MMMM YYYY HH:mm'
                    },
                    calendar: {
                        sameDay: '[Vandaag bij] LT',
                        nextDay: '[Morgen om] LT',
                        nextWeek: 'dddd [Bij] LT',
                        lastDay: '[Gisteren om] LT',
                        lastWeek: 'dddd [laatste tot] LT',
                        sameElse: 'L'
                    },
                    relativeTime: {
                        future: 'in %s',
                        past: 'er is %s',
                        s: 'een paar seconden',
                        m: 'een minuut',
                        mm: '%d minuten',
                        h: 'een uur',
                        hh: '%d uren',
                        d: 'un jour',
                        dd: '%d dagen',
                        M: 'een maand',
                        MM: '%d maand',
                        y: 'un een',
                        yy: '%d jaar'
                    },
                    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
                    ordinal: function (number) {
                        return number + (number === 1 ? 'er' : 'e');
                    },
                    meridiemParse: /PD|MD/,
                    isPM: function (input) {
                        return input.charAt(0) === 'M';
                    },
                    // In case the meridiem units are not separated around 12, then implement
                    // this function (look at locale/id.js for an example).
                    // meridiemHour : function (hour, meridiem) {
                    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
                    // },
                    meridiem: function (hours, minutes, isLower) {
                        return hours < 12 ? 'PD' : 'MD';
                    },
                    week: {
                        dow: 1, // Monday is the first day of the week.
                        doy: 4 // Used to determine first week of the year.
                    }
                });
            }
            if (this.LanguageId.substring(0, 2) === 'ro') {
                moment.locale('ro', {
                    months: 'ianuarie_februarie_martie_aprilie_mai_iunie_iulie_august_septemberie_octombrie_noiembrie_decembrie'.split(
                        '_'
                    ),
                    monthsShort: 'ian._febr._mar_apr._mai_iun_iul._aug_sep._oct._nov._dec.'.split('_'),
                    monthsParseExact: true,
                    weekdays: 'duminica_luni_marti_miercuri_joi_vineri_sambata'.split('_'),
                    weekdaysShort: 'du._lu._ma._mi._jo._vi._sa.'.split('_'),
                    weekdaysMin: 'Du_Lu_Ma_Mi_Jo_Vi_Sa'.split('_'),
                    weekdaysParseExact: true,
                    longDateFormat: {
                        LT: 'HH:mm',
                        LTS: 'HH:mm:ss',
                        L: 'DD/MM/YYYY',
                        LL: 'D MMMM YYYY',
                        LLL: 'D MMMM YYYY HH:mm',
                        LLLL: 'dddd D MMMM YYYY HH:mm'
                    },
                    calendar: {
                        sameDay: '[Azi] LT',
                        nextDay: '[Maine] LT',
                        nextWeek: 'dddd [In] LT',
                        lastDay: '[Ieri] LT',
                        lastWeek: 'dddd [Saptamana trecuta] LT',
                        sameElse: 'L'
                    },
                    relativeTime: {
                        future: 'in %s',
                        past: 'er is %s',
                        s: 'cateva secunde',
                        m: 'un minut',
                        mm: '%d minute',
                        h: 'o ora',
                        hh: '%d ore',
                        d: 'o zi',
                        dd: '%d zile',
                        M: 'o luna',
                        MM: '%d luni',
                        y: 'un an',
                        yy: '%d ani'
                    },
                    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
                    ordinal: function (number) {
                        return number + (number === 1 ? 'er' : 'e');
                    },
                    meridiemParse: /PD|MD/,
                    isPM: function (input) {
                        return input.charAt(0) === 'M';
                    },
                    // In case the meridiem units are not separated around 12, then implement
                    // this function (look at locale/id.js for an example).
                    // meridiemHour : function (hour, meridiem) {
                    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
                    // },
                    meridiem: function (hours, minutes, isLower) {
                        return hours < 12 ? 'PD' : 'MD';
                    },
                    week: {
                        dow: 1, // Monday is the first day of the week.
                        doy: 4 // Used to determine first week of the year.
                    }
                });
            }
            if (this.LanguageId.substring(0, 2) === 'es') {
                moment.locale('es', {
                    months: 'enero_febrero_marzo_abril_mayo_juño_julio_agosto_septiembre_octubre_noviembre_diciembre'.split('_'),
                    monthsShort: 'ene._febr._marzo_abr._mayo_juño_jul._agost._sept._oct._nov._dic.'.split('_'),
                    monthsParseExact: true,
                    weekdays: 'domingo_lunes_martes_miercoles_jueves_vienres_sabado'.split('_'),
                    weekdaysShort: 'dom._lun._mar._mier._juev._viern._sab.'.split('_'),
                    weekdaysMin: 'Do_Lu_Ma_Mie_Ju_Vie_Sa'.split('_'),
                    weekdaysParseExact: true,
                    longDateFormat: {
                        LT: 'HH:mm',
                        LTS: 'HH:mm:ss',
                        L: 'DD/MM/YYYY',
                        LL: 'D MMMM YYYY',
                        LLL: 'D MMMM YYYY HH:mm',
                        LLLL: 'dddd D MMMM YYYY HH:mm'
                    },
                    calendar: {
                        sameDay: '[Hoy a ] LT',
                        nextDay: '[Mañana a] LT',
                        nextWeek: 'dddd [à] LT',
                        lastDay: '[Ayer à] LT',
                        lastWeek: 'dddd [dernier à] LT',
                        sameElse: 'L'
                    },
                    relativeTime: {
                        future: 'en %s',
                        past: 'hay %s',
                        s: 'en unos segundos',
                        m: 'un minuto',
                        mm: '%d minutos',
                        h: 'une hora',
                        hh: '%d horas',
                        d: 'un día',
                        dd: '%d días',
                        M: 'un mes',
                        MM: '%d meses',
                        y: 'un año',
                        yy: '%d años'
                    },
                    dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
                    ordinal: function (number) {
                        return number + (number === 1 ? 'er' : 'e');
                    },
                    meridiemParse: /PD|MD/,
                    isPM: function (input) {
                        return input.charAt(0) === 'M';
                    },
                    // In case the meridiem units are not separated around 12, then implement
                    // this function (look at locale/id.js for an example).
                    // meridiemHour : function (hour, meridiem) {
                    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
                    // },
                    meridiem: function (hours, minutes, isLower) {
                        return hours < 12 ? 'PD' : 'MD';
                    },
                    week: {
                        dow: 1, // Monday is the first day of the week.
                        doy: 4 // Used to determine first week of the year.
                    }
                });
            }
        }
    }

    track = (action, data, immediate) => {
        return new UA(
            action,
            data,
            !immediate
                ? this.Logger
                : new Logger(
                      {
                          lingerMs: 0
                      },
                      this
                  )
        );
    };

    wEventId = (action, context, id) => {
        let g = generateGuid(true);
        this.track(action, g).on(context, id).emit();
        return g;
    };

    wEvent = (action, context, data, id, immediate) => {
        this.track(action, data, immediate).on(context, id).emit();
    };
    //Piwik event
    pwEvent = () => {}; //window.location.href.indexOf('mobile.whise.eu') > 0 ? CustomEvent.trackEvent : () => {};

    //Piwik page view
    pwPageView = () => {}; // window.location.href.indexOf('mobile.whise.eu') > 0 ? PageViews.trackPageView : () => {};

    gaEvent = (eventCategory, eventAction) => {
        // if (window.location.href.indexOf('mobile.whise.eu') > 0) {
        //     try {
        //         ReactGA.ga('send', {
        //             hitType: 'event',
        //             eventCategory,
        //             eventAction
        //         });
        //     } catch {}
        // }
    };

    gaPageView = () => {
        // try {
        //     ReactGA.pageview(window.location.href);
        // } catch {}
    };

    fbqEvent = (eventCategory, eventAction) => {
        // if (window.location.href.indexOf('mobile.whise.eu') > 0) {
        //     try {
        //         ReactPixel.track(eventCategory, eventAction);
        //     } catch {}
        // }
    };

    setLoginDataLayer = () => {
        try {
            const currentOffice = this.Offices[this.OfficeId];
            let packageName = '';
            switch (this.DataLayerPackageType) {
                case LicenseType.WhiseFreemium:
                    packageName = 'freemium';
                    break;

                case LicenseType.WhiseBasic:
                    packageName = 'starter';
                    break;

                case LicenseType.WhiseExpert:
                    packageName = 'expert';
                    break;

                default:
                    if (!this.DataLayerPackageType && !this.IsFreemium) {
                        packageName = 'expert';
                    } else {
                        packageName = 'other';
                    }
            }

            window['dataLayer'] = window['dataLayer'] || [];
            let dataLayer = window['dataLayer'];
            dataLayer.push({
                event: 'login',
                user_id: this.UserId,
                account_id: this.ClientId,
                account_name: this.ClientName,
                subscription_plan: packageName,
                country: this.Countries[this.CountryId].isoCode,
                language: this.LanguageId,
                office_id: this.OfficeId,
                office_name: this.OfficeName,
                office_numbers: this.ActiveOffices.length,
                office_users: this.ActiveUsers.filter((x) => x.officeId === this.OfficeId).length,
                zip: currentOffice.zip,
                city: currentOffice.city
            });
        } catch (e) {}
    };

    log = (action, data) => {
        new UA(action, data, this.Logger).emit();
    };

    formatFullDate = (date) => {
        return moment(date).format('ddd DD/MM');
    };

    formatDateTime = (date, convertTz) => {
        return this.getDate(date, convertTz).format('DD/MM/YYYY HH:mm');
    };

    getDate = (date, convertTz) => {
        if (this.CountryId !== 1 && convertTz && this.ServerTz !== this.LocalTz) {
            return this.getLocalDate(date);
        }
        return moment(date);
    };

    getLocalDate = (date) => {
        return moment().tz(date, this.ServerTz).tz(this.LocalTz);
    };

    getDateFromString = (date) => {
        return moment(date); // 'YYYY-MM-DDTHH:mm:ss'
    };

    formatDateFromMoment = (date) => {
        return date.format('YYYY-MM-DD[T]HH:mm:ss');
    };

    formatTime = (date) => {
        return moment(date).format('HH:mm');
    };

    formatISODateTime(date) {
        return date.format('YYYY-MM-DDTHH:mm:ss');
    }

    formatDate(date) {
        return moment(date).format('DD/MM/YYYY');
    }

    getUrl = (relative) => {
        let url = this.StorageUrl;

        if (url.slice(-1) !== '/') {
            url += '/';
        }
        return url + relative;
    };

    getStorageUrl = (storagePath) => {
        return this.format('https://{0}.{1}', storagePath, window.env?.REACT_APP_CLIENT_URL_SUFFIX);
    };

    format = (...params) => {
        let s = params[0],
            i = params.length - 1;

        while (i--) {
            s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i + 1]);
        }
        return s;
    };
}

export default new DataService();
