import Service from './_base';
import httpEmail from '../lib/httpEmail';
import { dataService } from '../services';
import { serializeParams, externalOpenWindow, addComChannelOutlookEmail, removeComChannelOutlookEmail } from '../lib/utils';
import { getLocalStorage } from '../m/hooks/useLocalStorage';
import { userService } from './index';
import { ComChannel, RowStatus } from 'commons';
import { saveAs } from 'file-saver';
import md5 from 'md5';
import { trackPromise } from 'react-promise-tracker';

// const EmailFolderFlags = {
//     NoSelect: -1,
//     None: 0,
//     Inbox: 1,
//     Sent: 2,
//     Trash: 3,
//     Archive: 4
// };

class EmailService extends Service {
    constructor() {
        super();
        this.client = httpEmail;
    }

    /**
     * Update the current email token with a new one
     * @param token : the new token that will be used for Authorization
     */
    updateEmailToken(token) {
        this.client.defaults.headers['Authorization'] = `Bearer ${token}`;
    }

    setRequestHeaders = (emailConfig) => {
        this.headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        };

        if (emailConfig.login) {
            this.headers.username = emailConfig.login;
        }

        if (emailConfig.server) {
            this.headers.server = emailConfig.server;
        }

        if (emailConfig.sharedMailbox) {
            this.headers.sharedMailbox = emailConfig.sharedMailbox;
        }
    };

    async getOutlookCategories() {
        let response = await trackPromise(this.client.get('/categories', { headers: this.headers }));
        if (response && response.data) {
            return response.data;
        }
        return [];
    }

    async addEmailCategory(uids, categories) {
        let requestUrl = `/changemessagecategory?categories=${categories}`;

        let response = await trackPromise(
            this.client.post(requestUrl, uids, {
                headers: {
                    ...this.headers,
                    Accept: '*/*',
                    'Content-Type': 'application/json;charset=UTF-8'
                }
            })
        );
        return response.data;
    }

    async deleteOutlookCategory(categoryId) {
        let response = await trackPromise(
            this.client.post(`/categories/delete?categoryId=${categoryId}`, null, { headers: this.headers })
        );
        return response.data;
    }

    async createOutlookCategory(displayName, color) {
        let response = await trackPromise(
            this.client.post(`/categories/create?displayName=${displayName}&color=${color}`, null, {
                headers: this.headers
            })
        );
        return response.data;
    }

    async updateOutlookCategory(categoryId, color) {
        let response = await trackPromise(
            this.client.post(`/categories/update?categoryId=${categoryId}&color=${color}`, null, {
                headers: this.headers
            })
        );
        return response.data;
    }

    async requestEmailToken(userName, password) {
        userName = encodeURIComponent(userName);
        let originalData = getLocalStorage('OriginalData');
        password = originalData ? password : md5(password.toString());

        let requestPayload = `grant_type=password&username=${userName}&password=${password}`;
        let response = await trackPromise(this.client.post('/Token', requestPayload));
        return response.data;
    }

    getFolders = async () => {
        let response = await trackPromise(this.client.get('/folders', { headers: this.headers }));
        if (response && response.data) {
            return response.data;
        }
        return [];
    };

    /**
     * Given a list of filters, returns a list of emails
     * @param filters
     * @returns {Promise<void>}
     */

    getEmails = async (filters, search, page) => {
        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    let response = await this.client.get('/emails', {
                        params: {
                            searchParameters: {
                                parameters: {
                                    emailFolderName: filters.emailFolderName,
                                    keywords: search
                                },
                                pageIndex: page,
                                pageSize: 20
                            }
                        },
                        headers: this.headers
                    });

                    resolve((response && response.data) || []);
                } catch (e) {
                    reject(e);
                }
            })
        );
    };

    /**
     * Given a list of filters, returns a list of emails
     * @param filters
     * @returns {Promise<void>}
     */

    getEmail = async (folderName, uid) => {
        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    let response = await this.client.get('/emails', {
                        params: {
                            searchParameters: {
                                parameters: {
                                    emailFolderName: folderName,
                                    uid,
                                    full: 'True'
                                },
                                pageIndex: null,
                                pageSize: null
                            }
                        },
                        headers: this.headers
                    });

                    resolve((response && response.data) || []);
                } catch (e) {
                    reject(e);
                }
            })
        );
    };

    downloadAttachment(param, localFileName) {
        let requestUrl = '/emails/attachment/download';
        let headers = { ...this.headers, 'Content-Type': 'application/json;charset=utf-8' };
        let config = {
            responseType: 'arraybuffer',
            headers
        };

        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    let response = await this.client.get(requestUrl + serializeParams(param), config);

                    if (response && response.status === 200) {
                        let byteArray = new Uint8Array(response.data);
                        let blob = new Blob([byteArray], { type: 'application/octet-stream' });
                        saveAs(blob, localFileName);
                    }

                    resolve((response && response.data) || []);
                } catch (e) {
                    reject(e);
                }
            })
        );
    }

    setEmailStatus(sourcefolderid, uids, statusId) {
        let requestUrl = `/changemessagestatus?sourcefolderid=${encodeURIComponent(sourcefolderid)}&statusid=${statusId}`;
        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    let response = await this.client.post(requestUrl, uids, {
                        headers: {
                            ...this.headers,
                            Accept: '*/*',
                            'Content-Type': 'application/json;charset=UTF-8'
                        }
                    });
                    resolve((response && response.data) || []);
                } catch (e) {
                    reject(e);
                }
            })
        );
    }

    moveEmails(sourcefolderid, targetfolderid, uids) {
        let requestUrl = `movemessages?sourcefolderid=${sourcefolderid}&targetfolderid=${targetfolderid}`;
        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    let response = await this.client.post(requestUrl, uids, {
                        headers: {
                            ...this.headers,
                            'Content-Type': 'application/json;charset=UTF-8'
                        }
                    });
                    resolve((response && response.data) || []);
                } catch (e) {
                    reject(e);
                }
            })
        );
    }

    /**
     * Permanent delete for emails
     *
     * @param {string} sourceFolderId folder name
     * @param {number[]} emailIds email uids to delete
     * @param {string} username
     * @param {string} server
     * @memberof UMailService
     */
    async deleteEmails(sourceFolderId, emailIds) {
        let config = {
            headers: {
                ...this.headers,
                'Content-Type': 'application/json;charset=UTF-8'
            }
        };

        const response = await trackPromise(
            this.client.post(`deletemessages?sourcefolderid=${encodeURIComponent(sourceFolderId)}`, emailIds, config)
        );
        return response.data;
    }

    sendMail = async (request, attachments) => {
        let fd = new FormData();
        fd.append('request', JSON.stringify(request));

        let i = 0;
        for (let attachment of attachments) {
            fd.append('att' + i.toString(), attachment);
            i++;
        }

        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    let response = await this.client.post('emails/sendmail', fd, {
                        headers: this.headers
                    });
                    if (response && response.status === 400) {
                        resolve(response);
                    } else {
                        resolve(response && response.data);
                    }
                } catch (e) {
                    reject(e);
                }
            })
        );
    };

    getEmailConfiguration = async (email) => {
        let response = await this.client.get(`emails/getmailconfiguration?email=${encodeURIComponent(email)}`);
        return response.data;
    };

    updateEmailConfiguration = async (configuration) => {
        let response = await this.client.put('emails/updatemailconfiguration', configuration);
        return response.data;
    };

    async downloadAttachmentStream(param) {
        const requestUrl = '/emails/attachment/download';
        const headers = { ...this.headers, 'Content-Type': 'application/json;charset=utf-8' };
        const config = {
            responseType: 'arraybuffer',
            headers
        };
        return trackPromise(
            new Promise(async (resolve, reject) => {
                try {
                    const response = await this.client.get(requestUrl + serializeParams(param), config);
                    if (response?.status === 200) {
                        let byteArray = new Uint8Array(response.data);
                        let blob = new Blob([byteArray], {
                            type: 'application/octet-stream'
                        });
                        let file = blob;
                        file.lastModifiedDate = new Date();
                        file.name = param.parameters.name;
                        resolve(file);
                    }
                } catch (e) {
                    reject(e);
                }
            })
        );
    }

    /**
     * Create new folder
     *
     * @param {object} folderName new folder name
     * @param {string} username
     * @param {string} server
     *
     * @returns promise
     */
    createNewFolder = async (folderName, username, server) => {
        let config = {
            headers: {
                Username: username,
                Server: server
            }
        };
        let requestUrl = `folders/create?folderName=${folderName}`;
        const response = await trackPromise(this.client.post(requestUrl, null, config));
        return response.data;
    };

    /**
     * A method called after outlook authorization window is closed
     *
     * @date 16/12/2022
     * @param {*} resolve
     * @param {*} reject
     * @param {*} channelTypeId
     * @param {boolean} [returnData=false]
     */
    onOutlookAuthorizeClosed = async (resolve, reject) => {
        let data = await userService.getComChannelData(ComChannel.OutlookEmail);
        if (data) {
            addComChannelOutlookEmail(data);
            resolve(data);
        } else {
            resolve(false);
        }
    };

    /**
     * A method used to get the outlook email configuration for the given channel
     *
     * @return {object|null} configuration
     */
    getOutlookConfiguration = async (userName) => {
        let result = null;
        try {
            result = await userService.getComChannelData(ComChannel.OutlookEmail, userName);
        } catch (e) {}
        return result;
    };

    authorizeOutlook = () => {
        return new Promise(async (resolve, reject) => {
            let win = externalOpenWindow(
                `${window.env.REACT_APP_EMAIL_API_URL}/outlook/authorize?state=${dataService.UserId}`,
                800,
                800
            );
            let timer = setInterval(async () => {
                if (win.closed) {
                    clearInterval(timer);
                    this.onOutlookAuthorizeClosed(resolve, reject);
                }
            }, 1000);
        });
    };

    removeOutlookSync = async (userName) => {
        let comChannel = {
            comChannelId: ComChannel.OutlookEmail,
            userId: dataService.UserId,
            username: userName,
            rowStatus: RowStatus.Deleted
        };
        try {
            await userService.saveUserComChannel([comChannel]);
            removeComChannelOutlookEmail(userName);
            return true;
        } catch (e) {
            return false;
        }
    };

    async checkSharedMailbox(username, server, sharedMailbox, accessToken) {
        let requestUrl = '/sharedMailbox/folders';
        let config = {
            headers: {
                accessToken: accessToken,
                // 'Content-Type': 'application/x-www-form-urlencoded',
                Username: username,
                Server: server,
                SharedMailbox: sharedMailbox
            }
        };

        let response = await trackPromise(this.client.get(requestUrl, config));
        return response?.data;
    }

    /**
     * Save email as draft
     *
     * @param {object} emailData object containing from, to, subject and email informations
     * @param {string} username
     * @param {string} server
     * @param {string|null} sharedMailbox shared mailbox
     *
     * @returns promise
     */
    async saveDraftMail(emailData, username, server, sharedMailbox = null) {
        let config = {
            headers: {
                Username: username,
                Server: server,
                'content-type': 'application/json;charset=utf-8'
            }
        };
        if (sharedMailbox) {
            config.headers.SharedMailbox = sharedMailbox;
        }

        return await trackPromise(this.client.post('emails/upload', emailData, config));
    }
}

export default new EmailService();
