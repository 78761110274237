import React from 'react';
import i18next from 'i18next';
import { EVENT_SHOW_TOAST, EVENT_SHOW_ERRORS } from '@/constants';
import moment from 'moment';
import DOMPurify from 'dompurify';
import Geocode from 'react-geocode';
import { orderBy, forEach, trim, get, set, unset, cloneDeep, sortBy, first, map, find } from 'lodash';
import {
    SchedulerViewMode,
    EstateCategory,
    EstateSubdetail,
    LogCategory,
    ExportMediaStatus,
    ActivityCategory,
    LoggableField,
    EmailType,
    PhoneNumberType,
    RecurrenceType,
    EstateStatus,
    ContactStatus,
    FileStatus,
    Country,
    FunnelStatus,
    ContactRelationType,
    ComChannel,
    QuestionId,
    FinancePurchaseId,
    WhenToBuyId,
    ReasonToBuyId
} from 'commons';
import events from './events';
import { dataService, googleService } from '../services';
import { ZipAutocompleteMode } from '../constants';
import i18n from '../lib/i18n';
import { getClient } from '../lib/maps';
import { ArrowRepeat, XCircle, ArrowUpCircle } from '../assets/icons';
import { MailClicked, MailOpen, VerticalDots25 } from '../assets/svg-icons';

/**
 * Log utility
 * @param args
 * @returns {boolean|void}
 */

export const d = (...args) => {
    return window.env?.REACT_APP_ENV === 'dev' && console.log.apply(this, args);
};

/**
 * Converts parameters to event target
 * @param name
 * @param value
 * @returns {{target: {name: *, value: *}}}
 */

export const dataToEvent = (name, value) => {
    return {
        target: {
            name,
            value
        }
    };
};

export const hexToRgb = (hex) => {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255 };
    }
    return { r: 0, g: 0, c: 0 };
};

/**
 * Function that shades a given color by a given percent
 *
 * @param {string} color color in hex that needs to be shaded
 * @param {number} percent shading percentage (positive or negative)
 * @return {string} shaded color hex
 */
const shadeColor = (color, percent) => {
    var R = parseInt(color.substring(1, 3), 16);
    var G = parseInt(color.substring(3, 5), 16);
    var B = parseInt(color.substring(5, 7), 16);

    R = parseInt((R * (100 + percent)) / 100);
    G = parseInt((G * (100 + percent)) / 100);
    B = parseInt((B * (100 + percent)) / 100);

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);
    return '#' + RR + GG + BB;
};

export const getTagColor = (color) => {
    let _color;
    if (color.indexOf('#') === 0) {
        _color = color;
    } else {
        switch (color) {
            case 'preset19':
                _color = '#013220';
                break;

            default:
                _color = '#000';
                break;
        }
    }
    let rgbColor = hexToRgb(_color);
    let maxValue = 0;
    Object.values(rgbColor).forEach((x) => {
        x > maxValue && (maxValue = x);
    });
    let adjustedColor = _color;
    if (maxValue > 170) {
        adjustedColor = shadeColor(_color, -30);
    }
    return { color: adjustedColor, bgColor: `${_color}26`, borderColor: adjustedColor };
};

/**
 * Returns name from a given collection
 * @param id
 * @param data
 */

export const nameFromId = (id, data = []) => {
    let entry = data.find((i) => i.id === id);
    return get(entry, 'name', '');
};

/**
 * Returns multiple names from a given collection
 * @param ids
 * @param data
 * @param divider
 */

export const namesFromIds = (ids = [], data = [], divider = ' ') => {
    let returned = data.filter((i) => ids.indexOf(i.id) > -1).map((i) => i.name || '');

    if (returned.length > 1) {
        return returned.join(divider);
    }

    return typeof returned[0] !== 'undefined' ? returned[0] : '';
};

/**
 * Fuzzy search utility
 * @param string
 * @param search
 * @returns {boolean}
 */

export const isMatch = (string, search) => {
    let hay = string.toLowerCase(),
        i = 0,
        n = -1,
        l;
    search = search.toLowerCase();

    for (; (l = search[i++]); ) if (!~(n = hay.indexOf(l, n + 1))) return false;

    return true;
};

/**
 * Converts an array to a map
 * @param data
 * @param key
 */

export const arrayToMap = (data = [], key = 'id') => {
    return data.reduce((all, current) => {
        return {
            ...all,
            [current[key]]: current
        };
    }, {});
};

/**
 * Shows the global toast from Layout
 * @param message
 * @param type
 */

export const showToast = (message, type = 'success') => {
    events.emit(EVENT_SHOW_TOAST, {
        message,
        type
    });
};

/**
 * Passes errors object to errors dialog
 * @param errors
 */

export const showErrors = (errors = []) => {
    events.emit(EVENT_SHOW_ERRORS, errors);
};

/**
 * Capitalizes a string
 * @param str
 */

export const capitalize = (str = '') => {
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
};

/**
 * Checks if id exists inside collection
 * @param id
 * @param collection
 */

export const idExists = (id, collection = []) => {
    return collection.find((i) => i.id === id);
};

/**
 * Maps an appointment to FullCalendar event
 * @param entry
 */

export const appointmentToEvent = (entry = {}) => {
    let event = {
        title: entry.subject,
        id: entry.id,
        start: moment(entry.startDateTime).toDate(),
        end: moment(entry.endDateTime).toDate(),
        resourceId: 'a',
        allDay: entry.allDay,
        __original: entry
    };

    if (entry.pattern) {
        let rrule = `RRULE:${entry.pattern}`;
        let startTime = moment(entry.startDateTime).format('YYYYMMDDTHHmmss');

        rrule = `DTSTART:${startTime}Z\n${rrule}`;

        if (entry.patternEndDate) {
            let endTime = moment(entry.patternEndDate).format('YYYYMMDDTHHmmss') + 'Z';
            rrule = `${rrule}UNTIL=${endTime}`;
        }

        if (rrule[rrule.length - 1] === ';') {
            rrule = rrule.substr(0, rrule.length - 1);
        }

        event.rrule = rrule;

        let start = moment(entry.startDateTime);
        let end = moment(entry.endDateTime);
        let duration = moment.duration(end.diff(start));

        event.duration = {
            hours: duration.asHours()
        };
    }

    return event;
};

/**
 * Returns the agenda interval for swipe feature
 * @param startDate
 * @param viewMode
 * @returns {Array}
 */

export const calculateAgendaInterval = (startDate, viewMode) => {
    let result = [];
    let endDate = startDate.clone();

    if (viewMode === SchedulerViewMode.WeekView || viewMode === SchedulerViewMode.List) {
        startDate = startDate.clone().startOf('isoweek');
        endDate = startDate.clone().add({ days: 6 });
    }

    if (viewMode === SchedulerViewMode.WorkingWeekView) {
        startDate = startDate.clone().startOf('isoweek');
        endDate = startDate.clone().add({ days: 6 });
    }

    if (viewMode === SchedulerViewMode.MonthView) {
        startDate = startDate.clone().startOf('month').add({ days: -3 });

        endDate = startDate.clone().add({ months: 1 }).add({ days: 3 });
    }

    result.push(startDate);
    result.push(endDate);

    return result;
};

/**
 * Formats date for being valid for server
 * @param date
 * @returns {*}
 */

export const formatDate = (date) => {
    return moment(date).format('YYYY-MM-DD[T]HH:mm:ss');
};

export const formatDateShort = (date) => {
    return moment(date).format('DD/MM/YYYY');
};

/**
 * Formats dates including the time, but without seconds
 *
 * @param {dateTime} date the date to be formatted
 *
 * @returns {string} date in the format: "DD/MM/YYYY HH:mm:ss"
 */
export const formatDateFullWithoutSeconds = (date) => {
    return moment(date).format('DD/MM/YYYY HH:mm');
};

/**
 * Generate text color that fits background
 * @param bgColor
 * @returns {string}
 */

export const idealTextColor = (bgColor) => {
    let nThreshold = 105;
    let r = bgColor.substring(1, 3);
    let g = bgColor.substring(3, 5);
    let b = bgColor.substring(5, 7);

    const components = {
        R: parseInt(r, 16),
        G: parseInt(g, 16),
        B: parseInt(b, 16)
    };

    let bgDelta = components.R * 0.299 + components.G * 0.587 + components.B * 0.114;
    return 255 - bgDelta < nThreshold ? '#000000' : '#ffffff';
};

/**
 * Image import helper
 * @param path
 * @returns {any}
 */

export const img = (path) => {
    try {
        return require(`assets/img/${path}`);
    } catch {
        d('Could not find asset with path', path);
    }
};

/**
 * If doesn't have
 * @returns {boolean}
 */

export const redirectNotAuthenticated = () => {
    let token = localStorage.getItem('AccessToken');

    if (!token) {
        //window.location = '/login.html';
        return false;
    }

    return true;
};

/**
 * Given a string, removes its diacritics
 * @param str
 * @returns {string|*}
 */

export const removeDiacritics = (str) => {
    if (!str) {
        return '';
    }

    let diacritics = {
        '\u24B6': 'A',
        Ａ: 'A',
        À: 'A',
        Á: 'A',
        Â: 'A',
        Ầ: 'A',
        Ấ: 'A',
        Ẫ: 'A',
        Ẩ: 'A',
        Ã: 'A',
        Ā: 'A',
        Ă: 'A',
        Ằ: 'A',
        Ắ: 'A',
        Ẵ: 'A',
        Ẳ: 'A',
        Ȧ: 'A',
        Ǡ: 'A',
        Ä: 'A',
        Ǟ: 'A',
        Ả: 'A',
        Å: 'A',
        Ǻ: 'A',
        Ǎ: 'A',
        Ȁ: 'A',
        Ȃ: 'A',
        Ạ: 'A',
        Ậ: 'A',
        Ặ: 'A',
        Ḁ: 'A',
        Ą: 'A',
        Ⱥ: 'A',
        Ɐ: 'A',
        Ꜳ: 'AA',
        Æ: 'AE',
        Ǽ: 'AE',
        Ǣ: 'AE',
        Ꜵ: 'AO',
        Ꜷ: 'AU',
        Ꜹ: 'AV',
        Ꜻ: 'AV',
        Ꜽ: 'AY',
        '\u24B7': 'B',
        Ｂ: 'B',
        Ḃ: 'B',
        Ḅ: 'B',
        Ḇ: 'B',
        Ƀ: 'B',
        Ƃ: 'B',
        Ɓ: 'B',
        '\u24B8': 'C',
        Ｃ: 'C',
        Ć: 'C',
        Ĉ: 'C',
        Ċ: 'C',
        Č: 'C',
        Ç: 'C',
        Ḉ: 'C',
        Ƈ: 'C',
        Ȼ: 'C',
        Ꜿ: 'C',
        '\u24B9': 'D',
        Ｄ: 'D',
        Ḋ: 'D',
        Ď: 'D',
        Ḍ: 'D',
        Ḑ: 'D',
        Ḓ: 'D',
        Ḏ: 'D',
        Đ: 'D',
        Ƌ: 'D',
        Ɗ: 'D',
        Ɖ: 'D',
        Ꝺ: 'D',
        Ǳ: 'DZ',
        Ǆ: 'DZ',
        ǲ: 'Dz',
        ǅ: 'Dz',
        '\u24BA': 'E',
        Ｅ: 'E',
        È: 'E',
        É: 'E',
        Ê: 'E',
        Ề: 'E',
        Ế: 'E',
        Ễ: 'E',
        Ể: 'E',
        Ẽ: 'E',
        Ē: 'E',
        Ḕ: 'E',
        Ḗ: 'E',
        Ĕ: 'E',
        Ė: 'E',
        Ë: 'E',
        Ẻ: 'E',
        Ě: 'E',
        Ȅ: 'E',
        Ȇ: 'E',
        Ẹ: 'E',
        Ệ: 'E',
        Ȩ: 'E',
        Ḝ: 'E',
        Ę: 'E',
        Ḙ: 'E',
        Ḛ: 'E',
        Ɛ: 'E',
        Ǝ: 'E',
        '\u24BB': 'F',
        Ｆ: 'F',
        Ḟ: 'F',
        Ƒ: 'F',
        Ꝼ: 'F',
        '\u24BC': 'G',
        Ｇ: 'G',
        Ǵ: 'G',
        Ĝ: 'G',
        Ḡ: 'G',
        Ğ: 'G',
        Ġ: 'G',
        Ǧ: 'G',
        Ģ: 'G',
        Ǥ: 'G',
        Ɠ: 'G',
        Ꞡ: 'G',
        Ᵹ: 'G',
        Ꝿ: 'G',
        '\u24BD': 'H',
        Ｈ: 'H',
        Ĥ: 'H',
        Ḣ: 'H',
        Ḧ: 'H',
        Ȟ: 'H',
        Ḥ: 'H',
        Ḩ: 'H',
        Ḫ: 'H',
        Ħ: 'H',
        Ⱨ: 'H',
        Ⱶ: 'H',
        Ɥ: 'H',
        '\u24BE': 'I',
        Ｉ: 'I',
        Ì: 'I',
        Í: 'I',
        Î: 'I',
        Ĩ: 'I',
        Ī: 'I',
        Ĭ: 'I',
        İ: 'I',
        Ï: 'I',
        Ḯ: 'I',
        Ỉ: 'I',
        Ǐ: 'I',
        Ȉ: 'I',
        Ȋ: 'I',
        Ị: 'I',
        Į: 'I',
        Ḭ: 'I',
        Ɨ: 'I',
        '\u24BF': 'J',
        Ｊ: 'J',
        Ĵ: 'J',
        Ɉ: 'J',
        '\u24C0': 'K',
        Ｋ: 'K',
        Ḱ: 'K',
        Ǩ: 'K',
        Ḳ: 'K',
        Ķ: 'K',
        Ḵ: 'K',
        Ƙ: 'K',
        Ⱪ: 'K',
        Ꝁ: 'K',
        Ꝃ: 'K',
        Ꝅ: 'K',
        Ꞣ: 'K',
        '\u24C1': 'L',
        Ｌ: 'L',
        Ŀ: 'L',
        Ĺ: 'L',
        Ľ: 'L',
        Ḷ: 'L',
        Ḹ: 'L',
        Ļ: 'L',
        Ḽ: 'L',
        Ḻ: 'L',
        Ł: 'L',
        Ƚ: 'L',
        Ɫ: 'L',
        Ⱡ: 'L',
        Ꝉ: 'L',
        Ꝇ: 'L',
        Ꞁ: 'L',
        Ǉ: 'LJ',
        ǈ: 'Lj',
        '\u24C2': 'M',
        Ｍ: 'M',
        Ḿ: 'M',
        Ṁ: 'M',
        Ṃ: 'M',
        Ɱ: 'M',
        Ɯ: 'M',
        '\u24C3': 'N',
        Ｎ: 'N',
        Ǹ: 'N',
        Ń: 'N',
        Ñ: 'N',
        Ṅ: 'N',
        Ň: 'N',
        Ṇ: 'N',
        Ņ: 'N',
        Ṋ: 'N',
        Ṉ: 'N',
        Ƞ: 'N',
        Ɲ: 'N',
        Ꞑ: 'N',
        Ꞥ: 'N',
        Ǌ: 'NJ',
        ǋ: 'Nj',
        '\u24C4': 'O',
        Ｏ: 'O',
        Ò: 'O',
        Ó: 'O',
        Ô: 'O',
        Ồ: 'O',
        Ố: 'O',
        Ỗ: 'O',
        Ổ: 'O',
        Õ: 'O',
        Ṍ: 'O',
        Ȭ: 'O',
        Ṏ: 'O',
        Ō: 'O',
        Ṑ: 'O',
        Ṓ: 'O',
        Ŏ: 'O',
        Ȯ: 'O',
        Ȱ: 'O',
        Ö: 'O',
        Ȫ: 'O',
        Ỏ: 'O',
        Ő: 'O',
        Ǒ: 'O',
        Ȍ: 'O',
        Ȏ: 'O',
        Ơ: 'O',
        Ờ: 'O',
        Ớ: 'O',
        Ỡ: 'O',
        Ở: 'O',
        Ợ: 'O',
        Ọ: 'O',
        Ộ: 'O',
        Ǫ: 'O',
        Ǭ: 'O',
        Ø: 'O',
        Ǿ: 'O',
        Ɔ: 'O',
        Ɵ: 'O',
        Ꝋ: 'O',
        Ꝍ: 'O',
        Œ: 'OE',
        Ƣ: 'OI',
        Ꝏ: 'OO',
        Ȣ: 'OU',
        '\u24C5': 'P',
        Ｐ: 'P',
        Ṕ: 'P',
        Ṗ: 'P',
        Ƥ: 'P',
        Ᵽ: 'P',
        Ꝑ: 'P',
        Ꝓ: 'P',
        Ꝕ: 'P',
        '\u24C6': 'Q',
        Ｑ: 'Q',
        Ꝗ: 'Q',
        Ꝙ: 'Q',
        Ɋ: 'Q',
        '\u24C7': 'R',
        Ｒ: 'R',
        Ŕ: 'R',
        Ṙ: 'R',
        Ř: 'R',
        Ȑ: 'R',
        Ȓ: 'R',
        Ṛ: 'R',
        Ṝ: 'R',
        Ŗ: 'R',
        Ṟ: 'R',
        Ɍ: 'R',
        Ɽ: 'R',
        Ꝛ: 'R',
        Ꞧ: 'R',
        Ꞃ: 'R',
        '\u24C8': 'S',
        Ｓ: 'S',
        Ś: 'S',
        Ṥ: 'S',
        Ŝ: 'S',
        Ṡ: 'S',
        Š: 'S',
        Ṧ: 'S',
        Ṣ: 'S',
        Ṩ: 'S',
        Ș: 'S',
        Ş: 'S',
        Ȿ: 'S',
        Ꞩ: 'S',
        Ꞅ: 'S',
        ẞ: 'SS',
        '\u24C9': 'T',
        Ｔ: 'T',
        Ṫ: 'T',
        Ť: 'T',
        Ṭ: 'T',
        Ț: 'T',
        Ţ: 'T',
        Ṱ: 'T',
        Ṯ: 'T',
        Ŧ: 'T',
        Ƭ: 'T',
        Ʈ: 'T',
        Ⱦ: 'T',
        Ꞇ: 'T',
        Ꜩ: 'TZ',
        '\u24CA': 'U',
        Ｕ: 'U',
        Ù: 'U',
        Ú: 'U',
        Û: 'U',
        Ũ: 'U',
        Ṹ: 'U',
        Ū: 'U',
        Ṻ: 'U',
        Ŭ: 'U',
        Ü: 'U',
        Ǜ: 'U',
        Ǘ: 'U',
        Ǖ: 'U',
        Ǚ: 'U',
        Ủ: 'U',
        Ů: 'U',
        Ű: 'U',
        Ǔ: 'U',
        Ȕ: 'U',
        Ȗ: 'U',
        Ư: 'U',
        Ừ: 'U',
        Ứ: 'U',
        Ữ: 'U',
        Ử: 'U',
        Ự: 'U',
        Ụ: 'U',
        Ṳ: 'U',
        Ų: 'U',
        Ṷ: 'U',
        Ṵ: 'U',
        Ʉ: 'U',
        '\u24CB': 'V',
        Ｖ: 'V',
        Ṽ: 'V',
        Ṿ: 'V',
        Ʋ: 'V',
        Ꝟ: 'V',
        Ʌ: 'V',
        Ꝡ: 'VY',
        '\u24CC': 'W',
        Ｗ: 'W',
        Ẁ: 'W',
        Ẃ: 'W',
        Ŵ: 'W',
        Ẇ: 'W',
        Ẅ: 'W',
        Ẉ: 'W',
        Ⱳ: 'W',
        '\u24CD': 'X',
        Ｘ: 'X',
        Ẋ: 'X',
        Ẍ: 'X',
        '\u24CE': 'Y',
        Ｙ: 'Y',
        Ỳ: 'Y',
        Ý: 'Y',
        Ŷ: 'Y',
        Ỹ: 'Y',
        Ȳ: 'Y',
        Ẏ: 'Y',
        Ÿ: 'Y',
        Ỷ: 'Y',
        Ỵ: 'Y',
        Ƴ: 'Y',
        Ɏ: 'Y',
        Ỿ: 'Y',
        '\u24CF': 'Z',
        Ｚ: 'Z',
        Ź: 'Z',
        Ẑ: 'Z',
        Ż: 'Z',
        Ž: 'Z',
        Ẓ: 'Z',
        Ẕ: 'Z',
        Ƶ: 'Z',
        Ȥ: 'Z',
        Ɀ: 'Z',
        Ⱬ: 'Z',
        Ꝣ: 'Z',
        '\u24D0': 'a',
        ａ: 'a',
        ẚ: 'a',
        à: 'a',
        á: 'a',
        â: 'a',
        ầ: 'a',
        ấ: 'a',
        ẫ: 'a',
        ẩ: 'a',
        ã: 'a',
        ā: 'a',
        ă: 'a',
        ằ: 'a',
        ắ: 'a',
        ẵ: 'a',
        ẳ: 'a',
        ȧ: 'a',
        ǡ: 'a',
        ä: 'a',
        ǟ: 'a',
        ả: 'a',
        å: 'a',
        ǻ: 'a',
        ǎ: 'a',
        ȁ: 'a',
        ȃ: 'a',
        ạ: 'a',
        ậ: 'a',
        ặ: 'a',
        ḁ: 'a',
        ą: 'a',
        ⱥ: 'a',
        ɐ: 'a',
        ꜳ: 'aa',
        æ: 'ae',
        ǽ: 'ae',
        ǣ: 'ae',
        ꜵ: 'ao',
        ꜷ: 'au',
        ꜹ: 'av',
        ꜻ: 'av',
        ꜽ: 'ay',
        '\u24D1': 'b',
        ｂ: 'b',
        ḃ: 'b',
        ḅ: 'b',
        ḇ: 'b',
        ƀ: 'b',
        ƃ: 'b',
        ɓ: 'b',
        '\u24D2': 'c',
        ｃ: 'c',
        ć: 'c',
        ĉ: 'c',
        ċ: 'c',
        č: 'c',
        ç: 'c',
        ḉ: 'c',
        ƈ: 'c',
        ȼ: 'c',
        ꜿ: 'c',
        ↄ: 'c',
        '\u24D3': 'd',
        ｄ: 'd',
        ḋ: 'd',
        ď: 'd',
        ḍ: 'd',
        ḑ: 'd',
        ḓ: 'd',
        ḏ: 'd',
        đ: 'd',
        ƌ: 'd',
        ɖ: 'd',
        ɗ: 'd',
        ꝺ: 'd',
        ǳ: 'dz',
        ǆ: 'dz',
        '\u24D4': 'e',
        ｅ: 'e',
        è: 'e',
        é: 'e',
        ê: 'e',
        ề: 'e',
        ế: 'e',
        ễ: 'e',
        ể: 'e',
        ẽ: 'e',
        ē: 'e',
        ḕ: 'e',
        ḗ: 'e',
        ĕ: 'e',
        ė: 'e',
        ë: 'e',
        ẻ: 'e',
        ě: 'e',
        ȅ: 'e',
        ȇ: 'e',
        ẹ: 'e',
        ệ: 'e',
        ȩ: 'e',
        ḝ: 'e',
        ę: 'e',
        ḙ: 'e',
        ḛ: 'e',
        ɇ: 'e',
        ɛ: 'e',
        ǝ: 'e',
        '\u24D5': 'f',
        ｆ: 'f',
        ḟ: 'f',
        ƒ: 'f',
        ꝼ: 'f',
        '\u24D6': 'g',
        ｇ: 'g',
        ǵ: 'g',
        ĝ: 'g',
        ḡ: 'g',
        ğ: 'g',
        ġ: 'g',
        ǧ: 'g',
        ģ: 'g',
        ǥ: 'g',
        ɠ: 'g',
        ꞡ: 'g',
        ᵹ: 'g',
        ꝿ: 'g',
        '\u24D7': 'h',
        ｈ: 'h',
        ĥ: 'h',
        ḣ: 'h',
        ḧ: 'h',
        ȟ: 'h',
        ḥ: 'h',
        ḩ: 'h',
        ḫ: 'h',
        ẖ: 'h',
        ħ: 'h',
        ⱨ: 'h',
        ⱶ: 'h',
        ɥ: 'h',
        ƕ: 'hv',
        '\u24D8': 'i',
        ｉ: 'i',
        ì: 'i',
        í: 'i',
        î: 'i',
        ĩ: 'i',
        ī: 'i',
        ĭ: 'i',
        ï: 'i',
        ḯ: 'i',
        ỉ: 'i',
        ǐ: 'i',
        ȉ: 'i',
        ȋ: 'i',
        ị: 'i',
        į: 'i',
        ḭ: 'i',
        ɨ: 'i',
        ı: 'i',
        '\u24D9': 'j',
        ｊ: 'j',
        ĵ: 'j',
        ǰ: 'j',
        ɉ: 'j',
        '\u24DA': 'k',
        ｋ: 'k',
        ḱ: 'k',
        ǩ: 'k',
        ḳ: 'k',
        ķ: 'k',
        ḵ: 'k',
        ƙ: 'k',
        ⱪ: 'k',
        ꝁ: 'k',
        ꝃ: 'k',
        ꝅ: 'k',
        ꞣ: 'k',
        '\u24DB': 'l',
        ｌ: 'l',
        ŀ: 'l',
        ĺ: 'l',
        ľ: 'l',
        ḷ: 'l',
        ḹ: 'l',
        ļ: 'l',
        ḽ: 'l',
        ḻ: 'l',
        ł: 'l',
        ƚ: 'l',
        ɫ: 'l',
        ⱡ: 'l',
        ꝉ: 'l',
        ꞁ: 'l',
        ꝇ: 'l',
        ǉ: 'lj',
        '\u24DC': 'm',
        ｍ: 'm',
        ḿ: 'm',
        ṁ: 'm',
        ṃ: 'm',
        ɱ: 'm',
        ɯ: 'm',
        '\u24DD': 'n',
        ｎ: 'n',
        ǹ: 'n',
        ń: 'n',
        ñ: 'n',
        ṅ: 'n',
        ň: 'n',
        ṇ: 'n',
        ņ: 'n',
        ṋ: 'n',
        ṉ: 'n',
        ƞ: 'n',
        ɲ: 'n',
        ŉ: 'n',
        ꞑ: 'n',
        ꞥ: 'n',
        ǌ: 'nj',
        '\u24DE': 'o',
        ｏ: 'o',
        ò: 'o',
        ó: 'o',
        ô: 'o',
        ồ: 'o',
        ố: 'o',
        ỗ: 'o',
        ổ: 'o',
        õ: 'o',
        ṍ: 'o',
        ȭ: 'o',
        ṏ: 'o',
        ō: 'o',
        ṑ: 'o',
        ṓ: 'o',
        ŏ: 'o',
        ȯ: 'o',
        ȱ: 'o',
        ö: 'o',
        ȫ: 'o',
        ỏ: 'o',
        ő: 'o',
        ǒ: 'o',
        ȍ: 'o',
        ȏ: 'o',
        ơ: 'o',
        ờ: 'o',
        ớ: 'o',
        ỡ: 'o',
        ở: 'o',
        ợ: 'o',
        ọ: 'o',
        ộ: 'o',
        ǫ: 'o',
        ǭ: 'o',
        ø: 'o',
        ǿ: 'o',
        ɔ: 'o',
        ꝋ: 'o',
        ꝍ: 'o',
        ɵ: 'o',
        œ: 'oe',
        ɶ: 'oe',
        ƣ: 'oi',
        ȣ: 'ou',
        ꝏ: 'oo',
        '\u24DF': 'p',
        ｐ: 'p',
        ṕ: 'p',
        ṗ: 'p',
        ƥ: 'p',
        ᵽ: 'p',
        ꝑ: 'p',
        ꝓ: 'p',
        ꝕ: 'p',
        '\u24E0': 'q',
        ｑ: 'q',
        ɋ: 'q',
        ꝗ: 'q',
        ꝙ: 'q',
        '\u24E1': 'r',
        ｒ: 'r',
        ŕ: 'r',
        ṙ: 'r',
        ř: 'r',
        ȑ: 'r',
        ȓ: 'r',
        ṛ: 'r',
        ṝ: 'r',
        ŗ: 'r',
        ṟ: 'r',
        ɍ: 'r',
        ɽ: 'r',
        ꝛ: 'r',
        ꞧ: 'r',
        ꞃ: 'r',
        '\u24E2': 's',
        ｓ: 's',
        ś: 's',
        ṥ: 's',
        ŝ: 's',
        ṡ: 's',
        š: 's',
        ṧ: 's',
        ṣ: 's',
        ṩ: 's',
        ș: 's',
        ş: 's',
        ȿ: 's',
        ꞩ: 's',
        ꞅ: 's',
        ſ: 's',
        ẛ: 's',
        ß: 'ss',
        '\u24E3': 't',
        ｔ: 't',
        ṫ: 't',
        ẗ: 't',
        ť: 't',
        ṭ: 't',
        ț: 't',
        ţ: 't',
        ṱ: 't',
        ṯ: 't',
        ŧ: 't',
        ƭ: 't',
        ʈ: 't',
        ⱦ: 't',
        ꞇ: 't',
        ꜩ: 'tz',
        '\u24E4': 'u',
        ｕ: 'u',
        ù: 'u',
        ú: 'u',
        û: 'u',
        ũ: 'u',
        ṹ: 'u',
        ū: 'u',
        ṻ: 'u',
        ŭ: 'u',
        ü: 'u',
        ǜ: 'u',
        ǘ: 'u',
        ǖ: 'u',
        ǚ: 'u',
        ủ: 'u',
        ů: 'u',
        ű: 'u',
        ǔ: 'u',
        ȕ: 'u',
        ȗ: 'u',
        ư: 'u',
        ừ: 'u',
        ứ: 'u',
        ữ: 'u',
        ử: 'u',
        ự: 'u',
        ụ: 'u',
        ṳ: 'u',
        ų: 'u',
        ṷ: 'u',
        ṵ: 'u',
        ʉ: 'u',
        '\u24E5': 'v',
        ｖ: 'v',
        ṽ: 'v',
        ṿ: 'v',
        ʋ: 'v',
        ꝟ: 'v',
        ʌ: 'v',
        ꝡ: 'vy',
        '\u24E6': 'w',
        ｗ: 'w',
        ẁ: 'w',
        ẃ: 'w',
        ŵ: 'w',
        ẇ: 'w',
        ẅ: 'w',
        ẘ: 'w',
        ẉ: 'w',
        ⱳ: 'w',
        '\u24E7': 'x',
        ｘ: 'x',
        ẋ: 'x',
        ẍ: 'x',
        '\u24E8': 'y',
        ｙ: 'y',
        ỳ: 'y',
        ý: 'y',
        ŷ: 'y',
        ỹ: 'y',
        ȳ: 'y',
        ẏ: 'y',
        ÿ: 'y',
        ỷ: 'y',
        ẙ: 'y',
        ỵ: 'y',
        ƴ: 'y',
        ɏ: 'y',
        ỿ: 'y',
        '\u24E9': 'z',
        ｚ: 'z',
        ź: 'z',
        ẑ: 'z',
        ż: 'z',
        ž: 'z',
        ẓ: 'z',
        ẕ: 'z',
        ƶ: 'z',
        ȥ: 'z',
        ɀ: 'z',
        ⱬ: 'z',
        ꝣ: 'z',
        '\uFF10': '0',
        '\u2080': '0',
        '\u24EA': '0',
        '\u2070': '0',
        '\u00B9': '1',
        '\u2474': '1',
        '\u2081': '1',
        '\u2776': '1',
        '\u24F5': '1',
        '\u2488': '1',
        '\u2460': '1',
        '\uFF11': '1',
        '\u00B2': '2',
        '\u2777': '2',
        '\u2475': '2',
        '\uFF12': '2',
        '\u2082': '2',
        '\u24F6': '2',
        '\u2461': '2',
        '\u2489': '2',
        '\u00B3': '3',
        '\uFF13': '3',
        '\u248A': '3',
        '\u2476': '3',
        '\u2083': '3',
        '\u2778': '3',
        '\u24F7': '3',
        '\u2462': '3',
        '\u24F8': '4',
        '\u2463': '4',
        '\u248B': '4',
        '\uFF14': '4',
        '\u2074': '4',
        '\u2084': '4',
        '\u2779': '4',
        '\u2477': '4',
        '\u248C': '5',
        '\u2085': '5',
        '\u24F9': '5',
        '\u2478': '5',
        '\u277A': '5',
        '\u2464': '5',
        '\uFF15': '5',
        '\u2075': '5',
        '\u2479': '6',
        '\u2076': '6',
        '\uFF16': '6',
        '\u277B': '6',
        '\u2086': '6',
        '\u2465': '6',
        '\u24FA': '6',
        '\u248D': '6',
        '\uFF17': '7',
        '\u2077': '7',
        '\u277C': '7',
        '\u24FB': '7',
        '\u248E': '7',
        '\u2087': '7',
        '\u247A': '7',
        '\u2466': '7',
        '\u2467': '8',
        '\u248F': '8',
        '\u24FC': '8',
        '\u247B': '8',
        '\u2078': '8',
        '\uFF18': '8',
        '\u277D': '8',
        '\u2088': '8',
        '\u24FD': '9',
        '\uFF19': '9',
        '\u2490': '9',
        '\u277E': '9',
        '\u247C': '9',
        '\u2089': '9',
        '\u2468': '9',
        '\u2079': '9'
    };
    let chars = str.split(''),
        i = chars.length - 1,
        alter = false,
        ch;
    for (; i >= 0; i--) {
        ch = chars[i];
        if (diacritics.hasOwnProperty(ch)) {
            chars[i] = diacritics[ch];
            alter = true;
        }
    }
    if (alter) {
        str = chars.join('');
    }

    return str;
};

/**
 * Generates a random password
 * @returns {string}
 */

export const generatePassword = () => Math.random().toString(36).slice(-8);

/**
 * Modifies an object and returns a new one based on rules
 * @param initial
 * @param rules
 * @param clone
 */

export const normalize = (initial = {}, rules = {}, clone = true) => {
    let result = {};

    if (clone) {
        result = cloneDeep(initial);
    }

    for (const k in rules) {
        if (!rules.hasOwnProperty(k)) {
            continue;
        }

        let current = rules[k];
        let value = get(initial, k, '');

        if (Array.isArray(current)) {
            let shouldSet = true;

            if (typeof current[1] === 'function') {
                let changed = current[1](value, result);

                if (typeof changed !== 'undefined') {
                    value = changed;
                } else {
                    shouldSet = false;
                }
            }

            if (current[0] && shouldSet) {
                set(result, current[0], value);
            }

            unset(result, k);
        }

        if (typeof current === 'function') {
            set(result, k, current(value));
        }

        if (typeof current === 'string') {
            set(result, current, value);
            unset(result, k);
        }
    }

    return result;
};

export const hsl2rgb = (h, s, l) => {
    let r, g, b, m, c, x;

    if (!isFinite(h)) {
        h = 0;
    }
    if (!isFinite(s)) {
        s = 0;
    }
    if (!isFinite(l)) {
        l = 0;
    }

    h /= 60;
    if (h < 0) {
        h = 6 - (-h % 6);
    }
    h %= 6;

    s = Math.max(0, Math.min(1, s / 100));
    l = Math.max(0, Math.min(1, l / 100));

    c = (1 - Math.abs(2 * l - 1)) * s;
    x = c * (1 - Math.abs((h % 2) - 1));

    if (h < 1) {
        r = c;
        g = x;
        b = 0;
    } else if (h < 2) {
        r = x;
        g = c;
        b = 0;
    } else if (h < 3) {
        r = 0;
        g = c;
        b = x;
    } else if (h < 4) {
        r = 0;
        g = x;
        b = c;
    } else if (h < 5) {
        r = x;
        g = 0;
        b = c;
    } else {
        r = c;
        g = 0;
        b = x;
    }

    m = l - c / 2;
    r = Math.round((r + m) * 255);
    g = Math.round((g + m) * 255);
    b = Math.round((b + m) * 255);

    return { r, g, b };
};

export const getColorFromString = (string) => {
    if (!string) {
        return '#e4e4e4';
    }
    //var sanitized = string.replace(/[^A-Za-z]/, '');
    let letters = string.split('');

    //Determine the hue
    let hue = Math.floor(((letters[0].toLowerCase().charCodeAt(0) - 96) / 26) * 360);
    let ord = 0;
    for (let i = 0; i < letters.length; i++) {
        ord = letters[i].charCodeAt(0);
        if ((ord >= 65 && ord <= 90) || (ord >= 97 && ord <= 122)) {
            hue += ord - 64;
        }
    }

    hue = hue % 360;

    //Determine the saturation
    let vowels = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u'];
    let count_cons = 0;

    //Count the consonants
    for (let i = 0; i < letters.length; i++) {
        if (vowels.indexOf(letters[i]) === -1) {
            count_cons++;
        }
    }

    //Determine what percentage of the string is consonants and weight to 95% being fully saturated.
    let saturation = (count_cons / letters.length / 0.95) * 100;
    if (saturation > 100) {
        saturation = 100;
    }

    //Determine the luminosity
    let ascenders = ['t', 'd', 'b', 'l', 'f', 'h', 'k'];
    let descenders = ['q', 'y', 'p', 'g', 'j'];
    let luminosity = 50;
    let increment = (1 / letters.length) * 50;

    for (var i = 0; i < letters.length; i++) {
        if (ascenders.indexOf(letters[i]) !== -1) {
            luminosity += increment;
        } else if (descenders.indexOf(letters[i]) !== -1) {
            luminosity -= increment * 2;
        }
    }
    if (luminosity > 100) {
        luminosity = 100;
    }

    let rgb = hsl2rgb(hue, saturation, luminosity);
    return 'rgb(' + rgb.r + ',' + rgb.g + ', ' + rgb.b + ')';
};

/**
 * Sanitizes html
 * @param html
 * @param tags
 * @returns {*|String|Node|null|string}
 */

export const sanitize = (html, tags = []) => {
    return DOMPurify.sanitize(html, { ALLOWED_TAGS: tags });
};

/**
 * Returns entity category
 * @param categoryId
 * @param isParent
 * @returns {string}
 */

export const getCategoryIcon = (categoryId, isParent = false) => {
    if (isParent) {
        return 'flaticon-projects';
    }

    switch (categoryId) {
        case EstateCategory.House:
            return 'flaticon-house';

        case EstateCategory.Flat:
            return 'flaticon-appartment';

        case EstateCategory.Plot:
            return 'flaticon-ground-area';

        case EstateCategory.Office:
            return 'flaticon-office-space';

        case EstateCategory.Commercial:
            return 'flaticon-comercial';

        case EstateCategory.Industrial:
            return 'flaticon-industrial';

        case EstateCategory.Garage:
            return 'flaticon-garage';

        default:
            return '';
    }
};

export const getMLValue = (arr, name, useDefault) => {
    name = name || 'name';
    let lang = (i18n.language || '').toLowerCase();
    let result = arr.find((x) => x.languageId.toLowerCase() === lang);
    if (useDefault && !result) {
        result = arr[0];
    }
    return result && result[name] ? result[name] : '';
};

export const maybeMLValue = (x) => {
    if (x.nameML) {
        return getMLValue(x.nameML);
    }

    return false;
};

export const getMLValueForLang = (arr = [], languageId, name) => {
    name = name || 'name';
    let langIdLowerCase = languageId.toLowerCase();
    let result = arr.find((x) => x.languageId.toLowerCase() === langIdLowerCase);
    return result && result[name] ? result[name] : '';
};

const formatPrice = (n, decimalPlaces, thousandSeparator, decimalSeparator, currencySymbol) => {
    if (typeof n == 'undefined') {
        return '';
    }

    decimalPlaces = isNaN((decimalPlaces = Math.abs(decimalPlaces))) ? 2 : decimalPlaces;
    decimalSeparator = decimalSeparator === undefined ? '.' : decimalSeparator;
    thousandSeparator = thousandSeparator === undefined ? ',' : thousandSeparator;

    let sign = n < 0 ? '-' : '',
        i = parseInt((n = Math.abs(+n || 0).toFixed(decimalPlaces))) + '',
        j = i.length > 3 ? i.length % 3 : 0;

    let decimals = Math.abs(n - parseInt(i))
        .toFixed(decimalPlaces)
        .slice(2);

    let formattedPrice = '';

    if (decimals !== '00') {
        formattedPrice =
            sign +
            (j ? i.substr(0, j) + thousandSeparator : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
            (decimalPlaces ? decimalSeparator + decimals : '') +
            (currencySymbol ? ' ' + currencySymbol : '');
    } else {
        formattedPrice =
            sign +
            (j ? i.substr(0, j) + thousandSeparator : '') +
            i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousandSeparator) +
            (currencySymbol ? ' ' + currencySymbol : '');
    }

    return formattedPrice;
};

export const formatUserPrice = (x) => {
    const { CurrencySymbol } = dataService;

    if (!x) {
        return '';
    }

    return formatPrice(x, 2, '.', ',', CurrencySymbol || '€');
};

export const formatExportStatus = (mediaStatusId) => {
    let setColor = '#03A9F4';
    let pendingColor = '#FD8C26';
    let confirmColor = '#9DC31E';
    let errorColor = '#EE5C5C';
    switch (mediaStatusId) {
        case ExportMediaStatus.ToBeExported:
            return {
                name: i18n.t('ToBeExported'),
                mediaStatusColor: setColor,
                icon: <ArrowUpCircle width="18px" color={setColor} />
            };

        case ExportMediaStatus.ExportedToMedia:
            return {
                name: i18n.t('ExportedToMedia'),
                mediaStatusColor: pendingColor,
                icon: <ArrowUpCircle width="18px" color={pendingColor} />
            };

        case ExportMediaStatus.ExportConfirmedByMedia:
            return {
                name: i18n.t('ExportConfirmedByMedia'),
                mediaStatusColor: confirmColor,
                icon: <ArrowUpCircle width="18px" color={confirmColor} />
            };

        case ExportMediaStatus.ErrorDuringExport:
            return {
                name: i18n.t('ErrorDuringExport'),
                mediaStatusColor: errorColor,
                icon: <ArrowUpCircle width="18px" color={errorColor} />
            };

        case ExportMediaStatus.ErrorDuringDelete:
            return {
                name: i18n.t('ErrorDuringDelete'),
                mediaStatusColor: errorColor,
                icon: <ArrowUpCircle width="18px" color={errorColor} />
            };

        case ExportMediaStatus.ToBeUpdated:
            return {
                name: i18n.t('ToBeUpdated'),
                mediaStatusColor: setColor,
                icon: <ArrowRepeat width="18px" color={setColor} />
            };

        case ExportMediaStatus.UpdateSentToMedia:
            return {
                name: i18n.t('UpdateSentToMedia'),
                mediaStatusColor: pendingColor,
                icon: <ArrowRepeat width="18px" color={pendingColor} />
            };

        case ExportMediaStatus.UpdateConfirmedByMedia:
            return {
                name: i18n.t('UpdateConfirmedByMedia'),
                mediaStatusColor: confirmColor,
                icon: <ArrowRepeat width="18px" color={confirmColor} />
            };

        case ExportMediaStatus.ErrorDuringUpdate:
            return {
                name: i18n.t('ErrorDuringUpdate'),
                mediaStatusColor: errorColor,
                icon: <ArrowRepeat width="18px" color={errorColor} />
            };

        case ExportMediaStatus.ToDeleteButNotExported:
            return {
                name: i18n.t('ToDeleteButNotExported'),
                mediaStatusColor: setColor,
                icon: <XCircle width="18px" color={setColor} />
            };

        case ExportMediaStatus.DeleteRequestConfirmedByMedia:
            return {
                name: i18n.t('DeleteRequestConfirmedByMedia'),
                mediaStatusColor: confirmColor
                // icon: <XCircle width="18px" color={setColor} />
            };

        case ExportMediaStatus.DeleteRequestExportedToMedia:
            return {
                name: i18n.t('DeleteRequestExportedToMedia'),
                mediaStatusColor: pendingColor,
                icon: <XCircle width="18px" color={pendingColor} />
            };

        case ExportMediaStatus.OrderPlaced:
            return {
                name: i18n.t('OrderPlaced'),
                mediaStatusColor: confirmColor,
                icon: <ArrowUpCircle width="18px" color={confirmColor} />
            };

        case ExportMediaStatus.OrderDelivered:
            return {
                name: i18n.t('OrderDelivered'),
                mediaStatusColor: confirmColor,
                icon: <ArrowUpCircle width="18px" color={confirmColor} />
            };

        case ExportMediaStatus.OrderCanceled:
            return {
                name: i18n.t('OrderCanceled'),
                mediaStatusColor: confirmColor,
                icon: <ArrowUpCircle width="18px" color={confirmColor} />
            };

        case ExportMediaStatus.Moderated:
            return {
                name: i18n.t('ExportModerated'),
                mediaStatusColor: pendingColor,
                icon: <XCircle width="18px" color={pendingColor} />
            };

        default:
            return {
                name: mediaStatusId ? mediaStatusId.toString() : '',
                mediaStatusColor: '#000000'
            };
    }
};

export const getUserInitials = (user) => {
    let initials = '';

    if (user && user.firstName) {
        initials = user.firstName[0] + user.name[0];
    } else if (user && user.name) {
        initials = user.name[0] + user.name[1];
    }

    return initials;
};

/**
 * concat only terms which are not empty / undefined
 */
const concatTerms = (terms, separator) => {
    let filledTerms = [];
    for (let term of terms) {
        if (term) {
            filledTerms.push(term);
        }
    }
    if (filledTerms.length === 0) {
        return '';
    }
    return filledTerms.join(separator);
};

/**
 * estate : { name, addressLine1, addressLine2, price, id }
 */
export const ToEstateDisplay = (estate) => {
    const terms = [estate.addressLine1, estate.addressLine2, estate.price];
    let s = concatTerms(terms, ', ');
    const extraTerms = [estate.name, estate.id];
    let extraInfo = concatTerms(extraTerms, ' - ');
    if (extraInfo) {
        s += ' (' + extraInfo + ')';
    }
    return s;
};

export const toEstateTitle = (name, subcategoryName, rooms, area, price) => {
    let title = '';

    if (name) {
        title = name;
    }

    if (subcategoryName) {
        if (title.length > 0) {
            title += ' - ';
        }
        title += subcategoryName;
    }

    if (rooms && rooms !== '-') {
        title += `, ${rooms} ${i18n.t('Rooms').toLowerCase()}`;
    }

    if (area && area !== '-') {
        title += `, ${area}`;
    }

    if (price) {
        title += ` - ${price}`;
    }

    return title;
};

export const generateGuid = (isSmall = false) => {
    var result, i, j;
    result = '';
    for (j = 0; j < 32; j++) {
        if (j === 8 || j === 12 || j === 16 || j === 20) result = result + '-';
        i = isSmall
            ? Math.floor(Math.random() * 16).toString(16)
            : Math.floor(Math.random() * 16)
                  .toString(16)
                  .toUpperCase();
        result = result + i;
    }
    return result;
};

export const PASSWORD_REGEX = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!#$%&()*+,-./:;<=>?@[\]^_{|}~]).{8,}$/;

const ToAddressLine2 = (zip, city) => {
    let address = '';

    if (zip && zip !== '') {
        address += zip;
    }

    if (city && city !== '') {
        if (address.length) {
            address += ' ';
        }

        address += city;
    }

    return address;
};

const ToAddressLine1 = (address1, nr, box, floor) => {
    let numberBeforeAddress = dataService.CountryId === Country.France;
    let address = '';

    if (numberBeforeAddress) {
        // add number
        if (nr && nr !== '') {
            address += nr;
            address += ' ';
        }
    }

    // add address
    if (address1 && address1 !== '') {
        address += address1;
    }

    if (!numberBeforeAddress) {
        // add number
        if (nr && nr !== '') {
            if (address.length) {
                address += ' ';
            }

            address += nr;
        }
    }

    // add box
    if (box && box !== '') {
        if (address.length) {
            address += ' ';
        }

        address += `${i18n.t('Box').toLowerCase()} ${box}`;
    }

    // add floor
    if (floor && floor !== '') {
        if (address.length) {
            address += ' ';
        }

        address += `${i18n.t('Floor').toLowerCase()} ${floor}`;
    }

    return address.toString();
};

const getDate = (date, convert) => {
    const { CountryId, ServerTz, LocalTz } = dataService;

    if (CountryId !== 1 && convert && ServerTz !== LocalTz) {
        return moment.tz(date, ServerTz).tz(LocalTz);
    }

    return moment(date);
};

export const getSubdetailVisitAddress = (item) => {
    let addressLine1 = '';
    // if subDetailValues is specified in the include option, and the subdetail values of the estate
    // contains the subdetail EstateSubdetail.VisitMeetingPoint, and it is filled,
    // then use this instead of the estate address
    if (item.subDetailValues?.length) {
        let subdetail = item.subDetailValues.find((x) => x.subdetailId === EstateSubdetail.VisitMeetingPoint);
        if (subdetail) {
            const subdetailValue = getMLValue(subdetail.textValueML, 'name', true);
            if (subdetailValue) {
                addressLine1 = subdetailValue;
            }
        }
    }
    return addressLine1;
};

/**
 *
 * @param {*} item
 * @param {*} options { includeId, useSubdetailAddress}
 * @returns
 */
export const ToEstateDisplayText = (item, options) => {
    const estateItem = { name: item.name, price: item.price };
    if (options?.includeId) {
        estateItem.id = item.id;
    }
    if (item.addressLine1) {
        estateItem.addressLine1 = item.addressLine1;
        estateItem.addressLine2 = item.addressLine2;
    } else {
        estateItem.addressLine1 = ToAddressLine1(item.address1, item.number, item.box, item.floor);
        estateItem.addressLine2 = ToAddressLine2(item.zip, item.city);
        estateItem.price = item.price ? formatPrice(item.price) : '';
    }
    return ToEstateDisplay(estateItem);
};

export const getNameInitials = (s) => {
    if (!s) {
        return '';
    }

    let parts = s.split(' ');
    let result = parts[0].charAt(0).toUpperCase();
    if (parts.length > 1) {
        result += parts[1].charAt(0).toUpperCase();
    }

    return result;
};

export const ToFullAddress = (zip, city, address1, nr, box, floor, region, zone, addressDetails) => {
    let address = '';
    if (region && region != null) {
        address.append(region);
    }

    let address2 = ToAddressLine2(zip, city);

    if (address2 !== '') {
        if (address.length) {
            address += ', ';
        }
        address += address2;
    }

    if (zone && zone != null) {
        if (address.length) {
            address += ', ';
        }
        address += zone;
    }

    let faddress = ToAddressLine1(address1, nr, box, floor);

    if (faddress !== '') {
        if (address.length) {
            address += ', ';
        }
        address += faddress;
    }

    if (addressDetails && addressDetails != null) {
        if (address.length) {
            address += ', ';
        }
        address += addressDetails;
    }

    return address.toString();
};

export const ToDisplayAddress = (address1, nr, zip, city) => {
    let address = '';
    let faddress = ToAddressLine1(address1, nr, '', '');
    let address2 = ToAddressLine2(zip, city);

    if (faddress !== '') {
        address += faddress;
    }

    if (address2 !== '') {
        if (address.length) {
            address += ', ';
        }
        address += address2;
    }

    return address.toString();
};

export const getTaskIcon = (task) => {
    const { CalendarActions, LanguageId } = dataService;
    let icon = <i className="flaticon flaticon-todo"></i>;
    let actionType;

    if (task.actionParameters) {
        try {
            let itemAction = JSON.parse(task.actionParameters);
            actionType = itemAction.ActionType;
        } catch (e) {}
    }

    if (task.actionId) {
        let action = Object.values(CalendarActions).find((x) => x.id === task.actionId);

        if (action) {
            let actionName = action.descriptionML
                ? action.descriptionML.find((x) => x.languageId.toLowerCase() === LanguageId)?.name
                : '';

            if (actionName) {
                icon = <span style={{ color: action.color }}>{getNameInitials(actionName).toUpperCase()}</span>;
            }
        }
    } else if (actionType) {
        icon = <i className={`flaticon ${actionType === 2 ? 'flaticon-file-text-document' : 'flaticon-email'}`}></i>;
    }

    return icon;
};

/**
 * Generate activities
 * @returns {Array}
 */

const getMedia = (mediaId) => {
    const { ServiceConsumers } = dataService;
    let mediaName = (ServiceConsumers.find((i) => i.mediaId === mediaId) || {}).name;
    return mediaName ? `(${mediaName})` : '';
};

const getContactQuestionText = (questionId, answerId) => {
    let value = '';
    const ContactQuestions = [
        { name: i18n.t('CPP_A_ReasonToBuy'), id: QuestionId.ReasonToBuy },
        { name: i18n.t('CPP_A_FinancePurchase'), id: QuestionId.FinancePurchase },
        { name: i18n.t('CPP_A_WhenToBuy'), id: QuestionId.WhenToBuy }
    ];

    const ReasonToBuyOptions = [
        { name: i18n.t('CPP_A_HomeownershipReason'), id: ReasonToBuyId.Homeownership },
        { name: i18n.t('CPP_A_RelocationReason'), id: ReasonToBuyId.Relocation },
        { name: i18n.t('CPP_A_UpgradingReason'), id: ReasonToBuyId.Upgrading },
        { name: i18n.t('CPP_A_DownsizingReason'), id: ReasonToBuyId.Downsizing },
        { name: i18n.t('CPP_A_InvestmentReason'), id: ReasonToBuyId.Investment }
    ];

    const WhenToBuyOptions = [
        { name: i18n.t('CPP_A_BuyAsap'), id: WhenToBuyId.Asap },
        { name: i18n.t('CPP_A_WithinNextYear'), id: WhenToBuyId.NextYear },
        { name: i18n.t('CPP_A_NotSureYet'), id: WhenToBuyId.NotSure }
    ];

    const FinancePurchaseOptions = [
        { name: i18n.t('CPP_A_PersonalFunds'), id: FinancePurchaseId.PersonalFunds },
        { name: i18n.t('CPP_A_MortgageLoan'), id: FinancePurchaseId.Mortgage },
        { name: i18n.t('CPP_A_CombinationFundsLoan'), id: FinancePurchaseId.Combination },
        { name: i18n.t('CPP_A_SellingProperty'), id: FinancePurchaseId.SellingProperty },
        { name: i18n.t('Other'), id: FinancePurchaseId.Other }
    ];

    let question = ContactQuestions.find((x) => x.id === questionId);
    if (question?.name) {
        value += question.name + ': ';

        let answerOptions = [];
        switch (questionId) {
            case QuestionId.FinancePurchase:
                answerOptions = FinancePurchaseOptions;
                break;
            case QuestionId.ReasonToBuy:
                answerOptions = ReasonToBuyOptions;
                break;
            case QuestionId.WhenToBuy:
                answerOptions = WhenToBuyOptions;
                break;
        }

        let answer = answerOptions.find((x) => x.id === answerId);
        if (answer?.name) {
            value += answer.name;
        }
    }

    return value;
};

const formatContactQuestions = (value) => {
    if (!value) {
        return;
    }

    value = JSON.parse(value);
    let newValue = '';

    value.forEach((x) => {
        let questionText = getContactQuestionText(parseInt(x.questionId), parseInt(x.answerId));
        if (questionText) {
            if (newValue) {
                newValue += '</br>';
            }
            newValue += questionText + '; ';
        }
    });

    return newValue;
};

const toAuditActivity = (x) => {
    const {
        Subcategories,
        ContactStatuses,
        AuditFields,
        EstateStatuses,
        PurposeStatuses,
        Countries,
        Availabilities,
        ProspectionStatuses,
        DisplayStatuses,
        Users
    } = dataService;

    const getInfo = (fieldId, oldValue = '', newValue = '', all) => {
        let info = '';
        let media = false;

        const val = (id, from, maybe = false) => {
            id = parseInt(id);

            if (!from[id]) {
                return id;
            }

            if (maybe) {
                return maybeMLValue(from[id]);
            }

            return getMLValue(from[id]);
        };
        const converters = {
            [LoggableField.EstatePrice]: [
                (x) => formatUserPrice(x.replace(',0000', '')),
                (x) => formatUserPrice(x.replace(',0000', ''))
            ],
            [LoggableField.ContactContactStatusId]: [(x) => val(x, ContactStatuses), (x) => val(x, ContactStatuses)],
            [LoggableField.ContactRepresentativeId]: [
                (x) => (Users[x] ? Users[x].fullName : x),
                (x) => (Users[x] ? Users[x].fullName : x)
            ],
            [LoggableField.EstateExportStatus]: [
                (x) => formatExportStatus(parseInt(x)).name,
                (x) => formatExportStatus(parseInt(x)).name,
                () => {
                    media = getMedia(parseInt(all.internalMessage));
                }
            ],
            [LoggableField.EstateSubCategoryId]: [(x) => val(x, Subcategories, true), (x) => val(x, Subcategories, true)],
            [LoggableField.EstateStatusId]: [(x) => val(x, EstateStatuses, true), (x) => val(x, EstateStatuses, true)],
            [LoggableField.EstateDisplayStatusId]: [(x) => val(x, DisplayStatuses, true), (x) => val(x, DisplayStatuses, true)],
            [LoggableField.EstatePurposeStatusId]: [(x) => val(x, PurposeStatuses, true), (x) => val(x, PurposeStatuses, true)],
            [LoggableField.EstateCountryId]: [(x) => val(x, Countries, true), (x) => val(x, Countries, true)],
            [LoggableField.EstateAvailabilityId]: [(x) => val(x, Availabilities, true), (x) => val(x, Availabilities, true)],
            [LoggableField.ContactProspectionStatusId]: [
                (x) => val(x, ProspectionStatuses, true),
                (x) => val(x, ProspectionStatuses, true)
            ],
            [LoggableField.ContactQuestions]: [(x) => formatContactQuestions(x), (x) => formatContactQuestions(x)]
        };

        converters[LoggableField.EstateRepresentativeId] = converters[LoggableField.ContactRepresentativeId];
        converters[LoggableField.EstateRepresentativeInId] = converters[LoggableField.ContactRepresentativeId];

        let converter = converters[fieldId];
        if (converter) {
            oldValue = converter[0] && oldValue ? converter[0](oldValue) : '';
            newValue = converter[1] && newValue ? converter[1](newValue) : '';
            converter[2] && converter[2]();
        }

        if (oldValue !== '' && newValue !== '') {
            info = `${i18n.t('From')} <strong>${oldValue}</strong> ${i18n.t('To')} <strong>${newValue}</strong>`;
        } else if (oldValue !== '') {
            info = `${i18n.t('Removed')} <strong>${oldValue}</strong>`;
        } else if (newValue !== '') {
            info = `${i18n.t('To')}  <strong>${newValue}</strong>`;
        }

        if (media) {
            info += media;
        }

        return info;
    };

    let activity = {
        category: ActivityCategory.Audit,
        id: x.id,
        color: '#3C88D4',
        icon: 'flaticon-pencilwrite',
        info: getInfo(x.fieldId, x.oldValue, x.newValue, x),
        date: getDate(x.dateTime, true)
    };

    let subject = '';
    let auditField = AuditFields[x.fieldId];

    if (auditField) {
        if (auditField.nameML) {
            subject = getMLValue(auditField.nameML);
        } else {
            if (auditField.fieldName === 'Value' || auditField.tableName.indexOf(auditField.fieldName) >= 0) {
                subject = auditField.tableName;
            } else {
                subject = auditField.tableName + ' ' + auditField.fieldName;
            }
        }
    } else {
        subject = 'edit ' + x.fieldId;
    }

    activity.subject = subject;

    let user = Users[x.userId];
    if (user != null) {
        activity.userName = user.fullName;
        activity.userInitials = getUserInitials(user);
    }

    return activity;
};

const toCalendarActivity = (x, contacts = [], estates = []) => {
    const { CalendarActions, Users } = dataService;

    let activity = {
        category: ActivityCategory.Calendar,
        id: x.id,
        date: getDate(x.startDateTime)
    };

    if (x.users && x.users.length > 0) {
        let user = Users[x.users[0].userId];
        if (user != null) {
            activity.userName = user.fullName;
            activity.userInitials = getUserInitials(user);
        }
    }

    let action = CalendarActions[x.categoryId];

    if (action) {
        if (action.descriptionML) {
            activity.actionName = getMLValue(action.descriptionML);
        }

        if (activity.actionName) {
            activity.actionInitials = getNameInitials(activity.actionName).toUpperCase();
        }

        activity.color = action.color;
    }

    activity.subject = x.subject;
    activity.info = x.description1;

    let estateNames = [];
    if (x.estates && x.estates.length > 0 && estates) {
        let estate = estates[x.estates[0].id];

        if (estate) {
            activity.estateId = estate.id;
            activity.subject += ' - ' + formatUserPrice(estate.price);
        }

        forEach(x.estates, (estateId) => {
            let estate = estates[estateId.id];
            if (estate) {
                estateNames.push(
                    ToFullAddress(
                        estate.zip,
                        estate.city,
                        estate.address1,
                        estate.number,
                        estate.box,
                        null,
                        null,
                        null,
                        estate.address2
                    )
                );
            }
        });
    }

    if (estateNames.length > 0) {
        activity.estates = estateNames.slice(0, 3).join(', ');

        if (estateNames.length > 3) {
            activity.estates += ` +${estateNames.length - 3} more`;
        }
    }

    let contactNames = [];
    if (x.contacts && x.contacts.length > 0 && contacts) {
        activity.contactId = x.contacts[0].id;

        forEach(x.contacts, (contactId) => {
            let contact = contacts[contactId.id];
            if (contact) {
                contactNames.push(`${contact.firstName || ''} ${contact.name || ''}`);
            }
        });
    }

    if (contactNames.length > 0) {
        activity.contacts = contactNames.slice(0, 3).join(', ');
        if (contactNames.length > 3) {
            activity.contacts += ` +${contactNames.length - 3} more`;
        }
    }

    return activity;
};

const toTaskActivity = (x, contacts = [], estates = []) => {
    const { Users } = dataService;
    const user = Users[x.updateUserId];

    let activity = {
        id: x.id,
        updateUser: user ? user.fullName : ''
    };

    if (x.doneDateTime) {
        activity.date = getDate(x.doneDateTime);
        activity.actionName = i18n.t('TaskDone');
        activity.color = '#008000';
    } else if (x.dueDateTime) {
        activity.date = getDate(x.dueDateTime);
        activity.actionName = i18n.t('Task');
        activity.color = '#000';
        if (x.statusId === 8) {
            activity.category = ActivityCategory.TaskDone;
            activity.actionName = i18n.t('TaskClosed');
            activity.color = '#008000';
            activity.info = i18n.t('TaskClosedNotDone');
        }
    }

    if (x.statusId === 8) {
        activity.category = ActivityCategory.TaskDone;
        activity.actionName = i18n.t('TaskClosed');
        activity.color = '#008000';
        activity.dateTime = moment(x.dueDateTime ? x.dueDateTime : x.createDateTime);
        activity.info = i18n.t('TaskClosedNotDone');
    }

    activity.actionInitials = getNameInitials(activity.actionName).toUpperCase();

    if (x.userId > 0) {
        let user = Users[x.userId];
        if (user != null) {
            activity.userName = user.fullName;
            activity.userInitials = getUserInitials(user);
        }
    }

    activity.subject = x.name;

    if (activity.info && x.description) {
        activity.info = activity.info + ' - ' + x.description;
    } else if (x.description) {
        activity.info = x.description;
    }

    let estateNames = [];
    if (x.estates && x.estates.length > 0 && estates) {
        let estate = estates[x.estates[0].value];

        if (estate) {
            activity.estateId = estate.id;
            activity.subject += ' - ' + formatUserPrice(estate.price);
        }

        forEach(x.estates, (estateId) => {
            let estate = estates[estateId.value];
            if (estate) {
                estateNames.push(
                    ToFullAddress(
                        estate.zip,
                        estate.city,
                        estate.address1,
                        estate.number,
                        estate.box,
                        null,
                        null,
                        null,
                        estate.address2
                    )
                );
            }
        });
    }

    if (estateNames.length > 0) {
        activity.estates = estateNames.slice(0, 3).join(', ');

        if (estateNames.length > 3) {
            activity.estates += ` +${estateNames.length - 3} more`;
        }
    }

    activity.estateIds = x.estates;
    activity.contactIds = x.contacts;

    let contactNames = [];
    if (x.contacts && x.contacts.length > 0 && contacts) {
        activity.contactId = x.contacts[0].value;

        forEach(x.contacts, (contactId) => {
            let contact = contacts[contactId.id];
            if (contact) {
                contactNames.push(`${contact.firstName || ''} ${contact.name || ''}`);
            }
        });
    }

    if (contactNames.length > 0) {
        activity.contacts = contactNames.slice(0, 3).join(', ');
        if (contactNames.length > 3) {
            activity.contacts += ` +${contactNames.length - 3} more`;
        }
    }

    return activity;
};

const toHistoryActivity = (x, contacts = [], estates = [], id) => {
    const { Users, HistoryCategories, ActionSelection, ClientId } = dataService;

    const user = Users[x.userIds[0]];
    let actionName = HistoryCategories[x.categoryId] && getMLValue(HistoryCategories[x.categoryId]);

    let activity = {
        actionName,
        date: getDate(x.date, x.categoryId !== LogCategory.OtherAutomaticAction),
        userName: user && user.fullName,
        userInitials: user && getUserInitials(user),
        category: ActivityCategory.History,
        subCategory: HistoryCategories[x.categoryId],
        id: x.id
    };

    activity.estateIds = x.estates;
    activity.contactIds = x.contacts;

    switch (x.categoryId) {
        case LogCategory.MailSent:
            activity.icon = 'flaticon-email';
            activity.color = '#3C88D4';
            activity.subject = x.subject;
            if (x.publicMessage) {
                activity.info = trim(x.publicMessage, '- ');
            }
            activity.actionName = i18next.t('ActivityEmailSent');
            break;

        case LogCategory.MailClicked:
            activity.icon = MailClicked;
            activity.color = '#3C88D4';
            activity.subject = x.subject;
            if (x.publicMessage) {
                activity.info = trim(x.publicMessage, '- ');
            }
            activity.actionName = i18next.t('EmailStatusEmailClicked');
            break;

        case LogCategory.AutomaticEmail:
            activity.icon = 'flaticon-email';
            activity.color = '#3AB75D';
            activity.subject = x.subject;
            activity.info = x.publicMessage;
            activity.actionName = i18next.t('ActivityEmailSent');
            break;

        case LogCategory.MailOpened:
            activity.icon = MailOpen;
            activity.color = '#3C88D4';
            activity.subject = x.subject;
            if (x.publicMessage) {
                activity.info = trim(x.publicMessage, '- ');
            }
            activity.actionName = i18next.t('EmailStatusEmailOpened');
            break;

        case LogCategory.AutomaticSMS:
            activity.icon = 'flaticon-smartphone';
            activity.color = '#FE881F';
            if (x.subject) {
                activity.subject = x.subject;
            } else {
                activity.subject = i18next.t('LoggedFromNewMobile_77');
            }
            activity.info = x.publicMessage;
            break;

        case LogCategory.AutomaticMatching:
        case LogCategory.ManualMatchingInfoSentByMail:
            activity.icon = 'flaticon-house-email';
            activity.color = '#D52728';
            activity.subject = i18n.t('AutomaticMatching');
            activity.info = x.publicMessage;
            break;

        case LogCategory.OtherAutomaticAction:
        case LogCategory.ContactCreatedByCleaningDoubles:
        case LogCategory.WH2_Automatic_Action:
        case LogCategory.CopyProject:
        case LogCategory.ContactCreatedManuall:
            activity.icon = 'flaticon-dots';
            activity.color = 'green';
            if (x.actionTypeId) {
                let action = ActionSelection[x.actionTypeId];
                if (action) {
                    if (action.descriptionML) {
                        activity.actionName = getMLValue(action.descriptionML) || i18next.t('ActivityOtherActions');
                    }
                    if (activity.actionName) {
                        activity.actionInitials = getNameInitials(activity.actionName).toUpperCase();
                    }
                    activity.color = action.color;
                }
            }
            activity.subject = x.subject;
            if (!activity.subject) {
                activity.subject = i18next.t('A_SystemAction');
            }
            activity.info = x.publicMessage;
            break;

        case LogCategory.ContactCreatedFromOwnWebsite:
        case LogCategory.ContactUpdatedManually:
            activity.icon = 'flaticon-import';
            activity.color = '#D52728';
            activity.subject = x.publicMessage;
            if (!activity.subject) {
                activity.subject = activity.actionName;
            }
            break;

        case LogCategory.ContactCreatedFromMediaWebsite:
        case LogCategory.ContactUpdatedFromOwnWebsite:
        case LogCategory.ContactUpdatedFromColleagueWebsite:
        case LogCategory.ContactUpdatedFromMediaWebsite:
        case LogCategory.ContactUpdatedFromOtherApp:
        case LogCategory.ContactCreatedFromOtherApp:
            activity.icon = 'flaticon-import';
            activity.color = '#D52728';
            activity.subject = x.subject;
            if (!activity.subject) {
                activity.subject = activity.actionName;
            }
            activity.info = x.internalMessage;
            break;

        case LogCategory.DocumentCreated:
        case LogCategory.BrochureCreated:
        case LogCategory.AfficheCreated:
        case LogCategory.LetterCreated:
        case LogCategory.FaxCreated:
            activity.icon = 'flaticon-fileadd';
            activity.color = '#D52728';
            activity.subject = x.publicMessage;
            break;

        case LogCategory.ManualMediaActivation:
            activity.icon = 'flaticon-arrow-check';
            activity.color = '#D52728';
            activity.subject = x.subject;
            break;

        case LogCategory.ManualMediaDeactivation:
            activity.icon = 'flaticon-arrow-close';
            activity.color = '#D52728';
            activity.subject = x.subject;
            break;

        case LogCategory.ContactUnsubscribed:
            activity.icon = 'flaticon-information';
            activity.color = '#D52728';
            activity.subject = i18n.t('ContactUnsubscribed');
            break;

        case LogCategory.Notification:
            activity.icon = 'flaticon-information';
            activity.color = '#D52728';
            activity.subject = x.subject;
            break;

        case LogCategory.ESignature:
            activity.icon = 'flaticon-file-text-document';
            activity.color = '#D52728';
            activity.subject = x.subject;
            activity.actionInitials = getNameInitials(i18next.t('ElectronicSignature'));
            break;

        case LogCategory.ChecklistCreated:
        case LogCategory.ChecklistDeleted:
            activity.subject = x.subject;
            actionName = i18next.t('Checklist');
            activity.actionInitials = getNameInitials(i18next.t('Checklist'));
            break;

        case LogCategory.TelephoneIngoingCall:
            activity.subject = i18next.t('TelephoneIngoingCall');
            actionName = i18next.t('Telephony');
            activity.actionInitials = getNameInitials(i18next.t('Telephony'));
            break;

        case LogCategory.TelephoneOutgoingCall:
            activity.subject = i18next.t('TelephoneOutgoingCall');
            actionName = i18next.t('Telephony');
            activity.actionInitials = getNameInitials(i18next.t('Telephony'));
            break;

        case LogCategory.OfferCreated:
        case LogCategory.OfferAccepted:
        case LogCategory.OfferRefused:
            activity.subject = x.subject;
            actionName = i18next.t('Offers');
            activity.actionInitials = getNameInitials(i18next.t('Offers'));
            break;

        default:
            activity.subject = capitalize(x.subject || actionName);
            activity.info = x.publicMessage || '';
            activity.icon = VerticalDots25;
            break;
    }

    if (x.internalMessage) {
        activity.internalInfo = x.internalMessage;
    }

    if (!activity.subject) {
        activity.subject = i18n.t('NoSubject');
    }

    let estateNames = [];
    if (x.estates && x.estates.length > 0 && estates) {
        activity.estateId = x.estates[0].value;

        forEach(x.estates, (estateId) => {
            let estate = estates[estateId.value];
            if (estate) {
                if (estate.parentId) {
                    if (estate.name) {
                        estateNames.push(estate.name);
                    } else {
                        estateNames.push(ToEstateDisplayText(estate));
                    }
                } else {
                    estateNames.push(ToEstateDisplayText(estate));
                }
            }
        });
    }

    if (estateNames.length > 0) {
        activity.estates = estateNames.slice(0, 3).join(', ');
        if (estateNames.length > 3) {
            activity.estates += `+${estateNames.length - 3} more`;
        }
    }

    let contactNames = [];

    if (x.contacts && x.contacts.length > 0 && contacts) {
        forEach(x.contacts, (contactId) => {
            if (contactId.value !== id) {
                if (!activity.contactId) {
                    activity.contactId = contactId.value;
                }
                let contact = contacts[contactId.value];
                if (contact) {
                    contactNames.push(`${contact.firstName || ''} ${contact.name || ''}`);
                }
            }
        });
    }

    if (contactNames.length > 0) {
        activity.contacts = contactNames.slice(0, 3).join(', ');
        if (contactNames.length > 3) {
            activity.contacts += ` +${contactNames.length - 3} more`;
        }
    }

    if (x.categoryId !== LogCategory.Notification || (x.categoryId === LogCategory.Notification && ClientId === 1)) {
        return activity;
    }
};

const toMediaActivity = (x) => {
    const { CalendarActions } = dataService;

    const mediaSubjects = {
        [ExportMediaStatus.UpdateConfirmedByMedia]: i18n.t('UpdateConfirmedByMedia'),
        [ExportMediaStatus.ToBeExported]: i18n.t('ToBeExported'),
        [ExportMediaStatus.ExportedToMedia]: i18n.t('ExportedToMedia'),
        [ExportMediaStatus.ExportConfirmedByMedia]: i18n.t('ExportConfirmedByMedia'),
        [ExportMediaStatus.ErrorDuringExport]: i18n.t('ErrorDuringExport'),
        [ExportMediaStatus.ToBeUpdated]: i18n.t('ToBeUpdated'),
        [ExportMediaStatus.UpdateSentToMedia]: i18n.t('UpdateSentToMedia'),
        [ExportMediaStatus.ErrorDuringUpdate]: i18n.t('ErrorDuringUpdate'),
        [ExportMediaStatus.ToDeleteButNotExported]: i18n.t('ToDeleteButNotExported'),
        [ExportMediaStatus.DeleteRequestExportedToMedia]: i18n.t('DeleteRequestExportedToMedia'),
        [ExportMediaStatus.DeleteRequestConfirmedByMedia]: i18n.t('DeleteRequestConfirmedByMedia'),
        [ExportMediaStatus.ErrorDuringDelete]: i18n.t('ErrorDuringDelete'),
        [ExportMediaStatus.OrderPlaced]: i18n.t('OrderPlaced'),
        [ExportMediaStatus.OrderDelivered]: i18n.t('OrderDelivered'),
        [ExportMediaStatus.OrderCanceled]: i18n.t('OrderCanceled')
    };

    return {
        date: getDate(x.dateTime, true),
        category: ActivityCategory.Media,
        subCategory: CalendarActions[x.categoryId],
        id: x.id,
        icon: 'flaticon-information',
        color: '#006ac9',
        subject: `${mediaSubjects[x.operationId]} ${getMedia(x.mediaId)}`
    };
};

const getFileExtension = (filePath) => {
    let basename = filePath.split(/[\\/]/).pop(); // extract file name from full path ...
    let pos = basename.lastIndexOf('.'); // get last position of `.`
    if (basename === '' || pos < 1) {
        // if file name is empty or ...
        return ''; //  `.` not found (-1) or comes first (0)
    }

    return basename.slice(pos + 1);
};

export const orderActivities = async (id, activities = {}, contacts = [], estates = []) => {
    let list = [];

    if (contacts[id]) {
        delete contacts[id];
    }

    const convert = async (key, handler) => {
        if (!activities[key]) {
            return;
        }

        let result = [];
        let items = activities[key];
        if (!items.responseData || !Array.isArray(items.responseData)) {
            return;
        }

        items = items.responseData;

        if (key === 'calendar') {
            items = items.filter((x) => x.pattern == null);
        }

        for (const x of items) {
            const res = await handler(x);
            res && result.push(res);
        }

        list = [...list, ...result];
    };

    await convert('history', (x) => toHistoryActivity(x, contacts, estates, id));
    await convert('calendar', (x) => toCalendarActivity(x, contacts, estates));
    await convert('audit', toAuditActivity);
    await convert('media', toMediaActivity);
    await convert('task', (x) => toTaskActivity(x, contacts, estates));
    return orderBy(list, (x) => x.date.toDate(), ['desc']);
};

export const getEstatePicture = (baseUrl, estateId, size, pictureUrl, statusId = EstateStatus.Active) => {
    if (pictureUrl && pictureUrl.indexOf('http') === 0) {
        return pictureUrl;
    }

    if (!baseUrl) {
        baseUrl = dataService.ImagesUrl;
    }

    if (statusId == EstateStatus.Archived) {
        if (baseUrl.indexOf('s3.') > 0 || baseUrl.indexOf('s3stg.') > 0) {
            let baseUrlParts = baseUrl.split('//');
            let urlParts = baseUrlParts[1].split('/');

            urlParts.splice(1, 0, 'Archived');
            baseUrl = baseUrlParts[0] + '//' + urlParts.join('/');

            return `${baseUrl}/${estateId}/${size}/${pictureUrl}`;
        } else if (baseUrl.indexOf('/public') > 0) {
            return `${baseUrl.replace('/public', '/public/Archived')}/${estateId}/${size}/${pictureUrl}`;
        }
    }

    if (statusId == EstateStatus.Deleted && baseUrl.indexOf('/public') > 0) {
        return `${baseUrl.replace('/public', '/public/Deleted')}/${estateId}/${size}/${pictureUrl}`;
    }

    return `${baseUrl}/${estateId}/${size}/${pictureUrl}`;
};

export const geolocate = () =>
    new Promise(async (resolve, reject) => {
        if (!navigator.geolocation) {
            return resolve({});
        }

        const client = await getClient();
        const { Geocoder } = client.maps;

        navigator.geolocation.getCurrentPosition(async (position) => {
            const location = {
                location: {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                }
            };

            const geocoder = new Geocoder();

            await geocoder.geocode(location, (data, status) => {
                if (status !== client.maps.GeocoderStatus.OK) {
                    return;
                }

                let components = {};
                let result = data[0].address_components;

                for (let i of result) {
                    for (let i2 in i.types) {
                        components[i.types[i2]] = i.short_name;
                    }
                }

                resolve({
                    location: components.route,
                    nr: components.street_number,
                    city: removeDiacritics(components.locality),
                    zip: components.postal_code
                });
            });
        });
    });

/**
 * Given a translation object, returns its ml result
 * @param obj
 * @returns {Array}
 */

export const toMlArray = (obj) => {
    let result = [];

    for (const lang in obj) {
        if (lang === '__translated__') {
            continue;
        }

        result.push({
            languageId: lang,
            name: obj[lang]
        });
    }

    return result;
};

export const format = function (...params) {
    let s = params[0],
        i = params.length - 1;

    while (i--) {
        s = s.replace(new RegExp('\\{' + i + '\\}', 'gm'), params[i + 1]);
    }

    return s;
};

export const toFullName = function (firstName, lastName) {
    return `${firstName || ''}${firstName && lastName ? ' ' : ''}${lastName || ''}`.replace(/,/g, '');
};

export const formatUnit = function (n, unit) {
    if (n) {
        return format('{0} {1}', formatUserPrice(n), unit);
    } else {
        return '';
    }
};

export const getQueryString = function (field, url) {
    var href = url ? url : window.location.href;
    var reg = new RegExp('[?&]' + field + '=([^&#]*)', 'i');
    var string = reg.exec(href);
    return string ? string[1] : null;
};

export const getFileMimeType = (fileName) => {
    let mimeType = '';

    if (!fileName || fileName.length === 0) {
        return mimeType;
    }

    let extension = getFileExtension(fileName);
    if (extension) {
        switch (extension) {
            case 'jpg':
                mimeType = 'image/jpg';
                break;
            case 'pdf':
                mimeType = 'application/pdf';
                break;
            case 'png':
                mimeType = 'image/png';
                break;
            case 'gif':
                mimeType = 'image/gif';
                break;
            default:
                mimeType = 'application/pdf';
                break;
        }
    }

    return mimeType;
};

export const serializeParams = (p) => {
    let s = '';
    if (p) {
        let params = [];
        for (let key in p) {
            params.push(key + '=' + encodeURIComponent(JSON.stringify(p[key])));
        }
        if (params.length > 0) {
            s = s + '?' + params.join('&');
        }
    }
    return s;
};

export const getPhoneTypeName = function (id) {
    let name = '';
    switch (id) {
        case PhoneNumberType.PrivateTel:
            name = 'MainTel';
            break;
        case PhoneNumberType.PrivateMobile:
            name = 'MainGSM';
            break;
        case PhoneNumberType.BusinessTel:
            name = 'WorkTel';
            break;
        case PhoneNumberType.BusinessMobile:
            name = 'WorkGSM';
            break;
        case PhoneNumberType.BusinessFax:
            name = 'WorkFax';
            break;
        case PhoneNumberType.OtherPhoneNumber:
            name = 'OtherPhoneNumber';
            break;
        default:
            break;
    }
    return capitalize(i18n.t(name));
};

export const getEmailTypeName = function (id) {
    let name = '';
    switch (id) {
        case EmailType.Private:
            name = 'MainEmail';
            break;
        case EmailType.Business:
            name = 'WorkEmail';
            break;
        case EmailType.OtherEmail:
            name = 'OtherEmail';
            break;
        default:
            break;
    }
    return capitalize(i18n.t(name));
};

export const getPhoneTypeSelection = function (contactNumberTypes, disabledContactNumberTypes, contactNumberTypeId, languageId) {
    let list = [];
    if (
        !disabledContactNumberTypes ||
        contactNumberTypeId === PhoneNumberType.PrivateTel ||
        disabledContactNumberTypes.indexOf(PhoneNumberType.PrivateTel) === -1
    ) {
        list.push({
            value: PhoneNumberType.PrivateTel,
            label: getPhoneTypeName(PhoneNumberType.PrivateTel)
        });
    }
    if (
        !disabledContactNumberTypes ||
        contactNumberTypeId === PhoneNumberType.PrivateMobile ||
        disabledContactNumberTypes.indexOf(PhoneNumberType.PrivateMobile) === -1
    ) {
        list.push({
            value: PhoneNumberType.PrivateMobile,
            label: getPhoneTypeName(PhoneNumberType.PrivateMobile)
        });
    }
    if (
        !disabledContactNumberTypes ||
        contactNumberTypeId === PhoneNumberType.BusinessTel ||
        disabledContactNumberTypes.indexOf(PhoneNumberType.BusinessTel) === -1
    ) {
        list.push({
            value: PhoneNumberType.BusinessTel,
            label: getPhoneTypeName(PhoneNumberType.BusinessTel)
        });
    }
    if (
        !disabledContactNumberTypes ||
        contactNumberTypeId === PhoneNumberType.BusinessMobile ||
        disabledContactNumberTypes.indexOf(PhoneNumberType.BusinessMobile) === -1
    ) {
        list.push({
            value: PhoneNumberType.BusinessMobile,
            label: getPhoneTypeName(PhoneNumberType.BusinessMobile)
        });
    }
    if (
        !disabledContactNumberTypes ||
        contactNumberTypeId === PhoneNumberType.BusinessFax ||
        disabledContactNumberTypes.indexOf(PhoneNumberType.BusinessFax) === -1
    ) {
        list.push({
            value: PhoneNumberType.BusinessFax,
            label: getPhoneTypeName(PhoneNumberType.BusinessFax)
        });
    }
    let contactNumberSelection = getLocalizableSelection(contactNumberTypes, languageId, true);
    for (let numberType of contactNumberSelection) {
        list.push({
            value: numberType.id,
            label: numberType.name
        });
    }

    return list;
};

export const getLocalizableSelection = function (data, languageId, orderByName) {
    languageId = languageId.toLowerCase();

    let selection = [];
    map(data, (value, key) => {
        let item = {};
        item.id = parseInt(key);
        let itemLanguage = value.find((x) => x.languageId.toLowerCase() === languageId);
        item.name = itemLanguage ? itemLanguage.name : first(value).name;
        selection.push(item);
    });
    if (orderByName) {
        selection = sortBy(selection, (x) => x.name);
    }
    return selection;
};

export const getLocalizableSelectionForPicker = function (data, languageId, orderByName) {
    let list = [];
    let selection = getLocalizableSelection(data, languageId, orderByName);
    for (let item of selection) {
        list.push({
            value: item.id,
            label: item.name
        });
    }

    return list;
};

export const getContactTitleSelection = function (data, languageId) {
    let list = [];
    let results = orderBy(data, ['id'], ['asc']);
    languageId = languageId.toLowerCase();

    for (let result of results) {
        let item = { value: result.id, label: '' };
        if (result.shortDescriptionML) {
            let itemLanguage = result.shortDescriptionML.find((x) => x.languageId.toLowerCase() === languageId);
            item.label = itemLanguage ? itemLanguage.name : first(result.shortDescriptionML).name;
        }
        list.push(item);
    }

    return list;
};

export const getLanguageSelection = function (data) {
    let list = [];
    for (let language of data) {
        list.push({ value: language.id, label: i18n.t('language_' + language.id) });
    }

    return list;
};

/**
 * create a selection array for SingleValuePicker
 * @param data : an array with the data for selection; the objects of the array must have fields: [id] and [name]
 * @param idField : field name for id; if not specified, it will be defaulted to 'id'
 * @param labelField : field name for label; if not specified, it will be defaulted to 'name'
 * */
export const getDefaultSelectionForPicker = function (data, idField, labelField) {
    let list = [];
    if (!data || data.length === 0) {
        return list;
    }
    if (!idField) {
        idField = 'id';
    }
    if (!labelField) {
        labelField = 'name';
    }
    for (let item of data) {
        list.push({ value: item[idField], label: capitalize(item[labelField]), item: item });
    }

    return list;
};

export const getEmailTypeSelection = function (disabledEmailTypes, emailTypeId) {
    let list = [];
    if (!disabledEmailTypes || emailTypeId === EmailType.Private || disabledEmailTypes.indexOf(EmailType.Private) === -1) {
        list.push({
            value: EmailType.Private,
            label: getEmailTypeName(EmailType.Private)
        });
    }
    if (!disabledEmailTypes || emailTypeId === EmailType.Business || disabledEmailTypes.indexOf(EmailType.Business) === -1) {
        list.push({
            value: EmailType.Business,
            label: getEmailTypeName(EmailType.Business)
        });
    }

    list.push({
        value: EmailType.OtherEmail,
        label: getEmailTypeName(EmailType.OtherEmail)
    });
    return list;
};

/**
 * create an array of objects { value, label } from the give array
 * @param values : an array with the data for selection
 * @param suffix : optional, a string to add at the end of the label
 * */
export const valuesToSelection = (values = [], suffix = '') => {
    return values.map((i) => ({
        value: i,
        label: `${i}${suffix}`
    }));
};

/**
 * format time in Hour:minute
 * @param {Date} date
 */
export const formatTime = (date) => {
    return moment(date).format('HH:mm');
};

/**
 * format date in Year-Month-Day
 * @param {String / Date object / moment object} date
 * @return the formmated date if date is valid; if invalid date, will return the date param
 */
export const formatISODate = (date) => {
    let dateMoment = date;
    if (typeof date === 'string' || Object.prototype.toString.call(date) === '[object Date]') {
        dateMoment = moment(date);
    }
    if (dateMoment._isAMomentObject) {
        return dateMoment.format('YYYY-MM-DD');
    } else {
        return date;
    }
};

/**
 * format date in Year-Month-Day
 * @param {String / Date object / moment object} date
 * @return the formmated date if date is valid; if invalid date, will return the date param
 */
export const formatUtcISODate = (date) => {
    let dateMoment = date;
    if (typeof date === 'string' || Object.prototype.toString.call(date) === '[object Date]') {
        dateMoment = moment.utc(date);
    }
    if (dateMoment._isAMomentObject) {
        return moment(dateMoment).format('YYYY-MM-DD');
    } else {
        return date;
    }
};

/**
 * format date in utc date format: Year-Month-Day Hour:Minute:Second
 * @param {String / Date object / moment object} date
 * @return the formmated date if date is valid; if invalid date, will return the date param
 */
export const formatUtcISODateTime = (date) => {
    let dateMoment = date;
    if (typeof date === 'string' || Object.prototype.toString.call(date) === '[object Date]') {
        dateMoment = moment.utc(date);
    }
    if (dateMoment._isAMomentObject) {
        return moment(dateMoment).format('YYYY-MM-DD[T]HH:mm:ss');
    } else {
        return date;
    }
};

/**
 * format date in utc date format: Year-Month-Day 00:00:00
 * @param {String / Date object / moment object} date
 * @return the formmated date if date is valid; if invalid date, will return the date param
 */
export const formatUtcISODateDayStart = (date) => {
    let dateMoment = date;
    if (typeof date === 'string' || Object.prototype.toString.call(date) === '[object Date]') {
        dateMoment = moment.utc(date).startOf('day');
    }
    if (dateMoment._isAMomentObject) {
        return moment(dateMoment).toISOString();
    } else {
        return date;
    }
};

/**
 * format date in ISO date time: Year-Month-Day[T]Hour:minute:second
 * @param {String / Date object / moment object} date
 * @return the formmated date if date is valid; if invalid date, will return the date param
 */
export const formatISODateTime = (date) => {
    let dateMoment = date;
    if (typeof date === 'string' || Object.prototype.toString.call(date) === '[object Date]') {
        dateMoment = moment(date);
    }
    if (dateMoment._isAMomentObject) {
        return dateMoment.format('YYYY-MM-DDTHH:mm:ss');
    } else {
        return date;
    }
};

/**
 * creates a date from a given object, being it string, date object or moment object
 * @param {String / Date object / moment object} date
 * @return a date object if date is valid; if invalid date, will return null
 */
export const getMomentFromObject = (date) => {
    let dateMoment = date;
    if (typeof date === 'string' || Object.prototype.toString.call(date) === '[object Date]') {
        dateMoment = moment(date);
    }
    if (dateMoment._isAMomentObject) {
        return dateMoment;
    } else {
        return null;
    }
};

export const cleanPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) {
        return '';
    }

    phoneNumber = phoneNumber.replace('+', '00');
    phoneNumber = phoneNumber.replace(/[^\d]*/g, '');
    phoneNumber = phoneNumber.replace(/^0032/, '0');

    return phoneNumber;
};

export const validateEmail = (email) => {
    let re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    return re.test(email);
};

/**
 * join non empty values
 * @param {string} glue
 * @param  {...array of strings} args
 */
export const joinValues = (glue, ...args) => {
    let list = [];
    for (let argument of args) {
        if (argument) {
            list.push(argument);
        }
    }
    return list.join(glue);
};

export const isRomanianUser = () => {
    return dataService.CountryId === Country.Romania;
};

export const getSelectionForPicker = (results, languageId, addDefault = false, defaultText = '', orderByIndex = false) => {
    languageId = languageId.toLowerCase();

    let selection = [];
    if (orderByIndex) {
        results = sortBy(results, (x) => x.orderIndex);
    }
    for (let result of results) {
        let item = {};
        item.value = result.id;
        item.color = result.color;
        if (result.nameML) {
            let itemLanguage = find(result.nameML, (x) => x.languageId.toLowerCase() === languageId);
            item.label = itemLanguage ? itemLanguage.name : first(result.nameML).name;
        }
        selection.push(item);
    }
    if (!orderByIndex) {
        selection = sortBy(selection, (x) => x.label);
    }
    if (addDefault) {
        selection.splice(0, 0, this.getEmptyItem(defaultText));
    }
    return selection;
};

export const getTextValueMLForEdit = (data) => {
    if (!data) {
        data = [];
    }
    let texts = [];
    for (let langId of dataService.UsedLanguages) {
        let text = data.find((x) => x.languageId === langId);
        if (text) {
            texts.push({
                id: text.languageId,
                label: text.languageId.substr(0, 2).toUpperCase(),
                text: text.name
            });
        } else {
            texts.push({
                id: langId,
                languageId: langId,
                label: langId.substr(0, 2).toUpperCase(),
                text: ''
            });
        }
    }
    return texts;
};

export const slugify = (text) => {
    return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w-]+/g, '') // Remove all non-word chars
        .replace(/--+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
};

export const arraySwap = (arr, fromIndex, toIndex) => {
    if (fromIndex < toIndex) {
        let aux = arr[fromIndex];
        for (let i = fromIndex; i < toIndex; i++) {
            arr[i] = arr[i + 1];
        }
        arr[toIndex] = aux;
    }
    if (toIndex < fromIndex) {
        let aux = arr[fromIndex];
        for (let i = fromIndex; i > toIndex; i--) {
            arr[i] = arr[i - 1];
        }
        arr[toIndex] = aux;
    }
    return arr;
};

export const getClientUrl = (baseUrl, relativePath) => {
    let url = baseUrl;
    if (url.lastIndexOf('/') !== url.length - 1) {
        url += '/';
    }
    return url + relativePath;
};

export const getCountries = () => {
    const { CountrySelection } = dataService;
    return CountrySelection ? CountrySelection : [];
};

/** This format (value,label) is used for pickers */
export const formatCountrySelection = (countrySelection) => {
    if (!countrySelection) {
        return [];
    }
    return countrySelection.map((x) => {
        return { value: x.id, label: x.name };
    });
};

/** This format (value,label) is used for pickers */
export const formatCountry = (country) => {
    if (!country) {
        return { value: '', label: ' ' };
    }
    return { value: country.id, label: country.name };
};

/** Get one country by name, or empty object */
export const getCountryByName = (countryName) => {
    const countrySelection = getCountries();
    if (!countryName || !countrySelection) {
        return { id: '', name: ' ' };
    }

    let data = countrySelection.filter((x) => x.name.toLowerCase().indexOf(countryName.toLowerCase()) >= 0);
    if (data.length > 0) {
        return data[0];
    }

    return { id: '', name: ' ' };
};

/** Get many countries, sorted by name */
export const getCountriesByName = (searchVal) => {
    const countrySelection = getCountries();
    if (!countrySelection) {
        return [];
    }
    if (!searchVal) {
        return countrySelection;
    }
    return countrySelection.filter((x) => x.name.toLowerCase().indexOf(searchVal.toLowerCase()) >= 0);
};

/** Get one country by it's id, or empty object */
export const getCountryById = (id) => {
    const countrySelection = getCountries();
    if (!id || !countrySelection) {
        return { id: '', name: ' ' };
    }

    return countrySelection.find((x) => x.id === id) || { id: '', name: ' ' };
};

/**Geolocate - start*/
const getFieldValue = (data, type, name) => {
    for (let item of data) {
        if (item.types.indexOf(type) >= 0) {
            return item[name];
        }
    }
    return '';
};

const getAddressComponents = (data, latitude, longitude) => {
    let address = {};

    const DataNameType = {
        ShortName: 'short_name',
        LongName: 'long_name'
    };

    if (data.results && data.results[0]) {
        let addressComponents = data.results[0].address_components;
        address = {
            street: getFieldValue(addressComponents, 'route', DataNameType.ShortName),
            number: getFieldValue(addressComponents, 'street_number', DataNameType.ShortName),
            city: getFieldValue(addressComponents, 'locality', DataNameType.ShortName),
            countryId: getCountryByName(getFieldValue(addressComponents, 'country', DataNameType.LongName)).id,
            zip: getFieldValue(addressComponents, 'postal_code', DataNameType.ShortName),
            formattedAddress: data.results[0].formatted_address,
            latitude,
            longitude
        };
    }
    return address;
};

export const returnGeocode = async (languageId, latitude, longitude) => {
    const GOOGLE_API_KEY = 'AIzaSyCYj2lBZq4Ekr9kKu5eXrg1HKeCq4pYse0';
    Geocode.setApiKey(GOOGLE_API_KEY);
    Geocode.setLanguage(languageId ? languageId.substring(0, 2) : 'en');
    let address = {};
    if (latitude && longitude) {
        try {
            let data = await Geocode.fromLatLng(latitude, longitude);
            address = getAddressComponents(data, latitude, longitude);
        } catch (e) {
            console.log(e);
        }
    }
    return address;
};
/**Geolocate - end*/

export const formatAPIErrorMessages = (errors) => {
    let messages = [];
    let errorMessage;
    for (let error of errors) {
        if (error.code === 'ValidMediaEstate') {
            let value = JSON.parse(error.value);
            if (value && value.mediaId) {
                // eslint-disable-next-line eqeqeq
                let media = dataService.ServiceConsumers.find((x) => x.id == value.mediaId);
                let mediaName = media && media.name ? media.name : value.mediaId;
                errorMessage = mediaName + ': ' + error.message;
            }
        } else {
            if (error.message.indexOf('V_') >= 0) {
                errorMessage = i18n.t(error.message).replace('{PropertyName}', i18n.t(error.property));
            } else {
                errorMessage = error.message;
            }
        }
        messages.push(errorMessage);
    }
    return messages;
};

/**
 * format zip search response for picker
 * @param {array} response
 */
export const getLocationListForPicker = (response, languageId, autocompleteMode) => {
    let list = [];
    if (response && response.length) {
        for (let result of response) {
            let zip = { ...result };
            if (zip.culture === 'fr-FR' || zip.culture === 'nl-NL') {
                zip.culture = '';
            }

            if (!zip.culture || zip.culture === languageId) {
                let zipObject = {
                    id: zip.id,
                    label:
                        zip.adminRegion1 !== 'NULL' && zip.adminRegion1 !== ''
                            ? format('{0} - {1} [{2}]', zip.zip, zip.city, zip.adminRegion1)
                            : format('{0} - {1}', zip.zip, zip.city),
                    value: zip.zip,
                    zip: zip.zip,
                    city:
                        zip.adminRegion1 !== 'NULL' && zip.adminRegion1 !== '' && autocompleteMode !== ZipAutocompleteMode.Zip
                            ? zip.adminRegion1
                            : zip.city
                };

                if (zip.zip !== '00') {
                    list.push(zipObject);
                }
            }
        }
    }
    return list;
};

/**
 * format radius address search response for picker
 * @param {array} response
 */
export const getRadiusListForPicker = (response) => {
    let pickerList = [];

    if (response && response.results) {
        response.results.forEach((item, i) => {
            if (item.formatted_address && item.geometry && item.geometry.location) {
                pickerList.push({
                    id: i + 1,
                    label: item.formatted_address,
                    value: item.formatted_address,
                    coordinates: item.geometry.location
                });
            }
        });
    }

    return pickerList;
};

/**
 * Function which searches for Netherlands zip codes
 *
 * @param {string} search input
 *
 * @return {array} array with searched results data
 */
export const getZipCodesNL = async (val) => {
    let response = await googleService.getPlacesAutocomplete(val || 1012, 'Netherlands'); // 1012 represents a default value for which we get results
    let predictionsResult = [];
    if (response?.predictions)
        for (let x of response.predictions) {
            let label = x.description,
                zip = x.structured_formatting?.main_text,
                city = x.secondary_text;

            if (label && zip) {
                predictionsResult.push({
                    id: label,
                    label: label,
                    value: zip,
                    zip: zip,
                    city: typeof city == 'string' ? city.split(',')[0] : null
                });
            }
        }

    return predictionsResult;
};

export const getEmailAddress = (emailAddress) => {
    return format('{0} <{1}>', emailAddress.name, emailAddress.email ? emailAddress.email : emailAddress.name);
};

export const getFileNameWithoutExtAndPath = (filePath) => {
    if (filePath) {
        let basename = filePath?.split(/[\\/]/)?.pop(); // extract file name from full path ...
        let pos = basename.lastIndexOf('.'); // get last position of `.`
        if (basename === '' || pos < 1) {
            // if file name is empty or ...
            return basename; //  `.` not found (-1) or comes first (0)
        }

        return basename.slice(0, pos);
    }
};

export const getFileNameWithoutPath = (filePath) => {
    let basename = filePath.split(/[\\/]/).pop(); // extract file name from full path ...
    return basename;
};

export const getPreferenceFilters = (filterId) => {
    if (!dataService.Preferences || !dataService.Preferences.listFilters || !dataService.Preferences.listFilters[filterId]) {
        return null;
    }
    let filters = [];
    for (let item of dataService.Preferences.listFilters[filterId]) {
        const filter = {
            id: item.id ? item.id : generateGuid(),
            key: item.key,
            name: item.name,
            searchQuery: item.searchQuery
        };
        if (filter.key) {
            filter.name = i18n.t(filter.key);
        }
        if (!filter.name) {
            filter.name = i18n.t('UnspecifiedFilterName');
        }
        try {
            filter.params = JSON.parse(item.params);
        } catch (e) {}
        filters.push(filter);
    }
    return filters;
};

export const getTel = (model) => {
    if (model.privateTel) {
        return model.privateTel;
    }
    if (model.businessTel) {
        return model.businessTel;
    }
    return '';
};

export const getMobile = (model) => {
    if (model.privateMobile) {
        return model.privateMobile;
    }
    if (model.businessMobile) {
        return model.businessMobile;
    }
    return '';
};

export const getEmail = (model) => {
    if (model.privateEmail && validateEmail(model.privateEmail)) {
        return model.privateEmail;
    }
    if (model.businessEmail && validateEmail(model.businessEmail)) {
        return model.businessEmail;
    }
    if (model.contactEmails && model.contactEmails.length) {
        for (let contactEmail of model.contactEmails) {
            if (validateEmail(contactEmail.email)) {
                return contactEmail.email;
            }
        }
    }
    return '';
};

export const getRecurrenceIntervalUnit = (recurrencePattern) => {
    let unit = '';
    switch (recurrencePattern) {
        case RecurrenceType.Daily:
            unit = i18n.t('days');
            break;
        case RecurrenceType.Weekly:
            unit = i18n.t('weeks');
            break;
        case RecurrenceType.Monthly:
            unit = i18n.t('months');
            break;
        case RecurrenceType.Yearly:
            unit = i18n.t('years');
            break;
        default:
            break;
    }
    return unit;
};

export const getBrowserInfo = () => {
    let ua = navigator.userAgent,
        tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return { name: 'IE', version: tem[1] || '' };
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR\/(\d+)/);
        if (tem != null) {
            return { name: 'Opera', version: tem[1] };
        }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
};

export const splitIgnoreQuotes = (s) => {
    return s.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/);
};

export const extractEmailAddress = (str) => {
    if (validateEmail(str)) {
        return { name: str, email: str };
    } else {
        let re = /(?:"?([^<"]+)"?\s*)?<?([^>\s,]*)/gi;
        let name = '';
        let email = '';

        let m = re.exec(str);
        if (m[1]) {
            m[1] = m[1].trim().replace(/"/g, '');
            if (validateEmail(m[1])) {
                email = m[1];
            } else {
                name = m[1];
            }
        }
        if (m[2]) {
            m[2] = m[2].trim().replace(/"/g, '');
            if (validateEmail(m[2])) {
                email = m[2];
            }
        }

        if (email) {
            if (!name) {
                name = email;
            }
            return {
                name: name.replace(/,/g, ''),
                email: email
            };
        } else {
            return null;
        }
    }
};

const unEscapeHtml = (unsafe) => {
    return unsafe
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
        .replace(/&cent;/g, '¢')
        .replace(/&pound;/g, '£')
        .replace(/&euro;/g, '€')
        .replace(/&yen;/g, '¥')
        .replace(/&deg;/g, '°')
        .replace(/&frac14;/g, '¼')
        .replace(/&OElig;/g, 'Œ')
        .replace(/&frac12;/g, '½')
        .replace(/&oelig;/g, 'œ')
        .replace(/&frac34;/g, '¾')
        .replace(/&Yuml;/g, 'Ÿ')
        .replace(/&iexcl;/g, '¡')
        .replace(/&laquo;/g, '«')
        .replace(/&raquo;/g, '»')
        .replace(/&iquest;/g, '¿')
        .replace(/&Agrave;/g, 'À')
        .replace(/&Aacute;/g, 'Á')
        .replace(/&Acirc;/g, 'Â')
        .replace(/&Atilde;/g, 'Ã')
        .replace(/&Auml;/g, 'Ä')
        .replace(/&Aring;/g, 'Å')
        .replace(/&AElig;/g, 'Æ')
        .replace(/&Ccedil;/g, 'Ç')
        .replace(/&Egrave;/g, 'È')
        .replace(/&Eacute;/g, 'É')
        .replace(/&Ecirc;/g, 'Ê')
        .replace(/&Euml;/g, 'Ë')
        .replace(/&Igrave;/g, 'Ì')
        .replace(/&Iacute;/g, 'Í')
        .replace(/&Icirc;/g, 'Î')
        .replace(/&Iuml;/g, 'Ï')
        .replace(/&ETH;/g, 'Ð')
        .replace(/&Ntilde;/g, 'Ñ')
        .replace(/&Ograve;/g, 'Ò')
        .replace(/&Oacute;/g, 'Ó')
        .replace(/&Ocirc;/g, 'Ô')
        .replace(/&Otilde;/g, 'Õ')
        .replace(/&Ouml;/g, 'Ö')
        .replace(/&Oslash;/g, 'Ø')
        .replace(/&Ugrave;/g, 'Ù')
        .replace(/&Uacute;/g, 'Ú')
        .replace(/&Ucirc;/g, 'Û')
        .replace(/&Uuml;/g, 'Ü')
        .replace(/&Yacute;/g, 'Ý')
        .replace(/&THORN;/g, 'Þ')
        .replace(/&szlig;/g, 'ß')
        .replace(/&agrave;/g, 'à')
        .replace(/&aacute;/g, 'á')
        .replace(/&acirc;/g, 'â')
        .replace(/&atilde;/g, 'ã')
        .replace(/&auml;/g, 'ä')
        .replace(/&aring;/g, 'å')
        .replace(/&aelig;/g, 'æ')
        .replace(/&ccedil;/g, 'ç')
        .replace(/&egrave;/g, 'è')
        .replace(/&eacute;/g, 'é')
        .replace(/&ecirc;/g, 'ê')
        .replace(/&euml;/g, 'ë')
        .replace(/&igrave;/g, 'ì')
        .replace(/&iacute;/g, 'í')
        .replace(/&icirc;/g, 'î')
        .replace(/&iuml;/g, 'ï')
        .replace(/&eth;/g, 'ð')
        .replace(/&ntilde;/g, 'ñ')
        .replace(/&ograve;/g, 'ò')
        .replace(/&oacute;/g, 'ó')
        .replace(/&ocirc;/g, 'ô')
        .replace(/&otilde;/g, 'õ')
        .replace(/&ouml;/g, 'ö')
        .replace(/&oslash;/g, 'ø')
        .replace(/&ugrave;/g, 'ù')
        .replace(/&uacute;/g, 'ú')
        .replace(/&ucirc;/g, 'û')
        .replace(/&uuml;/g, 'ü')
        .replace(/&yacute;/g, 'ý')
        .replace(/&thorn;/g, 'þ')
        .replace(/&quot;/g, '"')
        .replace(/'/g, "'")
        .replace(/&amp;/g, '&')
        .replace(/&nbsp;/g, ' ')
        .replace(/&iota;/g, 'ι')
        .replace(/&copy;/g, '©');
};

const decodeHtmlEntity = (str) => {
    return str.replace(/&#(\d+);/g, (match, dec) => {
        return String.fromCharCode(dec);
    });
};

export const htmlToPlainText = (source) => {
    let result = source.replace(/<!--[\s\S]*?-->/g, ''); // remove comments
    result = result.replace(/[\n\r]/g, ''); // remove carriage returns
    result = result.replace(/<(head|script|style)\b[^>]*>([\s\S]*?)<\/(head|script|style)>/gi, ''); // remove head/script/style

    result = result.replace(/<( )*td([^>])*>/gi, '{#}');
    result = result.replace(/<( )*br([ /])*>/gi, '{#}');
    result = result.replace(/<( )*li([ /])*>/gi, ' {#}');
    result = result.replace(/<( )*div([^>])*>/gi, '{#}');
    result = result.replace(/<( )*tr([^>])*>/gi, '{#}');
    result = result.replace(/<( )*p([^>])*>/gi, '{#}');

    result = result.replace(/<[^>]*>/gi, ''); // remove all remaining html tags

    result = unEscapeHtml(result);
    result = decodeHtmlEntity(result);

    result = result.replace(/(\{#\})/g, '\r');
    result = result.replace(/(\r){2,}/g, '\r');
    result = result.replace(/( ){2,}/g, ' ');

    return result.trim();
};

export const getMessageHeader = (message, lineSeparator) => {
    return format(
        'From: {1}{0}Sent: {2}{0}To: {3}{0}{4}Subject: {5}{0}',
        lineSeparator,
        message.from,
        moment(message.dateReceived).format('MMM DD YYYY, HH:mm'),
        message.to,
        message.cC ? format('CC: {0}{1}', message.cc, lineSeparator) : '',
        message.subject
    );
};

export const getAreaMeasurementUnit = () => {
    try {
        return dataService.Preferences.measurementUnits[2] + dataService.Preferences.measurementUnits[3];
    } catch {}
    return 'm²';
};

export const getLengthMeasurementUnit = () => {
    try {
        return dataService.Preferences.measurementUnits[2];
    } catch {}
    return 'm';
};

export const formatArea = function (n) {
    return formatUnit(n, getAreaMeasurementUnit());
};

export const isRomanian = () => {
    let { Offices, OfficeId } = dataService;
    let office = Offices[OfficeId];
    if (office && office.countryId === Country.Romania) {
        return true;
    }
    return false;
};

export const getFileStatus = (estateStatusId) => {
    let fileStatus = null;

    switch (estateStatusId) {
        case EstateStatus.Active:
            fileStatus = FileStatus.Normal;
            break;

        case EstateStatus.Archived:
            fileStatus = FileStatus.Archived;
            break;

        case EstateStatus.Deleted:
            fileStatus = FileStatus.Deleted;
            break;

        default:
            break;
    }
    return fileStatus;
};

export const getFileStatusContacts = (contactStatusId) => {
    let fileStatus = null;

    switch (contactStatusId) {
        case ContactStatus.Active:
            fileStatus = FileStatus.Normal;
            break;

        case ContactStatus.Archived:
            fileStatus = FileStatus.Archived;
            break;

        case ContactStatus.Deleted:
            fileStatus = FileStatus.Deleted;
            break;

        default:
            break;
    }
    return fileStatus;
};

export function componentLoader(lazyComponent, attemptsLeft) {
    return new Promise((resolve, reject) => {
        lazyComponent()
            .then(resolve)
            .catch((error) => {
                // retry after 1000 ms
                setTimeout(() => {
                    if (attemptsLeft === 1) {
                        reject(error);
                        return;
                    }
                    componentLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
                }, 1000);
            });
    });
}

export const getFunnelStatusSelection = () => {
    return [
        {
            value: FunnelStatus.Cold,
            label: i18n.t('FunnelStatusCold')
        },
        {
            value: FunnelStatus.Engaged,
            label: i18n.t('FunnelStatusEngaged')
        },
        {
            value: FunnelStatus.Warm,
            label: i18n.t('FunnelStatusWarm')
        },
        {
            value: FunnelStatus.Hot,
            label: i18n.t('FunnelStatusHot')
        },
        {
            value: FunnelStatus.Signed,
            label: i18n.t('FunnelStatusSigned')
        },
        {
            value: FunnelStatus.Dropped,
            label: i18n.t('FunnelStatusDropped')
        }
    ];
};

/**
 * get the translted text for the product name
 *
 * @param {object} data
 * @returns translated text for product name
 */
export const getProductName = (nameML) => {
    if (!nameML?.length) {
        return '';
    }
    return (
        nameML.find((x) => x.languageId === dataService?.LanguageId)?.label ||
        nameML.find((x) => x.languageId === 'en-GB')?.label ||
        ''
    );
};

export const getContactRelationTypeOptions = () => {
    return [
        {
            id: ContactRelationType.Mother,
            name: i18n.t('CRT_Mother')
        },
        {
            id: ContactRelationType.MotherInLaw,
            name: i18n.t('CRT_MotherInLaw')
        },
        {
            id: ContactRelationType.Father,
            name: i18n.t('CRT_Father')
        },
        {
            id: ContactRelationType.FatherInLaw,
            name: i18n.t('CRT_FatherInLaw')
        },
        {
            id: ContactRelationType.Parent,
            name: i18n.t('CRT_Parent')
        },
        {
            id: ContactRelationType.Brother,
            name: i18n.t('CRT_Brother')
        },
        {
            id: ContactRelationType.Sister,
            name: i18n.t('CRT_Sister')
        },
        {
            id: ContactRelationType.Son,
            name: i18n.t('CRT_Son')
        },
        {
            id: ContactRelationType.Daugther,
            name: i18n.t('CRT_Daugther')
        },
        {
            id: ContactRelationType.Child,
            name: i18n.t('CRT_Child')
        },
        {
            id: ContactRelationType.Wife,
            name: i18n.t('CRT_Wife')
        },
        {
            id: ContactRelationType.Husband,
            name: i18n.t('CRT_Husband')
        },
        {
            id: ContactRelationType.Partner,
            name: i18n.t('CRT_Partner')
        },
        {
            id: ContactRelationType.Friend,
            name: i18n.t('CRT_Friend')
        },
        {
            id: ContactRelationType.Acquaintance,
            name: i18n.t('CRT_Acquaintance')
        },
        {
            id: ContactRelationType.Other,
            name: i18n.t('CRT_Other')
        },
        {
            id: ContactRelationType.ChildInLaw,
            name: i18n.t('CRT_ChildInLaw')
        },
        {
            id: ContactRelationType.ParentInLaw,
            name: i18n.t('CRT_ParentInLaw')
        },
        {
            id: ContactRelationType.Spouse,
            name: i18n.t('CRT_Spouse')
        },
        {
            id: ContactRelationType.Sibling,
            name: i18n.t('CRT_Sibling')
        },
        {
            id: ContactRelationType.SonInLaw,
            name: i18n.t('CRT_SonInLaw')
        },
        {
            id: ContactRelationType.DaughterInLaw,
            name: i18n.t('CRT_DaughterInLaw')
        },
        {
            id: ContactRelationType.Uncle,
            name: i18n.t('CRT_Uncle')
        },
        {
            id: ContactRelationType.Aunt,
            name: i18n.t('CRT_Aunt')
        },
        {
            id: ContactRelationType.Niece,
            name: i18n.t('CRT_Niece')
        },
        {
            id: ContactRelationType.Nephew,
            name: i18n.t('CRT_Nephew')
        },
        {
            id: ContactRelationType.MotherFatherInLaw,
            name: i18n.t('CRT_MotherFatherInLaw')
        },
        {
            id: ContactRelationType.NieceNephew,
            name: i18n.t('CRT_NieceNephew')
        },
        {
            id: ContactRelationType.UncleAunt,
            name: i18n.t('CRT_UncleAunt')
        },
        {
            id: ContactRelationType.Guarantor,
            name: i18n.t('CRT_Guarantor')
        },
        {
            id: ContactRelationType.Guarantee,
            name: i18n.t('CRT_Guarantee')
        },
        {
            id: ContactRelationType.CoTenant,
            name: i18n.t('CRT_CoTenant')
        }
    ];
};

/**
 * A method used to open an external window
 *
 * @date 16/12/2022
 * @param {string} url window url
 * @param {number} w window width
 * @param {number} h window height
 * @return {*}
 */
export const externalOpenWindow = (url, w, h) => {
    let left = window.screen.width / 2 - (w / 2 + 10);
    let top = window.screen.height / 2 - (h / 2 + 50);
    let newWindow = window.open(url, '_blank', 'width=' + w + ',height=' + h + ',top=' + top + ',left=' + left);
    if (window.focus) {
        newWindow.focus();
    }
    return newWindow;
};

/**
 * add a com channel for Outlook Email, for the specified data
 *
 * @param {object} data { }
 * @param {string} userName
 */
export const addComChannelOutlookEmail = (data) => {
    let comChannels = dataService.ComChannels;
    // if no com channel exist
    if (!comChannels || comChannels.constructor !== Array || comChannels.length === 0) {
        comChannels = [data];
    } else {
        // filter out outlook channels
        let channelsWithoutOutlook = dataService.ComChannels.filter((x) => x && x.comChannelId !== ComChannel.OutlookEmail);
        // combine channelsWithoutOutlook with data
        comChannels = [...channelsWithoutOutlook, ...data];
    }
    dataService.ComChannels = comChannels;
};

/**
 * remove a com channel from Outlook Email channels, for the specified user name
 *
 * @param {string} userName the outlook account user name
 */
export const removeComChannelOutlookEmail = (userName) => {
    let comChannels = dataService.ComChannels;
    if (comChannels) {
        let outlookChannel = comChannels.find((x) => x.comChannelId === ComChannel.OutlookEmail && x.username === userName);
        if (outlookChannel) {
            let idx = comChannels.indexOf(outlookChannel);
            comChannels.splice(idx, 1);
        }
        dataService.ComChannels = comChannels;
    }
};

export const formatNumberOutput = (number) => {
    if (!number) {
        return 0;
    }
    let formattedNumber = parseFloat(number).toLocaleString('de-DE');
    let indexOfComma = formattedNumber.indexOf(',');
    if (indexOfComma >= 0 && formattedNumber.length - 2 > indexOfComma) {
        formattedNumber = formattedNumber.toString().substring(0, indexOfComma + 3);
    }
    return formattedNumber;
};

export const formatNumberInput = (number) => {
    if (!number) {
        return null;
    }
    let stringNum = number.toString();
    let index = stringNum.indexOf('.');
    if (index >= 0 && stringNum.slice(index + 1).length > 2) {
        return parseFloat(stringNum.slice(0, index + 3));
    }
    return number;
};

export const getVatValue = (countryId) => {
    switch (countryId) {
        case Country.Belgium:
            return 21;
        case Country.France:
            return 20;
        case Country.Guadeloupe:
        case Country.Martinique:
        case Country.Reunion:
            return 8.5;
        default:
            return 21;
    }
};

export const IsProduction = window.env.REACT_APP_ENV?.indexOf('prd') !== -1;

export const isBuyerFlowActive = () => dataService.Features.BuyerFlow;
