import { useState } from 'react';

export const useLocalStorage = (key, initialValue) => {
    // State to store our value
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? item : initialValue;
        } catch (error) {
            return initialValue;
        }
    });

    const setValue = (value) => {
        try {
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, valueToStore);
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
};

export const getLocalStorage = (key, defaultValue) => {
    try {
        const item = window.localStorage.getItem(key);
        return item ? item : defaultValue;
    } catch (error) {
        console.log(error);
        return defaultValue;
    }
};

export const setLocalStorage = (key, value) => {
    try {
        //const valueToStore = value instanceof Function ? value() : value;
        // Save to local storage
        window.localStorage.setItem(key, value);
    } catch (error) {
        console.log(error);
    }
};

export const removeLocalStorage = (key) => {
    try {
        // remove from local storage
        window.localStorage.removeItem(key);
    } catch (error) {
        console.log(error);
    }
};
