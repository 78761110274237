/* eslint-disable eqeqeq */
import Service from './_base';
import merge from 'deepmerge';
import { set } from '../lib/lodash';
import { serializeParams } from '../lib/utils';
import { ContactStatus } from 'commons';
import { trackPromise } from 'react-promise-tracker';
import { FileStatus } from 'commons';
import { getToken } from '../lib/auth';

class ContactService extends Service {
    async uploadDocuments(contactId, documentsModels, documentStreams, uploadParams) {
        let p = {
            holderId: contactId,
            holderTypeID: 4
        };

        let requestUrl = 'documents/create' + serializeParams(p);

        let fd = new FormData();
        fd.append('documents', JSON.stringify(documentsModels));

        let i = 0;
        for (let documentStream of documentStreams) {
            fd.append('doc' + i.toString(), documentStream);
            i++;
        }

        let response = await this.client.post(requestUrl, fd);
        return response && response.data ? response.data : null;
    }

    async getStreamAsBlob(path, fileStatus) {
        return trackPromise(
            new Promise((resolve, reject) => {
                let requestUrl = window.env?.REACT_APP_API_URL + '/streams/download?filePath=' + encodeURIComponent(path);
                requestUrl += '&fileStatus=' + (fileStatus || FileStatus.Normal);
                const accessToken = getToken();

                let xhr = new XMLHttpRequest();
                xhr.open('GET', requestUrl);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                xhr.onload = function () {
                    if (this.status === 200) {
                        let byteArray = new Uint8Array(xhr.response);
                        let blob = new Blob([byteArray], {
                            type: 'application/octet-stream'
                        });
                        resolve(blob);
                    } else {
                        resolve(null);
                    }
                };
                // tslint:disable-next-line:only-arrow-functions
                xhr.onerror = function () {
                    reject({ status: 500 });
                };
                xhr.send();
            })
        );
    }

    async updateDocuments(contactId, documents) {
        let p = {
            holderId: contactId,
            holderTypeID: 4
        };

        let requestUrl = 'documents/update' + serializeParams(p);

        let response = await this.client.put(requestUrl, documents);
        return response && response.data ? response.data : null;
    }

    async deleteDocuments(contactId, documents) {
        let p = {
            holderId: contactId,
            holderTypeID: 4
        };

        let requestUrl = 'documents/delete' + serializeParams(p);

        let response = await this.client.delete(requestUrl, { data: documents });
        return response && response.data ? response.data : null;
    }

    /**
     * Given an id, returns contacts activities list
     * @param id
     * @returns {Promise<void>}
     */

    async getActivities(id = '', options) {
        const historyData =
            options && options.history
                ? options.history
                : {
                      sort: {
                          field: 'date',
                          ascending: false
                      }
                  };
        const pageData = options && options.page ? options.page : { index: 0, size: 20 };
        const includeGroupEstates = options && options.includeGroupEstates ? options.includeGroupEstates : true;
        const data = {
            audit: {},
            calendar: {},
            task: {},
            contactIds: [id],
            history: historyData,
            includeGroupEstates,
            IncludeEstatePictureUrl: true,
            page: pageData
        };
        if (options && options.datetime) {
            data.datetime = options.datetime;
        }

        return await trackPromise(this.client.post('search/activities', data));
    }

    /**
     * Given a search string, returns a list of contacts
     * @param search
     * @returns {Promise<*>}
     */

    async searchContacts(search = '') {
        return await trackPromise(
            this.client.post('search/contacts', {
                aggregate: null,
                field: {
                    included: [
                        'id',
                        'name',
                        'firstName',
                        'privateEmail',
                        'businessEmail',
                        'contactEmails',
                        'privateTel',
                        'businessTel',
                        'privateMobile',
                        'businessMobile',
                        'agreementEmail'
                    ]
                },
                page: {
                    size: 20
                },
                postFilter: null,
                query: {
                    contactStatusIds: [1, 2],
                    includeExpiredContacts: true,
                    customSearch: search
                },
                sort: {
                    field: 'updateDateTime',
                    ascending: false
                }
            })
        );
    }

    /**
     * Given a list of filters, returns a list of contacts
     * @param filters
     * @returns {Promise<void>}
     */

    async getContacts(filters = {}) {
        if (filters.hasOwnProperty('page')) {
            let page = filters.page || 0;
            delete filters.page;
            set(filters, 'page.index', page);
        }

        if (filters.hasOwnProperty('search')) {
            set(filters, 'query.customSearch', filters.search);
            delete filters.search;
        }

        const payload = merge(
            {
                aggregate: {
                    terms: [
                        {
                            fieldName: 'baseContactTypeIds.value'
                        },
                        {
                            fieldName: 'contactTypeIds.value'
                        },
                        {
                            fieldName: 'officeIds.value'
                        },
                        {
                            fieldName: 'contactStatusId'
                        },
                        {
                            fieldName: 'languageId'
                        },
                        {
                            fieldName: 'prospectionStatusId'
                        }
                    ]
                },
                field: {},
                page: {
                    size: 20
                },
                postFilter: null,
                query: {
                    includeExpiredContacts: true,
                    includeLastActivityInfo: true,
                    onSearchCriteria: true,
                    contactEstateSearch: {}
                },
                sort: {
                    field: 'id',
                    ascending: false
                }
            },
            filters
        );

        return await trackPromise(this.client.post('search/contacts', payload));
    }

    /**
     * Given an id, deletes a contact
     * @param id
     * @returns {Promise<void>}
     */

    async deleteContact(id) {
        return await trackPromise(
            this.client.post('partialupdate/batch', [
                {
                    entityName: 'Contact',
                    primaryKey: {
                        contactId: id
                    },
                    values: {
                        contactStatusId: 3
                    }
                }
            ])
        );
    }

    /**
     * Given an ids array and actionType, performs the action on those ids
     * @param ids - array of ContactIds
     * @param actionType (0-archive; 1-active; 3- deleted;)
     * @returns {Promise<void>}
     */
    async performActionsForContact(ids, actionType) {
        let arrayToBeUpdated = [];
        ids.forEach((id) => {
            arrayToBeUpdated.push({
                entityName: 'Contact',
                primaryKey: {
                    contactId: id
                },
                values: {
                    contactStatusId: actionType
                }
            });
        });

        return await trackPromise(this.client.post('partialupdate/batch', arrayToBeUpdated));
    }

    /**
     * Given data, updates a contact
     * @param data
     * @returns {Promise<void>}
     */

    async updateContact(data = {}, correlationId = null) {
        return await trackPromise(
            this.client.put('contacts/savedata', data, {
                params: {
                    validationWarningBehaviour: 1,
                    correlationId: correlationId
                }
            })
        );
    }

    /**
     * Given an id, returns a contact
     * @param id
     * @returns {Promise<void>}
     */

    async getContact(id) {
        return await trackPromise(
            this.client.get('contacts', {
                params: {
                    searchParameters: JSON.stringify({
                        parameters: {
                            contactIdList: [id],
                            isForEdit: true,
                            pageIndex: 0,
                            pageSize: 1
                        }
                    }),
                    includes: [
                        'contactnumber',
                        'contactemail',
                        'searchcriteria',
                        'company',
                        'contacttype',
                        'estateaccount',
                        'lastcalendarinfo',
                        'lasthistoryinfo',
                        'lasttaskinfo',
                        'document',
                        'contactrelation'
                    ].join(',')
                }
            })
        );
    }

    /**
     * Given a list of ids, returns a list of contacts
     * @param ids
     * @returns {Promise<void>}
     */

    async getContactsById(ids) {
        return await trackPromise(
            this.client.get('contacts', {
                params: {
                    searchParameters: JSON.stringify({
                        parameters: {
                            contactIdList: ids,
                            isForEdit: true,
                            pageIndex: 0,
                            pageSize: 1
                        }
                    }),
                    includes: [
                        'contactnumber',
                        'contactemail',
                        'searchcriteria',
                        'company',
                        'contacttype',
                        'estateaccount',
                        'lastcalendarinfo',
                        'lasthistoryinfo',
                        'lasttaskinfo',
                        'document'
                    ].join(',')
                }
            })
        );
    }

    async getContactsOffline(_id) {
        return await trackPromise(
            this.client.get('contacts', {
                params: {
                    searchParameters: JSON.stringify({
                        parameters: {
                            contactIdList: _id,
                            isForEdit: true,
                            pageIndex: 0,
                            pageSize: 1
                        }
                    }),
                    includes: [
                        'contactnumber',
                        'contactemail',
                        'searchcriteria',
                        'company',
                        'contacttype',
                        'estateaccount',
                        'lastcalendarinfo',
                        'lasthistoryinfo',
                        'lasttaskinfo',
                        'document'
                    ].join(',')
                }
            })
        );
    }

    /**
     * Given a query, returns a list of zip codes from the API
     * @param query
     * @param country
     * @returns {Promise<void>}
     */

    async searchZipCodes(query = '', country) {
        return trackPromise(
            this.client.get('clients/zipcity', {
                params: {
                    searchParameters: {
                        maxResults: 10,
                        countryId: country,
                        city: `%${query.replace(/%/gi, '')}%`
                    }
                }
            })
        );
    }

    /**
     * Given data, creates a contact
     * @param data
     * @returns {Promise<void>}
     */

    async createContact(data = {}, correlationId = null) {
        data.rowStatus = 1;
        return trackPromise(
            this.client.put(
                'contacts/savedata',
                {
                    object: data
                },
                {
                    params: {
                        correlationId: correlationId
                    }
                }
            )
        );
    }

    /**
     * Given a query, returns a list of contacts from the API
     * @param term
     * @param options
     * @returns {Promise<void>}
     */

    async suggestContact(term = '', options) {
        let param = {
            contactStatusIds: [ContactStatus.Normal, ContactStatus.Pending],
            includeExpiredContacts: true
        };
        if (options) {
            if (options.companyName) {
                param.companyName = options.companyName;
            }
            if (options.isAgreementEmail) {
                param.isAgreementEmail = true;
            }
        }
        let filterValue = term;
        if (filterValue[0] == '+' || filterValue[0] == '(') {
            filterValue = filterValue.substring(1);
        }
        if (filterValue.indexOf(' ') >= 0 && isNaN(parseInt(filterValue[0]))) {
            param.name = filterValue;
        } else if (filterValue.indexOf('@') >= 0) {
            param.email = filterValue;
        } else {
            param.customSearch = filterValue;
        }

        let response = await trackPromise(
            this.client.post('search/contacts', {
                query: param,
                postFilter: null,
                page: { size: 20 },
                field: {
                    included: [
                        'id',
                        'name',
                        'firstName',
                        'privateEmail',
                        'businessEmail',
                        'contactEmails',
                        'privateTel',
                        'businessTel',
                        'privateMobile',
                        'businessMobile',
                        'agreementEmail',
                        'languageId'
                    ]
                },
                aggregate: null,
                sort: { field: 'updateDateTime', ascending: false }
            })
        );

        return response.data;
    }
    /**
     * Fully customizable contact search
     *
     * @param {ContactSearchOptions} query
     * @param {ContactSearchOptions} postFilter
     * @param {PageOptions} page
     * @param {FieldOptions} field
     * @param {AggregateOptions} aggregate
     * @param {SortOptions} sort
     * @return {*}  {Promise<SearchResponseList<ContactModel>>}
     * @memberof ContactService
     */
    async contactSearch(query, postFilter, page, field, aggregate, sort) {
        const response = await trackPromise(
            this.client.post('search/contacts', {
                query,
                postFilter,
                page,
                field,
                aggregate,
                sort
            })
        );
        return response.data;
    }

    /**
     * Given searchCriteria, returns the number of matchings
     * @param searchCriteria
     * @returns {Promise<number>}
     */
    async matchCountContact(query) {
        let response = await trackPromise(this.client.post('contacts/matchcount', query));
        return response.data;
    }

    /**
     * Given searchCriteria, returns the matched properties
     * @param searchCriteria
     * @returns {Promise<Object[]>}
     */
    async matchContact(query = {}, correlationId = null) {
        let response = await trackPromise(
            this.client.post('contacts/match', query, {
                params: {
                    correlationId: correlationId
                }
            })
        );
        return response.data;
    }

    /**
     * Given searchParameters, returns the duplicate contacts
     * @param searchParameters
     * @returns {Promise<Object[]>}
     */
    async getContactDuplicates(searchParameters) {
        let p = {
            params: {
                searchParameters: JSON.stringify(searchParameters)
            }
        };

        let response = await trackPromise(this.client.get('contacts/duplicates', p));
        return response.data;
    }

    /**
     *  Set a base contact type
     *
     * @param {BaseContactType} baseContactTypeId
     * @memberof ContactService
     */
    async setContactType(baseContactTypeId) {
        let response = await trackPromise(this.client.post(`admin/setcontacttype?baseTypeId=${baseContactTypeId}`, null));
        return response.data;
    }

    async getContactType() {
        let response = await trackPromise(this.client.get('admin/getcontacttype'));
        return response.data;
    }
}

export default new ContactService();
