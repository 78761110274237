import Service from './_base';
import { serializeParams } from '../lib/utils';
import { trackPromise } from 'react-promise-tracker';

class ClientService extends Service {
    // constructor() {
    //     this.client = http;
    // }

    /**
     * get a list of preferences for given parameters
     * @param param : PreferenceListParameters { preferenceIdList, preferenceLevelId, targetId, includeDefaultPreferences }
     * @returns {Promise<*>}
     */
    async getPreferenceValues(param) {
        let p = {
            searchParameters: param
        };
        let url = 'clients/preferencevalues' + serializeParams(p);
        let response = await trackPromise(this.client.get(url));
        return response.data;
    }

    /**
     * get a list of zip and city for given parameters
     * @param param : ZipCitySearchParameters { zip, city, freeField, adminRegion1, adminRegion2, culture, countryId, maxResults, hasRegions }
     * @returns a list of ZipCityModel { countryCode, zip, city, adminRegion1, culture}
     */
    async getZipCityAutocomplete(param) {
        let p = {
            searchParameters: param
        };
        let url = 'clients/zipcity' + serializeParams(p);
        let response = await trackPromise(this.client.get(url));
        return response.data;
    }

    async savePreferences(payload = {}) {
        return await trackPromise(this.client.put('clients/preferencevalues/save', [payload]));
    }

    async savePreferencesData(preferences) {
        return await trackPromise(this.client.put('clients/preferencevalues/savedata', preferences));
    }

    async getCredentials(userId) {
        let requestUrl = 'clients/credentials?userId=' + userId;
        let response = await trackPromise(this.client.get(requestUrl));
        if (!response) {
            return null;
        }
        return response.data;
    }

    async checkTrigger(trigger, count) {
        let requestUrl = 'clients/account/checktrigger';
        requestUrl += serializeParams({ trigger, count });
        let response = await trackPromise(this.client.get(requestUrl));
        return response.data;
    }
}

export default new ClientService();
