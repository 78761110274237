export { FilePicture } from 'styled-icons/icomoon/FilePicture';
export { Notifications } from 'styled-icons/material/Notifications';
export { HelpOutline } from 'styled-icons/material/HelpOutline';
export { ArrowDropDown } from 'styled-icons/material/ArrowDropDown';
export { Info } from 'styled-icons/material/Info';
export { InfoCircle } from 'styled-icons/boxicons-regular/InfoCircle';
export { KeyboardArrowRight } from 'styled-icons/material/KeyboardArrowRight';
export { KeyboardArrowLeft } from 'styled-icons/material/KeyboardArrowLeft';
export { AngleLeft } from 'styled-icons/fa-solid/AngleLeft';
export { AngleUp } from 'styled-icons/fa-solid/AngleUp';
export { AngleDown } from 'styled-icons/fa-solid/AngleDown';
export { Link } from 'styled-icons/material/Link';
export { DragHandle } from 'styled-icons/material/DragHandle';
export { AllInclusive } from 'styled-icons/material/AllInclusive';
export { Close } from 'styled-icons/material/Close';
export { KeyboardArrowDown } from 'styled-icons/material/KeyboardArrowDown';
export { ArrowForward } from 'styled-icons/material/ArrowForward';
export { ArrowBack } from 'styled-icons/material/ArrowBack';
export { ArrowBackIos } from 'styled-icons/material/ArrowBackIos';
export { CheckBoxOutlineBlank } from 'styled-icons/material/CheckBoxOutlineBlank';
export { AccessTime } from 'styled-icons/material/AccessTime';
export { MailOutline } from 'styled-icons/material/MailOutline';
export { Check } from 'styled-icons/material/Check';
export { Warning } from 'styled-icons/material/Warning';
export { Autorenew } from 'styled-icons/material/Autorenew';
export { Print } from 'styled-icons/material/Print';
export { PlayCircleOutline } from 'styled-icons/material/PlayCircleOutline';
export { Search } from 'styled-icons/material/Search';
export { CheckCircle } from 'styled-icons/material/CheckCircle';
export { Check as CheckCircleThin } from 'styled-icons/evil/Check';
export { CloudUpload } from 'styled-icons/material/CloudUpload';
export { Email } from 'styled-icons/material/Email';
export { Phone } from 'styled-icons/material/Phone';
export { Message } from 'styled-icons/material/Message';
export { InsertDriveFile } from 'styled-icons/material/InsertDriveFile';
export { History } from 'styled-icons/material/History';
export { Add } from 'styled-icons/material/Add';
export { Edit } from 'styled-icons/material/Edit';
export { EditAlt } from 'styled-icons/boxicons-regular/EditAlt';
export { Delete } from 'styled-icons/material/Delete';
export { GpsFixed } from 'styled-icons/material/GpsFixed';
export { Home } from 'styled-icons/material/Home';
export { Today } from 'styled-icons/material/Today';
export { User } from 'styled-icons/feather/User';
export { UserX } from 'styled-icons/feather/UserX';
export { UserPlus } from 'styled-icons/feather/UserPlus';
export { UserCheck } from 'styled-icons/feather/UserCheck';
export { Clear } from 'styled-icons/material/Clear';
export { Refresh } from 'styled-icons/material/Refresh';
export { PlaylistAddCheck } from 'styled-icons/material/PlaylistAddCheck';
export { Cancel } from 'styled-icons/material/Cancel';
export { Mail } from 'styled-icons/material/Mail';
export { VpnKey } from 'styled-icons/material/VpnKey';
export { People } from 'styled-icons/material/People';
export { PowerSettingsNew } from 'styled-icons/material/PowerSettingsNew';
export { SupervisorAccount } from 'styled-icons/material/SupervisorAccount';
export { MoreVert } from 'styled-icons/material/MoreVert';
export { Settings } from 'styled-icons/octicons/Settings';
export { TargetLock } from 'styled-icons/boxicons-regular/TargetLock';
export { Minus } from 'styled-icons/boxicons-regular/Minus';
export { Plus } from 'styled-icons/boxicons-regular/Plus';
export { PlusCircle } from 'styled-icons/boxicons-solid/PlusCircle';
export { Download } from 'styled-icons/boxicons-regular/Download';
export { MoreHoriz } from 'styled-icons/material/MoreHoriz';
export { NetworkWifi } from 'styled-icons/material/NetworkWifi';
export { WifiOff } from 'styled-icons/material/WifiOff';
export { BuildingHouse } from 'styled-icons/boxicons-regular/BuildingHouse';
export { ShoppingCart } from 'styled-icons/typicons/ShoppingCart';
export { CloseCircle } from 'styled-icons/remix-fill/CloseCircle';
export { MyLocation } from 'styled-icons/material/MyLocation';
export { ShareAlt } from 'styled-icons/boxicons-regular/ShareAlt';
export { LoaderAlt } from 'styled-icons/boxicons-regular/LoaderAlt';
export { Lock } from 'styled-icons/boxicons-solid/Lock';
export { Save } from 'styled-icons/boxicons-solid/Save';
export { Visibility } from 'styled-icons/material/Visibility';
export { VisibilityOff } from 'styled-icons/material/VisibilityOff';

export { ArrowUpCircle } from '@styled-icons/bootstrap/ArrowUpCircle';
export { XCircle } from '@styled-icons/bootstrap/XCircle';
export { ArrowRepeat } from '@styled-icons/bootstrap/ArrowRepeat';
