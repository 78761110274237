/* eslint-disable eqeqeq */
import Service from './_base';
import merge from 'deepmerge';
import { set } from '../lib/lodash';
import { trackPromise } from 'react-promise-tracker';


class TaskService extends Service {
    async getTasks(filters = {}) {
        if (filters.hasOwnProperty('page')) {
            let page = filters.page || 0;
            delete filters.page;
            set(filters, 'page.index', page);
        }

        if (filters.hasOwnProperty('search')) {
            set(filters, 'query.customSearch', filters.search);
            delete filters.search;
        }

        const payload = merge(
            {
                aggregate: null,
                field: {},
                page: {
                    size: 20
                },
                query: { dateRange: null, IncludeEstatePictureUrl: true }
            },
            filters
        );

        return await trackPromise(this.client.post('search/tasks', payload));
    }

    async saveTask(data) {
        return await trackPromise(this.client.put('tasks/save', data));
    }
}

export default new TaskService();
