import Service from './_base';
import { trackPromise } from 'react-promise-tracker';

class HistoryService extends Service {
    /**
     * Given a history entry, create one
     * @param entry
     * @returns {Promise<*>}
     */
    async createHistoryEntry(entry = {}) {
        return await trackPromise(this.client.post('activities/create', [entry]));
    }

    /**
     * update history entry
     * @param entry
     * @returns {Promise<*>}
     */
    async saveHistory(entry = {}) {
        return await trackPromise(this.client.put('activities/save', [entry]));
    }

    /**
     * Given a history id, return the data for it
     * @param historyId
     * @returns {Promise<*>}
     */
    async getHistoryItem(historyId) {
        let p = {
            params: {
                searchParameters: JSON.stringify({ parameters: { historyIdList: [historyId] }, pageIndex: 0, pageSize: 1 }),
                includes: ',history,contact,estate,'
            }
        };
        return await trackPromise(this.client.get('activities/history', p));
    }
}

export default new HistoryService();
