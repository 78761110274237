/* eslint-disable eqeqeq */
import moment from 'moment';
import LingerManager from './lingerManager';
import { getToken } from '../lib/auth';

export default class Logger {
    constructor(opts, userProfile) {
        this._metas = {};
        // bulk default options
        this._bulkLingerMs = 500;
        this._bulkMaxPostCount = 10;
        this._bulkMaxWaitingCount = -1;

        this._queue = [];
        this._maxContentSize = 200 * 1024; // limit post to 200 KB

        this._lingerManager = null;

        this._url = window.env?.REACT_APP_API_URL + '/logs/uam';

        if (userProfile) {
            this._token = userProfile.AccessToken;
        } else {
            this._token = getToken();
        }

        opts = opts || {};
        if (opts.lingerMs != null && opts.lingerMs >= 0) {
            // a delay used to give a change to bulk a few line of logs together
            this._bulkLingerMs = opts.lingerMs;
        }
        if (opts.maxPostCount != null) {
            // how many log lines should each post send at most (-1 no limit)
            if (opts.maxPostCount < 1) {
                opts.maxPostCount = 1;
            }
            this._bulkMaxPostCount = opts.maxPostCount;
        }
        if (opts.maxWaitingCount != null) {
            // how many log lines can be queued before dropping some (-1 no limit)
            this._bulkMaxWaitingCount = opts.maxWaitingCount;
        }
        if (opts.maxContentSize != null) {
            this._maxContentSize = opts.maxContentSize;
        }
        this._lingerManager = new LingerManager(this._bulkLingerMs);
    }

    assign(fromObject, toObject) {
        if (fromObject) {
            if (!toObject.parameters) {
                toObject.parameters = {};
            }
            for (let key in fromObject) {
                if (fromObject.hasOwnProperty(key)) {
                    toObject.parameters[key] = fromObject[key];
                }
            }
        }
    }

    getCurrentDateTime() {
        return moment().utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ');
    }

    info = (message, context) => {
        this.log(message, context, 'info');
    };

    warn = (message, context) => {
        this.log(message, context, 'warn');
    };

    error = (message, context) => {
        this.log(message, context, 'error');
    };

    log = (message, context, level) => {
        let payload = {
            message
        };

        payload['level'] = level;
        payload['date'] = moment(); //this.getCurrentDateTime();
        this.assign(context, payload);
        this.queue(payload);
    };

    queue = (payload) => {
        this.assign(this._metas, payload);

        this._queue = this._queue || [];
        this._queue.push(payload);

        // check if we are growing above the max waiting count (if any)
        if (this._bulkMaxWaitingCount >= 0 && this._queue.length > this._bulkMaxWaitingCount) {
            this._queue.shift();
        }

        this.tryPost();
    };

    tryPost = () => {
        // do nothing if the linger already scheduled or if a post is running
        if (this._lingerManager.isScheduled()) {
            return;
        }

        this._lingerManager.postpone(this.post);
    };

    handleExit = (status) => {
        if (status === 200 || status === 0) {
            if (this._queue.length > 0) {
                // continue until the queue is empty
                this._lingerManager.setMode(this._lingerManager.MODE.IMMEDIATE);
            } else {
                // default mode
                this._lingerManager.setMode(this._lingerManager.MODE.LINGER);
            }
        } else {
            // use a back-off mechanism
            this._lingerManager.setMode(this._lingerManager.MODE.ERROR);
        }

        this._lingerManager.reset();

        if (this._queue.length !== 0) {
            this.tryPost();
        }
    };

    post = () => {
        if (!this._url) {
            return;
        }

        let data = [];
        let contentSize = 0;

        let now = moment();

        while (this._queue.length > 0 && data.length < this._bulkMaxPostCount) {
            let o = this._queue.shift();
            o.timeOffset = now.diff(o.date);
            delete o.date;

            let item = JSON.stringify(o);
            contentSize += item.length;

            if (contentSize > this._maxContentSize) {
                // drop the element if its size is more than the max allowed, but warn the user
                if (item.length > this._maxContentSize) {
                    let newItem = {
                        level: 'warn',
                        message: 'Message dropped as its size exceeded the hard limit'
                    };

                    this.assign(this._metas, newItem);

                    if (JSON.stringify(newItem).length > this._maxContentSize) {
                        newItem = {
                            level: 'error',
                            message: 'Message dropped because the context size provided exceeded the hard limit'
                        };
                    }

                    o = newItem;
                }

                this._queue.unshift(o);
                break;
            }

            data.push(item);
        }

        if (data.length === 0) {
            this.handleExit(0);
            return;
        }

        let payload = '[' + data.join(',') + ']';

        let request = new XMLHttpRequest();
        request.open('PUT', this._url, true);
        request.setRequestHeader('Content-Type', 'application/json; charset=UTF-8');
        request.setRequestHeader('Authorization', 'Bearer '.concat(this._token));
        request.onerror = (response) => {
            this.handleExit(response.target.status);
        };
        request.onload = (response) => {
            this.handleExit(response.target.status);
        };
        request.send(payload);
    };

    setMetas = (metas) => {
        this._metas = metas;
    };

    addMeta = (key, value) => {
        this._metas[key] = value;
    };

    lastError = '';

    catchErrors = () => {
        window.onerror = (msg, source, fileno, columnNumber, error) => {
            try {
                let isPiwikError = error?.stack?.indexOf('https://whise.containers.piwik.pro') > 0;
                if (msg && msg != 'Script error.' && msg.indexOf('$') == -1 && !isPiwikError) {
                    let parameters = {};
                    parameters['source'] = source;
                    parameters['line'] = fileno;
                    parameters['column'] = columnNumber;
                    parameters['location'] = window.location.pathname + window.location.hash;
                    parameters['error'] = JSON.stringify(error);

                    if (window.location.href && window.location.href.indexOf('localhost') == -1) {
                        if (source) {
                            let thisError = source + '|' + fileno + '|' + columnNumber;

                            if (thisError != this.lastError) {
                                this.lastError = thisError;

                                if (
                                    (source.indexOf('/app') >= 0 &&
                                        msg.indexOf('a[b].target.className.indexOf is not a function') < 0 &&
                                        JSON.stringify(error).indexOf('a[b].target.className.indexOf is not a function') <
                                            0) /* javascript  error caused by browser extensions*/ ||
                                    source.indexOf('/mobile') >= 0 ||
                                    source.indexOf('/common') >= 0
                                ) {
                                    this.error(msg, parameters);
                                } else {
                                    this.warn(msg, parameters);
                                }
                            }
                        }
                    }
                }
            } catch (e) {
                this.error(msg, e);
            }
        };
    };
}

//export default new Logger();
