/**
 * Events
 * @type {string}
 */

export const EVENT_SHOW_TOAST = 'show:toast';
export const EVENT_SHOW_ERRORS = 'show:errors';

/**
 * Recurrence types
 * @type {string}
 */

export const DAILY_RECURRENCE = 3;
export const WEEKLY_RECURRENCE = 2;
export const MONTHLY_RECURRENCE = 1;
export const YEARLY_RECURRENCE = 0;
export const NO_RECURRENCE = -1;

export const ExcludedSubdetails = [
    679, 1393, 2045, 2046, 107, 697, 1411, 703, 1778, 2331, 1058, 1800, 908, 1515, 915, 1860, 1861, 1862, 1864, 1865, 1881, 1882,
    1959, 1960, 1961, 1962, 1963, 1964, 1974, 1975, 2043, 1992, 1993, 2422, 1994, 1995, 1997, 1998, 1999, 2000, 2001, 2002, 2003,
    2004, 2005, 2006, 2007, 2008, 2009, 2019, 2020, 2021, 2028, 2029, 2030, 2031, 2032, 2033, 2034, 2035, 2036, 2037, 2038, 2039,
    2040, 2041, 2042, 2051, 2053, 2054, 2083, 2084, 2085, 2278, 2279, 2280, 2281, 2282, 2283, 2284, 2285, 2286, 2287, 2320, 2288,
    2289, 2290, 2291, 2292, 2293, 2294, 2295, 2296, 2297, 2311, 2326, 2327, 2458, 2459, 2460, 2461, 2462, 2463, 2464, 2465, 2466,
    2506, 2508, 2557, 2577, 2578, 1779, 1928, 1933, 1938, 1929, 1934, 1939, 1930, 1935, 1943, 1931, 1936, 1944, 1932, 1937, 1945,
    2635
];

/**
 * Display values
 * @type {string}
 */
export const BOTTOM_MENU_HEIGHT = 50;
export const NotificationStatus = {
    None: 0,
    Success: 1,
    Error: 2,
    Warning: 3,
    Info: 4,
    Details: 5,
    WarningContinue: 6,
    WarningConfirm: 7
};

export const FreemiumTrigger = {
    ActiveEstates: 1,
    ActiveUsers: 2
};

export const ZipAutocompleteMode = {
    Standard: 0,
    Zip: 1,
    ZipCity: 2
};
