import Service from './_base';
import axios from 'axios';
import { set } from '../lib/lodash';
import { serializeParams } from '../lib/utils';
import merge from 'deepmerge';
import { getToken } from '../lib/auth';
import { trackPromise } from 'react-promise-tracker';
import { FileStatus } from 'commons';

class EstateService extends Service {
    /**
     * Given a filters object, saves them in the api
     * @param filters
     * @returns {Promise<void>}
     */

    async saveFilter(filters = {}) {
        const payload = {
            preferenceId: 140,
            targetId: filters.userId,
            levelId: 10,
            value: JSON.stringify({
                estate: filters.estate
            })
        };

        return await trackPromise(this.client.put('clients/preferencevalues/save', [payload]));
    }

    /**
     * Given data, saves a picture
     * @param id
     * @param data
     * @returns {Promise<void>}
     */

    async savePictures(id = 0, data = {}) {
        return await trackPromise(
            this.client.post('estates/create/pictures', data, {
                params: {
                    estateId: id,
                    copyToSubestates: false
                }
            })
        );
    }

    /**
     * Given an id, returns an external estate
     * @param id
     * @returns {Promise<void>}
     */

    async getExternalEstate(id = 0) {
        return await trackPromise(
            this.client.post('/search/estates', {
                query: {
                    includeGroupEstates: false,
                    includeGroupIds: false,
                    includeExternalData: true,
                    externalIds: [id]
                }
            })
        );
    }

    /**
     * Given an id, returns an estate
     * @param id
     * @returns {Promise<void>}
     */

    async getEstate(id = 0) {
        return await trackPromise(
            this.client.get('estates', {
                params: {
                    includes: [
                        'estate',
                        'picture',
                        'document',
                        'export',
                        'estatelanguage',
                        'contract',
                        'estatepurposestatus',
                        'subdetailvalue',
                        'subdetailvaluelanguage',
                        'contactestate',
                        'contact',
                        'estateregion'
                    ].join(','),
                    searchParameters: JSON.stringify({
                        pageIndex: 0,
                        pageSize: 1,
                        parameters: {
                            estateIdList: [id],
                            includeProjectPathName: true
                        }
                    })
                }
            })
        );
    }

    /**
     * Given a list of ids, returns a list of estates
     * @param ids
     * @returns {Promise<void>}
     */

    async getEstatesById(ids = []) {
        return await trackPromise(
            this.client.get('estates', {
                params: {
                    includes: [
                        'estate',
                        'picture',
                        'document',
                        'export',
                        'estatelanguage',
                        'contract',
                        'estatepurposestatus',
                        'subdetailvalue',
                        'subdetailvaluelanguage',
                        'contactestate',
                        'contact',
                        'estateregion'
                    ].join(','),
                    searchParameters: JSON.stringify({
                        pageIndex: 0,
                        pageSize: 1,
                        parameters: {
                            estateIdList: ids,
                            includeProjectPathName: true
                        }
                    })
                }
            })
        );
    }

    /**
     * Given a list of filters, returns a list of contacts
     * @param filters
     * @returns {Promise<void>}
     */

    async getEstates(filters = {}) {
        if (filters.hasOwnProperty('page')) {
            let page = filters.page || 0;
            delete filters.page;
            set(filters, 'page.index', page);
        }

        if (filters.hasOwnProperty('search')) {
            set(filters, 'query.customSearch', filters.search);
        }

        if (filters.hasOwnProperty('limit')) {
            set(filters, 'page.size', filters.limit);
        }

        const payload = merge(
            {
                aggregate: {
                    terms: [
                        {
                            fieldName: 'purposeId'
                        },
                        {
                            fieldName: 'purposeStatusId'
                        },
                        {
                            fieldName: 'categoryId'
                        },
                        {
                            fieldName: 'subcategoryId'
                        },
                        {
                            fieldName: 'displayStatusId'
                        },
                        {
                            fieldName: 'statusId'
                        },
                        {
                            fieldName: 'countryId'
                        },
                        {
                            fieldName: 'regionId'
                        },
                        {
                            fieldName: 'officeId'
                        },
                        {
                            fieldName: 'city.raw'
                        },
                        {
                            fieldName: 'investmentEstate'
                        },
                        {
                            fieldName: 'isTopParent'
                        }
                    ]
                },
                field: {
                    excluded: ['longDescriptionML', 'shortDescriptionML', 'comments', 'contract']
                },
                page: {
                    size: 20,
                    index: filters.page
                },
                postFilter: {},
                query: {
                    ...(filters.query.includeExternalData
                        ? {}
                        : {
                              project: {
                                  includeProjectDetails: true
                              }
                          }),
                    ...(filters.isMap
                        ? {
                              includedSubdetailIds: [1849, 1850]
                          }
                        : {})
                },
                sort: {
                    field: 'id',
                    ascending: false
                }
            },
            filters
        );

        if (filters.sort) {
            payload.sort = filters.sort;
        }

        return await trackPromise(this.client.post('search/estates', payload));
    }

    async getEstatesDocuments(estateIds) {
        const payload = {
            agregate: null,
            field: {
                included: ['id', 'name', 'documents']
            },
            page: null,
            postFilter: null,
            query: {
                estateIds: estateIds
            },
            sort: null
        };

        return await trackPromise(this.client.post('search/estates', payload));
    }

    async getEstatesOffline(_estateIds) {
        return await trackPromise(
            this.client.get('estates', {
                params: {
                    includes: [
                        'estate',
                        'picture',
                        'document',
                        'export',
                        'estatelanguage',
                        'contract',
                        'estatepurposestatus',
                        'subdetailvalue',
                        'subdetailvaluelanguage',
                        'contactestate',
                        'contact',
                        'estateregion'
                    ].join(','),
                    searchParameters: JSON.stringify({
                        pageIndex: 0,
                        pageSize: 20,
                        parameters: {
                            estateIdList: _estateIds,
                            includeProjectPathName: true
                        }
                    })
                }
            })
        );
    }

    async getEstatesForMap(filters = {}) {
        if (filters.hasOwnProperty('search')) {
            set(filters, 'query.customSearch', filters.search);
        }

        const payload = merge(
            {
                field: {
                    excluded: ['longDescriptionML', 'shortDescriptionML', 'comments', 'contract']
                },
                page: {
                    size: 10000,
                    index: 0
                },
                postFilter: {},
                query: {
                    ...(filters.isMap
                        ? {
                              includedSubdetailIds: [1849, 1850]
                          }
                        : {})
                },
                sort: {
                    field: 'id',
                    ascending: false
                }
            },
            filters
        );

        if (filters.sort) {
            payload.sort = filters.sort;
        }

        return await trackPromise(this.client.post('search/estates', payload));
    }

    /**
     * Given data, creates an estate
     * @param data
     * @returns {Promise<void>}
     */

    async createEstate(data = {}, correlationId = null) {
        data.rowStatus = 1;
        const result = await trackPromise(
            this.client.put(
                'estates/savedata',
                [
                    {
                        object: data,
                        events: []
                    }
                ],
                {
                    params: {
                        validationWarningBehaviour: 1,
                        validationForMedias: true,
                        correlationId: correlationId
                    }
                }
            )
        );
        return result.data;
    }

    /**
     * Given data, updates an estate
     * @param data
     * @returns {Promise<void>}
     */

    async updateEstate(data = {}, correlationId = null) {
        const result = await trackPromise(
            this.client.put('estates/savedata', [data], {
                params: {
                    validationWarningBehaviour: 1,
                    validationForMedias: true,
                    correlationId: correlationId
                }
            })
        );
        return result.data;
    }

    /**
     * Given a contact id, returns contact's owned estates
     * @param id
     * @returns {Promise<void>}
     */

    async getContactOwnedEstates(id = '') {
        return await trackPromise(
            this.client.post('search/estates', {
                aggregate: null,
                field: null,
                page: null,
                postFilter: null,
                query: {
                    ownerIds: [id],
                    statusIds: [1, 5]
                },
                sort: null
            })
        );
    }

    /**
     * Given a contact id, returns contact's bought estates
     * @param id
     * @returns {Promise<void>}
     */

    async getContactBoughtEstates(id = '') {
        return await trackPromise(
            this.client.post('search/estates', {
                aggregate: null,
                field: null,
                page: null,
                postFilter: null,
                query: {
                    buyerIds: [id],
                    statusIds: [1, 5]
                },
                sort: null
            })
        );
    }

    /**
     * Given a search string, returns a list of estates
     * @param search
     * @param options
     * @returns {Promise<*>}
     */

    async searchEstates(search, options) {
        let query = {
            customSearch: search,
            includeGroupEstates: true,
            project: {
                hasParent: false,
                includeProjectDetails: true
            },
            purposeStatusIds: [1, 2, 5, 6, 8, 10, 11, 12, 13, 14, 15, 16, 17, 19, 20, 21, 22, 23, 24, 25, 26]
        };
        let includedFields = [
            'id',
            'name',
            'address1',
            'number',
            'box',
            'floor',
            'zip',
            'city',
            'projectPathName',
            'parentId',
            'pictures',
            'price',
            'documents',
            'purposeStatusId',
            'isParent',
            'officeId',
            'statusId'
        ];
        if (options) {
            if (options.extraQuery) {
                query = { ...query, ...options.extraQuery };
            }
            if (options.include) {
                includedFields = includedFields.concat(options.include);
            }
        }

        return await trackPromise(
            this.client.post('search/estates', {
                aggregate: null,
                field: {
                    included: includedFields
                },
                postFilter: null,
                query,
                sort: {
                    field: 'id',
                    ascending: true
                }
            })
        );
    }

    /**
     * Given a search string, returns a list of projects
     * @param search
     * @param extra
     * @returns {Promise<*>}
     */
    async searchProjects(search = '', extra = {}) {
        return await trackPromise(
            this.client.post('search/estates', {
                aggregate: null,
                field: {
                    included: [
                        'id',
                        'name',
                        'address1',
                        'number',
                        'box',
                        'floor',
                        'zip',
                        'city',
                        'projectPathName',
                        'parentId',
                        'pictures',
                        'isParent',
                        'officeId'
                    ]
                },
                postFilter: null,
                query: {
                    customSearch: search,
                    statusIds: [1],
                    project: { isParent: true, includeProjectDetails: true },
                    ...extra
                },
                sort: {
                    field: 'id',
                    ascending: true
                }
            })
        );
    }

    /**
     * Given a search string, returns a list of estates
     * @param search
     * @param extra
     * @returns {Promise<*>}
     */

    async searchEstate(query, postFilter, page, field, aggregate, sort) {
        let response = await trackPromise(
            this.client.post('search/estates', {
                query,
                postFilter,
                page,
                field,
                aggregate,
                sort
            })
        );
        return response.data;
    }

    async getGroups() {
        let requestUrl = 'admin/estatesubdetails?mergeActivatedSubdetails=' + true;
        let response = await trackPromise(this.client.get(requestUrl, null, null));
        return response.data;
    }

    async uploadPictures(estateId, pictureModels, pictureStreams, uploadParams) {
        let p = {
            estateId,
            resizeAsync: true
        };
        if (uploadParams && uploadParams.copyToSubestates) {
            p.copyToSubestates = uploadParams.copyToSubestates;
        }
        if (uploadParams && uploadParams.estateStatusId) {
            p.estateStatusId = uploadParams.estateStatusId;
        }
        if (uploadParams && uploadParams.copyToSubestates) {
            p.copyToSubestates = uploadParams.copyToSubestates;
            p.resizeAsync = false;
        }
        if (uploadParams && uploadParams.sendNotification !== undefined) {
            p.sendNotification = uploadParams.sendNotification;
        }

        let requestUrl = 'estates/create/pictures' + serializeParams(p);

        let fd = new FormData();
        fd.append('pictures', JSON.stringify(pictureModels));

        let i = 0;
        for (let pictureStream of pictureStreams) {
            fd.append('pic' + i.toString(), pictureStream);
            i++;
        }

        let response = await trackPromise(this.client.post(requestUrl, fd));
        return response && response.data ? response.data : null;
    }

    async uploadDocuments(estateId, documentsModels, documentStreams, uploadParams) {
        let p = {
            estateId
        };
        if (uploadParams && uploadParams.copyToSubestates) {
            p.copyToSubestates = uploadParams.copyToSubestates;
        }

        let requestUrl = 'estates/create/documents' + serializeParams(p);

        let fd = new FormData();
        fd.append('documents', JSON.stringify(documentsModels));

        let i = 0;
        for (let documentStream of documentStreams) {
            fd.append('doc' + i.toString(), documentStream);
            i++;
        }

        let client400 = axios.create({
            baseURL: window.env?.REACT_APP_API_URL,
            headers: {
                Authorization: `Bearer ${getToken()}`
            },
            validateStatus: (status) => {
                return status >= 200 && status <= 400;
            }
        });

        let response = await client400.post(requestUrl, fd);

        if (response.status === 400) {
            return { message: response.data };
        }

        return response && response.data ? response.data : response;
    }

    async getStreamAsBlob(path, fileStatus) {
        return trackPromise(
            new Promise((resolve, reject) => {
                let requestUrl = window.env?.REACT_APP_API_URL + '/streams/download?filePath=' + encodeURIComponent(path);
                requestUrl += '&fileStatus=' + (fileStatus || FileStatus.Normal);
                const accessToken = getToken();

                let xhr = new XMLHttpRequest();
                xhr.open('GET', requestUrl);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
                xhr.setRequestHeader('Authorization', 'Bearer ' + accessToken);
                xhr.onload = function () {
                    if (this.status === 200) {
                        let byteArray = new Uint8Array(xhr.response);
                        let blob = new Blob([byteArray], {
                            type: 'application/octet-stream'
                        });
                        resolve(blob);
                    } else {
                        resolve(null);
                    }
                };
                // tslint:disable-next-line:only-arrow-functions
                xhr.onerror = function () {
                    reject({ status: 500 });
                };
                xhr.send();
            })
        );
    }

    /**
     *
     * @param {string} url : must be from same server, cross server ajax call is not allowed
     */
    async loadImageFromUrl(url) {
        return trackPromise(
            new Promise((resolve, reject) => {
                let requestUrl = encodeURIComponent(url);

                let xhr = new XMLHttpRequest();
                xhr.open('GET', requestUrl);
                xhr.responseType = 'blob';
                // xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
                xhr.onload = function () {
                    if (this.status === 200) {
                        resolve(xhr.response);
                    } else {
                        reject(null);
                    }
                };
                xhr.onerror = function () {
                    reject(null);
                };
                xhr.send();
            })
        );
    }

    async loadImageFromUrlAsBlob(url) {
        return trackPromise(
            new Promise((resolve, reject) => {
                let requestUrl = url;

                let xhr = new XMLHttpRequest();
                xhr.open('GET', requestUrl);
                xhr.responseType = 'blob';
                // xhr.setRequestHeader('Content-Type', 'application/json;charset=utf-8');
                xhr.onload = function (args) {
                    if (this.status === 200) {
                        // let byteArray = new Uint8Array(xhr.response);
                        // let blob = new Blob([byteArray], {
                        //     type: 'application/octet-stream'
                        // });
                        // resolve(blob);
                        resolve(xhr.response);
                    } else {
                        resolve(null);
                    }
                };
                xhr.onerror = function () {
                    resolve(null);
                };
                xhr.send();
            })
        );
    }

    async deleteEstates(ids) {
        return trackPromise(
            this.client.put('estates/delete', ids, {
                params: {
                    validationWarningBehaviour: 1,
                    canRemoveFromMedias: false
                }
            })
        );
    }

    async matchEstate(query = {}, correlationId = null) {
        let response = await trackPromise(
            this.client.post('estates/match', query, {
                params: {
                    correlationId: correlationId
                }
            })
        );
        return response.data;
    }

    async validateEstates(estates, mediaIds, languageId) {
        let response = await trackPromise(
            this.client.post(`exports/validate?mediaIds=${mediaIds.join(',')}&languageId=${languageId}`, estates)
        );
        return response.data;
    }

    async searchActivity(request) {
        let response = await trackPromise(this.client.post('search/activities', request));
        return response.data;
    }

    async getEstateDetailUrl(estateId, languageId, estateName) {
        let url =
            'estates/detailurl?estateId=' +
            estateId +
            '&languageId=' +
            languageId +
            '&estateName=' +
            encodeURIComponent(estateName || '_');
        let response = await trackPromise(this.client.get(url));
        return response.data;
    }

    async getMrsActivationDetails(estateId) {
        let response = await trackPromise(this.client.post('estates/activations', { estateId }));
        return response.data;
    }

    async activateMrs(params) {
        let response = await trackPromise(this.client.post('estates/externalactivation', params));
        return response.data;
    }

    async resendAccessMrs(params) {
        let response = await trackPromise(this.client.post('estates/resendaccess', params));
        return response.data;
    }

    async removeAccessMrs(params) {
        let response = await trackPromise(this.client.post('estates/removeaccess', params));
        return response.data;
    }

    async getContactProfileMrs(params) {
        let response = await trackPromise(this.client.post('estates/contactprofile', params));
        return response.data;
    }

    async generateFlashDescription(param) {
        let url = 'openai/estatedescription/sync';
        let response = await trackPromise(this.client.post(url, param));
        return response.data;
    }
}

export default new EstateService();
