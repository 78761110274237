import axios from 'axios';
//import history from './history';
import { getToken, setToken } from './auth';
import Logger from '../services/logger';
import { formatAPIErrorMessages } from './utils';
import { validateEstateResult } from '../m/Estate/EstateValidationUtil';

const clearOverflow = () => {
    setTimeout(() => {
        if (document.body.style.overflow) {
            document.body.style.removeProperty('overflow');
        }
    }, 100);
};

const formatItemForValidation = (item, config) => {
    return {
        location: window.location.pathname + window.location.hash,
        baseUrl: config.baseURL,
        url: config.url,
        data: config.data,
        code: item.code,
        property: item.property,
        resource: item.resource
    };
};

const MyLogger = new Logger(
    {
        lingerMs: 0
    },
    null
);

let options = {
    baseURL: window.env?.REACT_APP_API_URL
};

const client = axios.create(options);

client.interceptors.request.use(
    (config) => {
        if (localStorage && localStorage.Version !== window.env?.REACT_APP_VERSION && config.url !== '/token') {
            setToken('');
        }

        const token = getToken();
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }

        config.headers['Content-Type'] = 'application/json';

        if (config.method === 'put' && config.params && config.params.correlationId) {
            config.headers['x-correlation-id'] = config.params.correlationId;
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);

client.interceptors.response.use(
    (response) => {
        if (response.config.url === 'estates/savedata') {
            const mediaErrors = validateEstateResult(response.data);
            if (mediaErrors) {
                throw mediaErrors;
            }
        }
        if (response.data && response.data.errors && response.data.errors.length > 0) {
            response.data.errors.forEach((item) => {
                MyLogger.warn('Validation from API', formatItemForValidation(item, response.config));
            });
            throw formatAPIErrorMessages(response.data.errors);
        }
        if (response.data && response.data.warnings && response.data.warnings.length > 0) {
            response.data.warnings.forEach((item) => {
                MyLogger.warn('Validation from API', formatItemForValidation(item, response.config));
            });
        }
        clearOverflow();
        return response;
    },
    (error) => {
        if (error && error.response) {
            switch (error.response.status) {
                case 400:
                    MyLogger.warn('Invalid request', error.response);
                    return error.response;
                case 401:
                    setToken('');
                    MyLogger.warn('Token expiration', error.response);

                    if (window.env.REACT_APP_ENV !== 'prd') {
                        window.location = '/';
                    } else {
                        window.location = '/app/';
                    }
                    break;

                case 500:
                    MyLogger.warn('Internal Server Error', error.response);
                    window.location = '/#/internalservererror';
                    break;

                default:
                    MyLogger.error('Unhandled from API', error.response);
                    break;
            }
        }
        // else {
        //     if (error.message && error.message === 'Network Error') {
        //         window.location = '/#/internalservererror';
        //     }
        // }
    }
);

export default client;
