const theme = {
    colors: {
        primary: '#006ac9',
        primaryDark: '#05448b',
        whiseTurqoise: '#46a0bf',
        textDark: '#475B6C',
        textLight: '#8294A4',
        textLighter: '#777A81',
        textUltraLight: '#c9c9c9',
        textAccent: '',
        modalLight: '#f5f4f4',
        borderLight: '#e1e1e1',
        //textError: '#FF3E04',
        whiseRed: '#ce0024',
        textError: '#ce0024',
        textSuccess: '#58AD43',
        backgroundDefault: '#F6F9FB',
        backgroundDisabled: '#bfbfbf',
        backgroundSuccess: '#E4FBDC',
        backgroundError: '#ffe9e9',
        backgroundInfo: '#EFF9FF',
        badgeRed: '#E11A1A',
        badgeGreen: '#00e407'
    },
    space: {
        0: 0,
        xs: '3px',
        sm: '5px',
        md: '10px',
        lg: '20px'
    },
    iconSize: {
        xsmall: '14px',
        small: '18px',
        normal: '24px',
        medium: '30px',
        large: '48px'
    }
};

export default theme;
