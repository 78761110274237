import { EstateMediaValidationFlags } from 'commons';
import i18next from 'i18next';
import { dataService } from 'services';

export const canRemoveFromMedia = (flags) => {
    return ((flags || 0) & EstateMediaValidationFlags.CanRemoveFromMedia) !== 0;
};

const getMediaName = (mediaId) => {
    let media = dataService.ServiceConsumers.filter((x) => x.id === mediaId)[0];
    return (media && media.name) || 'media id ' + mediaId;
};

export const validateEstateResult = (data) => {
    let errors = [];
    let warnings = [];
    let hasMediaErrors = false;
    let mediasToRemove = [];
    let medias = [];
    let removePropertyFromMediaMessage = '';

    const getValue = (items) => {
        let errors = [];
        for (let x of items) {
            x.mediaWithProblem = '';

            if (!x.value || x.code === 'Fatal') {
                errors.push(x);
                continue;
            }
            try {
                let value = JSON.parse(x.value);
                if (!value) {
                    errors.push(x);
                    continue;
                }
                switch (x.code) {
                    case 'ValidMediaEstate':
                        if (!value.mediaId) {
                            break;
                        }

                        if (value.flags) {
                            x.flag = value.flags;
                        }

                        let exists = errors.find((x) => x.mediaId === value.mediaId && x.flag === value.flags);
                        if (exists) {
                            exists.message += '<br>' + x.message;

                            break;
                        }

                        let estate = medias.find((x) => x.estateId === (value.projectId ? value.projectId : value.estateId));
                        if (estate) {
                            if (!estate.mediaIds) {
                                estate.mediaIds = [];
                            }

                            estate.mediaIds.push(value.mediaId);
                        } else {
                            medias.push({
                                estateId: value.projectId || value.estateId,
                                mediaIds: [value.mediaId]
                            });
                        }

                        let media = dataService.ServiceConsumers.find((x) => x.id === value.mediaId);
                        if (media && media.name) {
                            x.mediaWithProblem =
                                media.name +
                                (value.projectId ? ' ({0}: {1})'.format(i18next.t('Project').capitalize(), value.projectId) : '');
                            x.mediaId = media.id;
                        }

                        errors.push(x);
                        break;

                    default:
                        errors.push(x);
                }
            } catch (e) {}
        }
        return errors;
    };

    if (data.errors) {
        let _errors = getValue(data.errors);
        hasMediaErrors = !_errors.some((x) => !canRemoveFromMedia(x.flag));
        if (hasMediaErrors) {
            mediasToRemove = _errors.filter((x) => canRemoveFromMedia(x.flag)).map((y) => JSON.parse(y.value).mediaId);
            removePropertyFromMediaMessage = i18next
                .t('SavingWillRemovePropertyFromMedias')
                .replace('{0}', mediasToRemove.map((x) => `<i>${getMediaName(x)}</i>`).join(', '));
        }
        errors = _errors;
    }

    if (data.warnings) {
        let _warnings = getValue(data.warnings);
        warnings = _warnings;
    }

    if (removePropertyFromMediaMessage) {
        const mediaErrors = {
            title: removePropertyFromMediaMessage,
            medias,
            mediasToRemove,
            hasMediaErrors,
            errors: errors.map((x) => ({ title: x.mediaWithProblem, message: x.message })),
            warnings: warnings.map((x) => ({ title: x.mediaWithProblem, message: x.message })),
            htmlMessage:
                `<strong>${removePropertyFromMediaMessage}</strong><br/><br/>` +
                errors.map((x) => `<span style="color: red">${x.mediaWithProblem}: </span>${x.message}<br/>`).join('')
        };
        return { mediaErrors };
    }
    return null;
};
